import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgArrowFullRight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke={props.color || '#C5C8D3'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3.333 8h9.334m0 0L8 3.333M12.667 8 8 12.667"
        />
    </svg>
);
