import * as React from 'react';
import { SVGProps } from 'react';
export const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.333 13v-1.333A2.667 2.667 0 0 0 8.666 9H3.333a2.667 2.667 0 0 0-2.666 2.667V13m14.666 0v-1.333a2.667 2.667 0 0 0-2-2.58m-2.667-8a2.667 2.667 0 0 1 0 5.166m-2-2.586a2.667 2.667 0 1 1-5.333 0 2.667 2.667 0 0 1 5.333 0Z"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
