import {
    BaseStepData,
    VoidFunction,
    NullableDate
} from 'utils/types/general/general.types';
import {
    ShipmentDetailsFormValues,
    PartQuantity,
    SelectPartsStepOrderFormValues,
    SerialsStepFormValues,
    CountryFormFields,
    PostalCityState
} from 'utils/types/general/stepper.types';
import { AccountWarehouse } from 'utils/types/general/warehouse.type';

export interface DeliveryTypeStepFormValues {
    warehouse: string;
    deliveryType: string;
    includeLabels: boolean;
    date: NullableDate;
    time?: number;
    earliestReady?: boolean;
}

export enum ORDER_BY_PART_STEPS {
    SELECT_PARTS = 'Select Parts',
    DELIVERY = 'Delivery',
    REFERENCE_NUMBERS = 'Reference Numbers',
    SHIPMENTS_DETAILS = 'Shipment Details',
    ORDER_REVIEW = 'Order Review'
}

export type OutboundOrderFormValues = SelectPartsStepOrderFormValues &
    DeliveryTypeStepFormValues &
    ShipmentDetailsFormValues &
    SerialsStepFormValues & { remember: boolean };

export interface GetModalDataProps {
    description: string;
    submitText: string;
    onOk: VoidFunction;
    onCancelButtonClick: VoidFunction;
}

export interface SelectPartByWarehouseStepFormValues extends PartQuantity {
    warehouse: string;
}

export type DeliveryByWarehouseStepFormValues = Omit<
    DeliveryTypeStepFormValues,
    'warehouse'
> &
    CountryFormFields &
    PostalCityState;

export interface OutboundOrderStepData extends BaseStepData {
    initialValues?: Partial<OutboundOrderFormValues>;
    onFinish?: (values: OutboundOrderFormValues) => void;
}

export type ChosenWarehouseForSubmit = Partial<
    Pick<AccountWarehouse, 'countryCode' | 'code'>
>;

export interface ConfirmModalData {
    reduceFunction: VoidFunction;
    showReduceOption?: boolean;
}
