import { MIR_STATUSES_FOR_RESPONSE } from 'APIServices/materialsInReview/MaterialsInReview.types';
import { ORDER_STATUS_FROM_RESPONSE } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { ILabelProps } from 'components/UI/atoms/label/Label';

export enum PROGRESS_STATUSES {
    ON_TIME = 'On Time',
    DELAYED = 'Delayed',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    TOTAL = 'Total'
}

export enum MIR_STATUSES {
    ALL = 'All',
    CLOSED = 'Closed',
    IN_CHOICE_REVIEW = 'In Choice Review',
    IN_CLIENT_REVIEW = 'In Client Review',
    NEW_RECORD = 'New Mir Record'
}

export const getStatusVariant = (status: string): ILabelProps['variant'] => {
    switch (status) {
        case PROGRESS_STATUSES.PENDING:
            return 'Yellow';
        case PROGRESS_STATUSES.DELAYED:
            return 'Red';
        case PROGRESS_STATUSES.COMPLETED:
            return 'Green';
        case PROGRESS_STATUSES.ON_TIME:
            return 'Green';
        case PROGRESS_STATUSES.TOTAL:
            return 'Blue';
        case PROGRESS_STATUSES.CANCELLED:
        default:
            return 'Neutral';
    }
};

export const mapStatusTotalsToStatuses = (status?: string) => {
    switch (status) {
        case ORDER_STATUS_FROM_RESPONSE.ON_TIME:
            return PROGRESS_STATUSES.ON_TIME;
        case ORDER_STATUS_FROM_RESPONSE.CANCELLED:
            return PROGRESS_STATUSES.CANCELLED;
        case ORDER_STATUS_FROM_RESPONSE.COMPLETED:
            return PROGRESS_STATUSES.COMPLETED;
        case ORDER_STATUS_FROM_RESPONSE.DELAYED:
            return PROGRESS_STATUSES.DELAYED;
        case ORDER_STATUS_FROM_RESPONSE.PENDING:
            return PROGRESS_STATUSES.PENDING;
        case ORDER_STATUS_FROM_RESPONSE.TOTAL:
        default:
            return PROGRESS_STATUSES.TOTAL;
    }
};

export const mapMirTotalsToStatuses = (status?: string) => {
    switch (status) {
        case MIR_STATUSES_FOR_RESPONSE.ALL:
            return MIR_STATUSES.ALL;
        case MIR_STATUSES_FOR_RESPONSE.CLOSED:
            return MIR_STATUSES.CLOSED;
        case MIR_STATUSES_FOR_RESPONSE.IN_CHOICE_REVIEW:
            return MIR_STATUSES.IN_CHOICE_REVIEW;
        case MIR_STATUSES_FOR_RESPONSE.NEW_RECORD:
            return MIR_STATUSES.NEW_RECORD;
        case MIR_STATUSES_FOR_RESPONSE.IN_CLIENT_REVIEW:
            return MIR_STATUSES.IN_CLIENT_REVIEW;
        default:
            return MIR_STATUSES.ALL;
    }
};
