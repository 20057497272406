import * as React from 'react';
import { SVGProps } from 'react';
export const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.666 3c0-.734-.6-1.334-1.333-1.334H2.666c-.733 0-1.333.6-1.333 1.333m13.333 0v8c0 .734-.6 1.334-1.333 1.334H2.666c-.733 0-1.333-.6-1.333-1.334V3m13.333 0L8 7.666 1.333 2.999"
            stroke={props.color || '#2C2F3A'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
