import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgDoubleArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#8E94A9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m8 12 4-4-4-4M4 12l4-4-4-4"
        />
    </svg>
);
