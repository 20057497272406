export const HUBS = Object.freeze({
    user: {
        notification: 'NotificationsHub'
    },
    outbound: {
        good: {
            validation: 'FileValidation/CreateOutboundsGoodByUploadHub',
            submit: 'SubmitOrders/CreateOutboundsGoodByUploadHub'
        },
        defective: {
            validation: 'FileValidation/CreateOutboundsDefectiveByUploadHub',
            submit: 'SubmitOrders/CreateOutboundsDefectiveByUploadHub'
        }
    },
    inbound: {
        good: {
            validation: 'FileValidation/CreateInboundsReplenishmentByUploadHub',
            submit: 'SubmitOrders/CreateInboundsReplenishmentByUploadHub'
        },
        defective: {
            validation: 'FileValidation/CreateInboundsDefectiveByUploadHub',
            submit: 'SubmitOrders/CreateInboundsDefectiveByUploadHub'
        }
    },
    transfers: {
        good: {
            validation: 'FileValidation/CreateTransfersGoodByUploadHub',
            submit: 'SubmitOrders/CreateTransfersGoodByUploadHub'
        },
        defective: {
            validation: 'FileValidation/CreateTransfersDefectiveByUploadHub',
            submit: 'SubmitOrders/CreateTransfersDefectiveByUploadHub'
        }
    }
});

export enum EVENTS {
    RECEIVE_SUBMISSION = 'ReceiveSubmissionProgress',
    RECEIVE_FILE_VALIDATION = 'ReceiveFileValidationProgress',
    RECEIVE_USER_STATUS = 'ReceiveUserStatusMessage'
}
