import * as React from 'react';
import { SVGProps } from 'react';
export const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.334 13v-1.333A2.667 2.667 0 0 0 9.667 9H4.334a2.667 2.667 0 0 0-2.667 2.667V13m8-9.333a2.667 2.667 0 1 1-5.333 0 2.667 2.667 0 0 1 5.333 0Z"
            stroke={props.color || '#000'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
