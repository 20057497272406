import { useState } from 'react';

import { Form, FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import AuthApi from 'APIServices/auth/auth.api';
import { useValidation } from 'components/UI/organisms/auth/signIn/useValidation';
import { B2CController } from 'config/B2CController';
import { useAppDispatch } from 'store';
import { setUserId } from 'store/slices/user/user';
import { RoutesList } from 'utils/constants/RoutesList';
import { emailRegExp } from 'utils/helpers/validationHelpers';
import {
    GRAND_TYPES,
    RESPONSE_TYPES,
    SCOPES
} from 'utils/types/general/b2C.types';
import { VoidFunction } from 'utils/types/general/general.types';
import { SignInForm } from 'utils/types/users/authForm.types';

interface UseSignInReturn {
    isAuthError: boolean;
    isLoading: boolean;
    form: FormInstance<SignInForm>;
    haveAllFields: boolean;
    onFinish: (value: SignInForm) => Promise<void>;
    onForgotClick: VoidFunction;
    onChange: (fieldName: 'email' | 'password') => (value: string) => void;
    passwordValidation: Rule[];
    emailRules: Rule[];
}

export const useSignIn = (): UseSignInReturn => {
    const dispatch = useAppDispatch();
    const [isAuthError, setIsAuthError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm<SignInForm>();
    const navigation = useNavigate();

    const { passwordValidation, emailRules } = useValidation(form);

    const email = Form.useWatch('email', form);
    const password = Form.useWatch('password', form);

    const allFieldsAreValid = Boolean(email && emailRegExp.test(email));

    const haveAllFields = Boolean(password && allFieldsAreValid);

    const onFinish = async (value: SignInForm) => {
        const b2cConfig = B2CController.getInstance().getConfig();

        try {
            setIsLoading(true);
            const data = await AuthApi.signIn({
                password: value.password,
                username: value.email,
                client_id: b2cConfig.clientId,
                grant_type: GRAND_TYPES.PASSWORD,
                response_type: `${RESPONSE_TYPES.TOKEN} ${RESPONSE_TYPES.ID_TOKEN}`,
                scope: `${SCOPES.OPEN_ID} ${b2cConfig.scopes.login} ${SCOPES.OFFLINE}`
            });
            if (data && data.data.access_token) {
                AuthApi.setTokens(
                    data.data.access_token,
                    data.data.refresh_token
                );
                const decoded: { oid: string } = jwt(data.data.access_token);

                if (decoded && decoded.oid) {
                    dispatch(setUserId(decoded.oid));
                }

                navigation(RoutesList.HOME.ROOT, { replace: true });
            }
        } catch (error) {
            form.setFields([
                {
                    name: 'email',
                    errors: ['']
                },
                {
                    name: 'password',
                    errors: ['']
                }
            ]);
            setIsAuthError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onForgotClick = () => {
        navigation(RoutesList.AUTH.VERIFICATION);
    };

    const onChange = (fieldName: 'email' | 'password') => (value: string) => {
        setIsAuthError(false);
        form.setFieldsValue({ [fieldName]: value });
    };

    return {
        form,
        isLoading,
        emailRules,
        isAuthError,
        haveAllFields,
        passwordValidation,
        onChange,
        onFinish,
        onForgotClick
    };
};
