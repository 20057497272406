import * as React from 'react';
import { SVGProps } from 'react';
export const SvgFinancials = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.334 1.333H3a1.333 1.333 0 0 0-1.333 1.334v10.666A1.333 1.333 0 0 0 3 14.667h8a1.333 1.333 0 0 0 1.334-1.334v-8m-4-4 4 4m-4-4v4h4M9.667 8.667H4.334m5.333 2.666H4.334M5.667 6H4.334"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
