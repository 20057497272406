import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSiteAdministration = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.8 4.2a.667.667 0 0 0 0 .933L9.867 6.2a.667.667 0 0 0 .933 0l2.513-2.513A3.999 3.999 0 0 1 8.02 8.98l-4.607 4.607a1.414 1.414 0 0 1-2-2L6.02 6.98a4 4 0 0 1 5.293-5.293L8.8 4.2Z"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
