import { useState } from 'react';

import { Form, FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import { useNavigate, useOutletContext } from 'react-router-dom';

import AuthApi from 'APIServices/auth/auth.api';
import { VerificationData } from 'APIServices/auth/authApi.types';
import { useValidation } from 'components/UI/organisms/auth/passwordRecovery/emailVerification/useValidation';
import { RoutesList } from 'utils/constants/RoutesList';
import { emailRegExp, setFieldError } from 'utils/helpers/validationHelpers';
import { VoidFunction } from 'utils/types/general/general.types';
import { ForgotFormForm } from 'utils/types/users/authForm.types';

interface UseForgotPasswordReturn {
    form: FormInstance<ForgotFormForm>;
    recoveryStep: number;
    isLoading: boolean;
    isSendCodeAvailable: boolean;
    isCodeCodeVerifyAvailable: boolean;
    onSendCode: (
        values: Pick<ForgotFormForm, 'recoveryEmail'>
    ) => Promise<void>;
    onFinish: (values: ForgotFormForm) => Promise<void>;
    email: string;
    isTokenExpired: boolean;
    resendCode: VoidFunction;
    emailRules: Rule[];
    codeRules: Rule[];
}

export const useEmailVerification = (): UseForgotPasswordReturn => {
    const navigation = useNavigate();
    const [form] = Form.useForm<ForgotFormForm>();

    const { toggleBackButton } = useOutletContext<{
        toggleBackButton: (isOpen: boolean) => void;
    }>();

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [recoveryStep, setRecoveryStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [expiredEmail, setExpiredEmail] = useState('');
    const [verificationData, setVerificationData] =
        useState<VerificationData | null>(null);

    const email = Form.useWatch('recoveryEmail', form);
    const code = Form.useWatch('code', form);

    const isSendCodeAvailable = Boolean(email && emailRegExp.test(email));
    const isCodeCodeVerifyAvailable = Boolean(code);

    const { emailRules, codeRules } = useValidation(form, isLoading);

    const handelExpiredCode = () => {
        toggleBackButton(false);
        setIsTokenExpired(true);
        form.setFieldsValue({ code: '' });
        setExpiredEmail(email);
    };

    const onSendCode = async (
        values: Pick<ForgotFormForm, 'recoveryEmail'>
    ) => {
        setIsLoading(true);

        const { response, isError } = await AuthApi.verifyEmail({
            email: values.recoveryEmail
        });

        if (isError) {
            setFieldError(
                form,
                'recoveryEmail',
                "The verification code sent. If you haven't received it, please contact a system administrator"
            );
            setIsLoading(false);
            return;
        }

        if (response) {
            setVerificationData({
                emailConfirmationId: response.data.emailConfirmationId,
                token: ''
            });
            setRecoveryStep(1);
        }

        setIsLoading(false);
    };

    const onFinish = async (values: ForgotFormForm) => {
        if (verificationData?.emailConfirmationId) {
            setIsLoading(true);
            const { response, isError, isExpired } = await AuthApi.verifyCode({
                code: values.code,
                emailConfirmationId: verificationData.emailConfirmationId
            });

            if (isExpired) {
                handelExpiredCode();
                return;
            }

            if (isError) {
                setFieldError(form, 'code', 'Verification code is incorrect');
                setIsLoading(false);
                return;
            }

            if (response) {
                const verifyData = {
                    emailConfirmationId: verificationData.emailConfirmationId,
                    token: response.data.token
                };

                setVerificationData(verifyData);
                navigation(`../${RoutesList.AUTH.CREATE_PASS}`, {
                    state: {
                        ...verifyData,
                        email
                    }
                });
            }
            setIsLoading(false);
        }
    };

    const resendCode = () => {
        onSendCode({ recoveryEmail: email || expiredEmail });
        toggleBackButton(true);
        setIsTokenExpired(false);
    };

    return {
        onFinish,
        onSendCode,
        resendCode,
        form,
        email,
        codeRules,
        isLoading,
        emailRules,
        recoveryStep,
        isTokenExpired,
        isSendCodeAvailable,
        isCodeCodeVerifyAvailable
    };
};
