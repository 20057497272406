// Note: This method is designed to work with arrays that contain unique values e.g [1, 2, 3], [5, 2 ,6]
// it returns false for [1, 1, 2], [1, 1, 2] because the arrays aren't unique

export const areUniqueArraysEqual = <T>(array1: T[], array2: T[]): boolean => {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    if (
        array1.length !== set1.size ||
        array2.length !== set2.size ||
        set1.size !== set2.size
    ) {
        return false;
    }

    return [...set1].every((item) => set2.has(item));
};
