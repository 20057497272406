import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCross6 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={8}
        height={9}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m7 1.5-6 6m0-6 6 6"
            stroke="var(--iconStroke)"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
