import React, { useEffect } from 'react';

import { Layout } from 'antd';
import jwt from 'jwt-decode';
import { Navigate, useLocation } from 'react-router-dom';

import AuthApi from 'APIServices/auth/auth.api';
import { MainLayout } from 'components/UI/organisms/mainLayout/MainLayout';
import { useSignalR } from 'signalR/SignalR';
import { EVENTS, HUBS } from 'signalR/types';
import { useAppDispatch, useAppSelector } from 'store';
import { setUserId } from 'store/slices/user/user';
import { RoutesList } from 'utils/constants/RoutesList';
import {
    getLocalStorageItem,
    LOCAL_STORAGE_FIELDS
} from 'utils/helpers/localStorage';

import styles from './Main.module.less';

enum USER_STATUS_CODES {
    ACTIVATE = 1,
    DEACTIVATED = 2,
    DELETED = 3
}

export const Main = () => {
    const { openConnection } = useSignalR<{
        userStatusCode: USER_STATUS_CODES;
    }>({
        hub: HUBS.user.notification,
        eventCallBack: ({ userStatusCode }) => {
            if (
                userStatusCode === USER_STATUS_CODES.DELETED ||
                userStatusCode === USER_STATUS_CODES.DEACTIVATED
            ) {
                AuthApi.signOut();
            }
        },
        eventName: EVENTS.RECEIVE_USER_STATUS
    });

    const dispatch = useAppDispatch();
    const location = useLocation();
    const userId = useAppSelector(({ user: { userId } }) => userId);
    const isAuthenticated = Boolean(userId);

    useEffect(() => {
        openConnection();
    }, []);

    useEffect(() => {
        const token = getLocalStorageItem(LOCAL_STORAGE_FIELDS.ACCESS_TOKEN);
        if (!token && isAuthenticated) {
            AuthApi.signOut();
        }
    }, [location, isAuthenticated]);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === null) {
                AuthApi.signOut();
            } else if (event.key === LOCAL_STORAGE_FIELDS.ACCESS_TOKEN) {
                const newAccessToken = localStorage.getItem(
                    LOCAL_STORAGE_FIELDS.ACCESS_TOKEN
                );

                if (newAccessToken) {
                    const decoded: { oid: string } = jwt(newAccessToken);

                    if (decoded && decoded.oid) {
                        dispatch(setUserId(decoded.oid));
                    }
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);

    if (!isAuthenticated) {
        return <Navigate to={`/${RoutesList.AUTH.ROOT}`} />;
    }

    return (
        <Layout className={styles.Main}>
            <MainLayout />
        </Layout>
    );
};
