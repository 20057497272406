import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { bulkResponseHandler } from 'APIServices/bulkResponseHandler';
import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import {
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from 'APIServices/inboundByBulk/InboundByBulk.type';
import { IQueryWithIntercept } from 'APIServices/queryWithIntercept';
import { IRequestStatusWithEmptyData } from 'utils/types/api/api.types';
import {
    DownloadTemplateDTO,
    BULK_TEMPLATES_NAMES,
    ColumnMappingDTO,
    ColumnMappingResponse,
    IValidateFileResponse,
    IValidateFileDTO,
    IGroupingOrdersDTO,
    IGroupingOrdersResponse,
    ISubmitOrdersDTO
} from 'utils/types/bulkOrder/bulk.types';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    requestsPath: typeof REQUESTS_PATH | typeof REQUESTS_PATH_DEFECTIVE,
    isDefective = false
) => ({
    getDownloadInboundTemplate: builder.mutation<File, DownloadTemplateDTO>({
        query: (dto) => ({
            url: requestsPath.DOWNLOAD_TEMPLATE,
            headers: {
                responseType: 'blob',
                'content-type': 'application/octet-stream'
            },
            responseHandler: async (response) =>
                bulkResponseHandler(
                    response,
                    dto,
                    isDefective
                        ? BULK_TEMPLATES_NAMES.INBOUND_DEFECTIVE
                        : BULK_TEMPLATES_NAMES.INBOUND
                ),
            cache: 'no-cache',
            method: 'POST',
            body: dto
        })
    }),
    resetInboundFile: builder.mutation<IRequestStatusWithEmptyData, string>({
        query: (id) => ({
            url: requestsPath.RESET_FILE.replace(':id', id),
            method: 'DELETE'
        })
    }),
    getInboundColumnsMapping: builder.mutation<
        ColumnMappingResponse,
        ColumnMappingDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_COLUMNS_MAPPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    validateInboundFile: builder.mutation<
        IValidateFileResponse,
        IValidateFileDTO
    >({
        query: (dto) => ({
            url: requestsPath.VALIDATE_FILE,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    getInboundGroupingOrders: builder.mutation<
        IGroupingOrdersResponse,
        IGroupingOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_GROUPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    submitInboundOrders: builder.mutation<
        IRequestStatusWithEmptyData,
        ISubmitOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_ORDERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    })
});

export const inboundByBulkApi = createApi({
    reducerPath: 'inboundByBulkApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH)
});

export const inboundByBulkDefectiveApi = createApi({
    reducerPath: 'inboundByBulkDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) =>
        getCommonEndpoints(builder, REQUESTS_PATH_DEFECTIVE, true)
});

export const {
    useGetDownloadInboundTemplateMutation,
    useResetInboundFileMutation,
    useGetInboundColumnsMappingMutation,
    useValidateInboundFileMutation,
    useGetInboundGroupingOrdersMutation,
    useSubmitInboundOrdersMutation
} = inboundByBulkApi;

export const {
    useGetDownloadInboundTemplateMutation:
        useDefectiveGetDownloadInboundTemplateMutation,
    useResetInboundFileMutation: useDefectiveResetInboundFileMutation,
    useGetInboundColumnsMappingMutation:
        useDefectiveGetInboundColumnsMappingMutation,
    useValidateInboundFileMutation: useDefectiveValidateInboundFileMutation,
    useGetInboundGroupingOrdersMutation:
        useDefectiveGetInboundGroupingOrdersMutation,
    useSubmitInboundOrdersMutation: useDefectiveSubmitInboundOrdersMutation
} = inboundByBulkDefectiveApi;
