import React from 'react';

import {
    NotAllowedTemplate,
    ERROR_PAGE_TYPE
} from 'components/templates/notAllowed/NotAllowed.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const NotFoundPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.NOT_FOUND);

    return <NotAllowedTemplate type={ERROR_PAGE_TYPE.NOT_FOUND} />;
};
