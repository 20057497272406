import React from 'react';

import { OrdersListTemplate } from 'components/templates/trackOrders/ordersList/OrdersList.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const OrdersListPage = () => {
    usePageTitle(pageHeadersList.TRACK_ORDERS.ROOT);

    return <OrdersListTemplate />;
};
