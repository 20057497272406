import React from 'react';

import { ColumnsType, ColumnType } from 'antd/lib/table';

import { SvgCheckMark } from 'components/icons';
import { SortingCell } from 'components/UI/atoms/table/sortingCell/SortingCell';
import { getFieldSorting } from 'utils/helpers/sorting';
import {
    defaultSortDirections,
    Sorting
} from 'utils/types/general/general.types';
import { ClosestWarehouse } from 'utils/types/general/warehouse.type';

interface IArgs {
    sorting: Sorting;
    hasActiveForSubaccount?: boolean;
    measureDistanceUnits?: string;
    convertDistanceValues: (distanceValue: number) => number;
}

export const getColumns = ({
    sorting,
    hasActiveForSubaccount,
    measureDistanceUnits,
    convertDistanceValues
}: IArgs) => {
    const mainColumns: ColumnsType<ClosestWarehouse> = [
        {
            title: 'Warehouse',
            dataIndex: 'code',
            key: 'code',
            width: '25%',
            render: (value, record) => {
                const alias = record.alias ? `(${record.alias})` : '';

                return `${value} ${alias}`;
            }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: '25%'
        },
        {
            dataIndex: 'distance',
            key: 'distance',
            width: '25%',
            sorter: true,
            render: (value) => {
                return Number(convertDistanceValues(value));
            },
            sortOrder: getFieldSorting('distance', sorting),
            sortDirections: defaultSortDirections,
            title: ({ sortColumns }) => (
                <SortingCell id="distance" sortColumns={sortColumns}>
                    <span id="distance">{`Distance (${measureDistanceUnits})`}</span>
                </SortingCell>
            ),
            align: 'center'
        }
    ];

    const activeForSubaccountColumn: ColumnType<ClosestWarehouse> = {
        title: 'Active for Subaccount',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '25%',
        render: (value) => value && <SvgCheckMark color="#273C83" />,
        align: 'center'
    };
    return hasActiveForSubaccount
        ? [...mainColumns, activeForSubaccountColumn]
        : mainColumns;
};
