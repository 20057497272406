import * as React from 'react';
import { SVGProps } from 'react';
export const SvgOutboundOrder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.333 11.333 8 14.667l6.666-3.334M1.333 8 8 11.333 14.666 8M8 1.333 1.333 4.667 8 8l6.666-3.333L8 1.333Z"
            stroke={props.color ?? '#fff'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
