import { BaseStepData } from 'utils/types/general/general.types';
import {
    DeliveryStepFormValues,
    SelectPartsStepOrderFormValues,
    SerialsStepFormValues,
    ShipmentDetailsFormValues
} from 'utils/types/general/stepper.types';

export enum ACTIVE_ORDER_TYPE {
    INBOUND_REPLENISHMENT_BY_PART = 'inboundReplenishmentByPart',
    INBOUND_REPLENISHMENT_BY_PART_DEFECTIVE = 'defectiveInboundReplenishmentByPart',
    INBOUND_REPLENISHMENT_BY_UPLOAD = 'inboundReplenishmentByUpload',
    INBOUND_REPLENISHMENT_BY_UPLOAD_DEFECTIVE = 'defectiveInboundReplenishmentByUpload',
    INBOUND_RETURN_BY_PART = 'inboundReturnByPart',
    GOOD_BY_WAREHOUSE = 'goodBySslOrder',
    DEFECTIVE_BY_WAREHOUSE = 'defectiveBySslOrder',
    GOOD_BY_PART = 'goodByPartOrder',
    TRANSFER_BY_WAREHOUSE = 'bySslTransfer',
    TRANSFER_BY_WAREHOUSE_DEFECTIVE = 'defectiveBySslTransfer'
}

export type ActiveOrderFormValues = SelectPartsStepOrderFormValues &
    DeliveryStepFormValues &
    SerialsStepFormValues &
    ShipmentDetailsFormValues;

export interface ActiveOrderStepData extends BaseStepData {
    initialValues?: Partial<ActiveOrderFormValues>;
    onFinish?: (values: ActiveOrderFormValues) => void;
}
