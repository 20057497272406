import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSmallCube = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.18 5.14 8 8.507l5.82-3.367M8 15.22V8.5m6 2.667V5.833a1.333 1.333 0 0 0-.667-1.153L8.667 2.013a1.333 1.333 0 0 0-1.334 0L2.667 4.68A1.333 1.333 0 0 0 2 5.833v5.334a1.334 1.334 0 0 0 .667 1.153l4.666 2.667a1.334 1.334 0 0 0 1.334 0l4.666-2.667A1.333 1.333 0 0 0 14 11.167Z"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
