interface IUserNameData {
    firstName: string;
    lastName: string;
}

export const convertNameToInitials = (
    userNameData?: Partial<IUserNameData>
) => {
    if (!userNameData) return '';
    const { firstName, lastName } = userNameData;
    const userName = [firstName, lastName];
    return userName
        .map((name) => name?.split(' ').slice(0, 1)[0].charAt(0).toUpperCase())
        .join('');
};
