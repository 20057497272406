import * as React from 'react';
import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export const SvgFile = (props: IProps) => (
    <svg
        width={14}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.664.832H2.997a1.333 1.333 0 0 0-1.333 1.333v10.667a1.333 1.333 0 0 0 1.333 1.333h8a1.333 1.333 0 0 0 1.334-1.333V5.499M7.664.832l4.667 4.667M7.664.832v4.667h4.667"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
