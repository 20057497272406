import React from 'react';

import { PartCount } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { ToolTip } from 'components/UI/atoms/tooltip/ToolTip';
import { capitalize } from 'utils/helpers/stringHelper';

import styles from './PartsCount.module.less';

interface IProps {
    partsCount: PartCount;
    isMobile?: boolean;
}

export const PartsCount = ({ partsCount, isMobile }: IProps) => {
    const totalCount = Object.values(partsCount).reduce(
        (cur, acc) => cur + acc,
        0
    );

    const tooltipText = Object.entries(partsCount)
        .map(([key, value]) =>
            value
                ? `${value} ${capitalize(key === 'bad' ? 'defective' : key)}`
                : ''
        )
        .filter(Boolean)
        .join(',');

    return (
        <span className={styles.Text}>
            <ToolTip
                title={tooltipText}
                overlayClassName={styles.Tooltip}
                placement={isMobile ? 'bottomRight' : 'bottomLeft'}
            >
                {totalCount}
            </ToolTip>
        </span>
    );
};
