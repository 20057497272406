import * as React from 'react';
import { SVGProps } from 'react';
export const SvgHome = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5 14.667V8h4v6.667M1 6l6-4.667L13 6v7.333a1.333 1.333 0 0 1-1.333 1.334H2.333A1.333 1.333 0 0 1 1 13.333V6Z"
            stroke={props.color ?? '#fff'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
