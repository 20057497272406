import * as React from 'react';
import { SVGProps } from 'react';
export const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.034 3.833a3.333 3.333 0 0 1 2.633 2.633m-2.633-5.3a6 6 0 0 1 5.3 5.293m-.667 5.32v2a1.332 1.332 0 0 1-1.453 1.334 13.193 13.193 0 0 1-5.754-2.047 13 13 0 0 1-4-4 13.193 13.193 0 0 1-2.046-5.78A1.333 1.333 0 0 1 2.74 1.833h2a1.333 1.333 0 0 1 1.334 1.146 8.56 8.56 0 0 0 .466 1.874 1.333 1.333 0 0 1-.3 1.406l-.846.847a10.666 10.666 0 0 0 4 4l.846-.847a1.333 1.333 0 0 1 1.407-.3 8.562 8.562 0 0 0 1.873.467 1.333 1.333 0 0 1 1.147 1.353Z"
            stroke={props.color || '#2C2F3A'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
