import * as React from 'react';
import { SVGProps } from 'react';

export const SvgIconRight = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.333 8h9.334m0 0L8 3.333M12.667 8 8 12.667"
            stroke={color || '#fff'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
