import React from 'react';

import { Select as AntSelect } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import classNames from 'classnames';

import { IState } from 'APIServices/countries/CountriesApi.types';
import {
    FormSelect,
    IFormSelectProps
} from 'components/UI/molecules/form/formSelect/FormSelect';
import { ShipmentDetailsValidationRules } from 'components/UI/organisms/shipmentDetails/validationRules';
import { filterString } from 'utils/helpers/filtering';

import styles from './ProvinceSelect.module.less';

const { Option } = AntSelect;

const { shipmentStateRequired } = ShipmentDetailsValidationRules;

export interface IProvinceSelectProps extends Partial<IFormSelectProps> {
    onChangeSelectState?: (value: string) => void;
    states?: IState[];
    fieldName?: string;
    isRequired?: boolean;
}

const filterStates = (input: string, option?: DefaultOptionType): boolean => {
    const { displayName, provinceCode } = option?.data;
    return (
        filterString(input.trim(), displayName) ||
        filterString(input.trim(), provinceCode)
    );
};

export const ProvinceSelect = ({
    onChangeSelectState,
    states = [],
    isRequired,
    fieldName = 'state',
    ...props
}: IProvinceSelectProps) => {
    return (
        <div
            className={classNames(
                styles.ProvinceSelectContainer,
                props.className
            )}
            id="ProvinceSelect"
        >
            <FormSelect
                {...props}
                onChange={onChangeSelectState}
                required={isRequired}
                fieldName={fieldName}
                title="State/Province"
                initialValue={null}
                showSearch
                allowClear
                optionLabelProp="label"
                filterOption={filterStates}
                placeholder="Select State or Province"
                id="state"
                rules={isRequired ? shipmentStateRequired : []}
                data={states?.map((item, i) => (
                    <Option
                        key={item.stateId}
                        value={item.displayName}
                        data={item}
                        className={styles.Option}
                        id={`StateOption_${i}`}
                    >
                        <div className={styles.Option}>
                            <span
                                id={`StateName_${i}`}
                                className={styles.Option__Name}
                            >
                                {item.displayName}
                                &nbsp;
                            </span>
                            <span>{item.provinceCode}</span>
                        </div>
                    </Option>
                ))}
            />
        </div>
    );
};
