import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCopyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.334 10.5h-.667a1.333 1.333 0 0 1-1.333-1.333v-6a1.333 1.333 0 0 1 1.333-1.333h6a1.333 1.333 0 0 1 1.334 1.333v.667M7.334 6.5h6c.736 0 1.333.597 1.333 1.333v6c0 .737-.597 1.334-1.333 1.334h-6a1.333 1.333 0 0 1-1.333-1.333v-6C6 7.096 6.598 6.5 7.334 6.5Z"
            stroke="#B0BDE8"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
