import { IInitialPartMaster } from 'store/slices/partMaster/partMaster';
import { IInitialPreCheckMaster } from 'store/slices/preCheckMaster/preCheckMaster';
import { IInitialSerialsMaster } from 'store/slices/serialsMaster/serialsMaster';
import { PreCheckResponse } from 'utils/types/api/api.types';
import {
    IGetPartTransformedResponse,
    GetSerialsTransformedResponse
} from 'utils/types/parts/parts.types';

type FulfilledActionType = {
    payload:
        | GetSerialsTransformedResponse
        | PreCheckResponse
        | IGetPartTransformedResponse;
};

type FulfilledStateType =
    | IInitialSerialsMaster
    | IInitialPreCheckMaster
    | IInitialPartMaster;

export const handleFulfilledPagination = (
    state: FulfilledStateType,
    action: FulfilledActionType
) => {
    const { payload } = action;

    state.pagination.totalItemsCount = payload.data?.totalItemsCount || 0;
    state.pagination.pagesCount = payload.data?.pagesCount || 0;
    state.data = payload.data?.items;
    state.errors = payload.data?.error;
};
