import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m5 6.833 2 2 6.667-6.666M13 7.5v4.667a1.334 1.334 0 0 1-1.333 1.333H2.333A1.334 1.334 0 0 1 1 12.167V2.833A1.333 1.333 0 0 1 2.333 1.5h7.334"
            stroke="#26A671"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
