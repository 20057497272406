import { MEASUREMENTS } from 'APIServices/users/UsersApi.types';
import { useUserSettings } from 'utils/hooks/useUserSettings';

export const useWeightOrDistanceConverter = () => {
    const { measurementKey } = useUserSettings();
    const convertWeightValues = (
        weightValue: number,
        onlyPounds?: boolean
    ): string => {
        if (measurementKey === MEASUREMENTS.US || onlyPounds) {
            return weightValue ? weightValue.toFixed(2) : '0.00';
        }

        return weightValue ? (weightValue * 0.45359237).toFixed(2) : '0.00';
    };

    const convertDistanceValues = (distanceValue: number) => {
        if (measurementKey === MEASUREMENTS.US) {
            return distanceValue;
        }

        return +(distanceValue * 1.60934).toFixed(0);
    };

    const measureWeightUnits = measurementKey === MEASUREMENTS.US ? 'lb' : 'kg';
    const defaultPoundsUnits = 'lb';
    const defaultKilogramsUnits = 'kg';
    const measureDistanceUnits =
        measurementKey === MEASUREMENTS.US ? 'mi' : 'km';

    return {
        convertWeightValues,
        convertDistanceValues,
        measureWeightUnits,
        defaultPoundsUnits,
        defaultKilogramsUnits,
        measureDistanceUnits
    };
};
