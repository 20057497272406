import React from 'react';

import { Table as AntTable } from 'antd';
import type { TableProps } from 'antd/es/table';
import classNames from 'classnames';

import { SvgArrow } from 'components/icons';
import { Loader } from 'components/UI/atoms/loader/Loader';

import styles from './Table.module.less';

export interface ITableProps<T> extends TableProps<T> {
    isLoading: boolean;
    withHighlightedExpandedRow?: boolean;
}

export const Table = <T extends Record<'id', string>>(
    props: ITableProps<T>
) => {
    const className = classNames(styles.Table, props.className, {
        [styles.Table__HighlightedExpandedRow]: props.withHighlightedExpandedRow
    });

    return (
        <>
            <AntTable
                rowKey={(record) => record.id}
                bordered={false}
                showSorterTooltip={false}
                className={className}
                pagination={false}
                loading={{
                    spinning: props.isLoading,
                    indicator: <Loader className={styles.Table__Loader} />
                }}
                data-testid="Table"
                {...props}
                expandable={
                    props.expandable
                        ? {
                              expandIcon: ({ expanded, onExpand, record }) => (
                                  <div onClick={(e) => onExpand(record, e)}>
                                      <SvgArrow
                                          color="#8e94a9"
                                          data-testid={
                                              expanded
                                                  ? 'expandedRowIcon'
                                                  : 'collapsedRowIcon'
                                          }
                                          className={classNames({
                                              [styles.Expand__Icon]: !expanded
                                          })}
                                      />
                                  </div>
                              ),
                              ...props.expandable
                          }
                        : {}
                }
            />
        </>
    );
};
