export const MAX_LENGTHS = {
    companyName: 35,
    addressLine1: 35,
    addressLine2: 35,
    city: 35,
    postalCode: 10,
    contactName: 35,
    email: 80,
    phoneNumber: 10
};
