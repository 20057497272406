import { ConsigneeConsignorAddress } from 'utils/types/api/api.types';
import { AvailableServicesDTO } from 'utils/types/deliveryServices/deliveryServices.types';

export const REQUESTS_PATH = {
    PARTS: '/Replenishments/GoodParts/Parts',
    REFERENCE_LABELS: '/Replenishments/GoodParts/GetOrderReferenceNumberLabels',
    SUBMIT: '/Replenishments/GoodParts/SubmitOrder',
    GET_SERVICES: '/Replenishments/GoodParts/AvailableServices',
    ACCOUNT_WAREHOUSE: '/Replenishments/GoodParts/AccountSSLs'
} as const;

export const REQUESTS_PATH_DEFECTIVE = {
    PARTS: '/ReturnParts/DefectiveToChoice/Parts',
    REFERENCE_LABELS:
        '/ReturnParts/DefectiveToChoice/GetOrderReferenceNumberLabels',
    SUBMIT: '/ReturnParts/DefectiveToChoice/SubmitOrder',
    GET_SERVICES: '/ReturnParts/DefectiveToChoice/AvailableServices',
    ACCOUNT_WAREHOUSE: '/ReturnParts/DefectiveToChoice/AccountSSLs'
} as const;

export interface AvailableMaterialServicesDTO
    extends Omit<AvailableServicesDTO, 'consigneeAddress'> {
    consigneeSsl: string;
    consignorAddress: Partial<ConsigneeConsignorAddress>;
}
