import { useEffect } from 'react';

import { defaultPageTitle } from 'utils/constants/pageTitles';

export const usePageTitle = (pageTitle: string) => {
    const titleValue =
        pageTitle && pageTitle !== 'Home'
            ? `Compass ${pageTitle} Choice Logistics`
            : defaultPageTitle;

    useEffect(() => {
        document.title = titleValue;
    }, [pageTitle]);

    return titleValue;
};
