import React, { ReactNode } from 'react';

import { Menu, Dropdown as AntDropdown, Space } from 'antd';
import classNames from 'classnames';

import { SvgArrow } from 'components/icons';
import { VoidFunction } from 'utils/types/general/general.types';

import styles from './Dropdown.module.less';

interface MenuItem {
    label: string;
    icon: ReactNode;
    onClick?: VoidFunction;
}

interface IProps {
    trigger?: ReactNode;
    showIcon?: boolean;
    arrowColor?: string;
    items?: MenuItem[];
    className?: string;
    openClassName?: string;
}

export const Dropdown = ({
    showIcon = true,
    trigger,
    arrowColor = '#8E94A9',
    items = [],
    className,
    openClassName
}: IProps) => {
    const menu = (
        <Menu
            items={items.map(({ label, icon, onClick }, index) => ({
                label: (
                    <div id={`menuItem_${index}`} className={styles.MenuItem}>
                        {icon}
                        <div className={styles.MenuItem__Text}>{label}</div>
                    </div>
                ),
                key: index,
                onClick
            }))}
            className={classNames(styles.Menu, className)}
        />
    );

    return (
        <AntDropdown
            overlay={menu}
            trigger={['click']}
            className={styles.Container}
            openClassName={classNames(styles.Container__Open, openClassName)}
            destroyPopupOnHide={true}
            getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
            }
        >
            <Space>
                <div className={styles.Container__Title}>{trigger}</div>
                {showIcon && (
                    <div className={styles.IconContainer}>
                        <SvgArrow color={arrowColor} />
                    </div>
                )}
            </Space>
        </AntDropdown>
    );
};
