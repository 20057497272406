export interface Client {
    clientId: number;
    clientName: string;
    hasProfiles: boolean;
}

export interface ClientProfileForSUser {
    profileId: number;
    name: string;
}

export interface ClientProfile extends ClientProfileForSUser {
    clientId?: number;
}

export enum REQUESTS_PATH {
    CLIENTS = '/Clients/GetNames',
    GET_ACCOUNTS_AND_SUBS = '/Clients/GetAccountsAndSubAccounts',
    GET_CLIENT_PROFILES = '/Clients/GetClientProfiles'
}
