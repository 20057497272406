import React from 'react';

import styles from './Welcome.module.less';

interface Props {
    name?: string;
    lastName?: string;
    welcomeMessage: string;
}

export const Welcome = ({ welcomeMessage, name, lastName }: Props) => {
    return (
        <div className={styles.Container}>
            <div className={styles.Container__Title}>
                {name && lastName ? `Welcome ${name} ${lastName}` : 'Welcome'}
            </div>
            <div className={styles.Container__Message}>{welcomeMessage}</div>
        </div>
    );
};
