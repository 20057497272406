import * as React from 'react';
import { SVGProps } from 'react';
export const SvgBasket16 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 4h1.333m0 0H14M3.333 4v9.334a1.333 1.333 0 0 0 1.334 1.333h6.666a1.333 1.333 0 0 0 1.334-1.333V4.001H3.333Zm2 0V2.668a1.333 1.333 0 0 1 1.334-1.333h2.666a1.333 1.333 0 0 1 1.334 1.333v1.334"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
