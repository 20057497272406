export enum PERMISSIONS_KEYS {
    // Outbound Orders
    orderByPart = 'customer-orders-good-parts',
    orderByWarehouse = 'return-parts-good-from-choice',
    defectiveByWarehouse = 'return-parts-defective-from-choice',
    defectiveByUpload = 'return-parts-defective-from-choice-upload',
    outboundOrderByBulk = 'customer-orders-good-parts-upload',
    trackOrders = 'track-orders',
    orderHistory = 'order-history',

    // Materials Management
    inboundReplenishment = 'replenishments-good-parts',
    inboundReturn = 'return-parts-good-to-choice',
    defectiveByPart = 'return-parts-defective-to-choice',
    inboundOrderByBulk = 'replenishments-good-parts-upload',
    interWarehouseTransferByWarehouse = 'warehouse-transfers-good-parts',
    interWarehouseTransferByPart = 'inter-ssl-transfer-by-part',
    inboundDefectiveByUpload = 'return-parts-defective-to-choice-upload',

    // Create Transfers
    transferGoodByUpload = 'warehouse-transfers-good-parts-upload',
    transferDefectiveByWarehouse = 'warehouse-transfers-defective-parts',
    transferDefectiveByUpload = 'warehouse-transfers-defective-parts-upload',

    // Manage parts
    partMaster = 'manage-parts-part-master',
    substituteParts = 'manage-parts-substitute-parts',

    // Manage inventory
    locateClosestWarehouse = 'manage-inventory-locate-closest-ssl',
    inventoryOnHand = 'manage-inventory-inventory-on-hand',
    inventoryTransactionHistory = 'manage-inventory-inventory-transaction-history',
    materialInReview = 'manage-inventory-material-in-review',
    materialInReviewAnalysis = 'manage-inventory-materials-in-review-analysis',
    missingPart = 'manage-inventory-missing-parts',
    outstandingInbounds = 'manage-inventory-outstanding-inbounds',
    outstandingReturns = 'manage-inventory-outstanding-returns',

    // Financials
    invoices = 'financials-invoices',
    storageBilling = 'financials-storage-billing',
    clientBilling = 'financials-client-billing',
    clientSpend = 'financials-client-spend',
    dutiesTaxesAnalysis = 'financials-duties-taxes-analysis',

    // Analytics
    analyticsOrderHistory = 'analytics-order-history',
    cycleCountPerformance = 'analytics-cycle-count-performance',
    globalCapabilities = 'analytics-global-capabilities',
    dockToStock = 'analytics-dock-to-stock',
    outboundDriveFillRate = 'analytics-outbound-drive-fill-rate',
    outboundDrivePerformance = 'analytics-outbound-drive-performance',
    tenderToCarrierPerformance = 'analytics-tender-to-carrier-performance',

    // Admin Panel
    admins = 'admins',
    users = 'users',
    profiles = 'profiles',
    choiceAdmins = 'choice-admins'
}

export enum MENU_SECTIONS_PERMISSION_KEYS {
    outboundOrders = 'customer-orders',
    replenishments = 'replenishments',
    materialsManagement = 'return-parts',
    createTransfer = 'warehouse-transfers',
    manageParts = 'manage-parts',
    manageInventory = 'manage-inventory',
    financials = 'financials',
    analytics = 'analytics',
    adminPanel = 'admin-panel',
    trackOrders = 'track-orders',
    home = 'home'
}
