import React from 'react';

import { Button } from 'components/UI/atoms/button/Button';
import { SearchBadge } from 'components/UI/atoms/searchBadge/SearchBadge';
import { VoidFunction } from 'utils/types/general/general.types';

import styles from './SelectedFilterValues.module.less';

interface SelectedFiltersData {
    onRemove?: (value: string) => void;
    label?: string;
    values?: string[] | { title: string; value: string }[];
    isSelected?: boolean;
}
interface IProps {
    withoutDelete?: boolean;
    stopPropagation?: boolean;
    onRemoveFilterValues?: VoidFunction;
    chosenItemMobileMode?: boolean;
    selectedFiltersData: SelectedFiltersData[];
    clearAllSelectedFilters?: VoidFunction;
    clearAllDesktop: boolean;
}

export const SelectedFilterValues = ({
    withoutDelete,
    onRemoveFilterValues,
    stopPropagation,
    chosenItemMobileMode,
    selectedFiltersData,
    clearAllSelectedFilters,
    clearAllDesktop
}: IProps) => {
    const lastSelectedItem = selectedFiltersData.reduce((acc, item, index) => {
        return item.isSelected ? index : acc;
    }, -1);

    const spawnSelectedFilterValues = () => {
        return (
            <>
                {selectedFiltersData?.map((item, filterItemIndex) => {
                    return item?.values?.length && item.isSelected ? (
                        <div key={filterItemIndex}>
                            <div
                                className={styles.Container}
                                data-testid={item.label}
                            >
                                {item.label && (
                                    <div
                                        className={styles.Label}
                                        data-testid="SelectedFilterLabel"
                                    >
                                        {item.label}:
                                    </div>
                                )}
                                {item?.values?.map((valuesItem, index) => {
                                    const [title, value] =
                                        typeof valuesItem === 'string'
                                            ? [valuesItem, valuesItem]
                                            : [
                                                  valuesItem.title,
                                                  valuesItem.value
                                              ];

                                    const defaultOnRemove = () => {
                                        if (onRemoveFilterValues)
                                            return onRemoveFilterValues();

                                        return item.onRemove
                                            ? item.onRemove(value)
                                            : null;
                                    };

                                    return (
                                        <SearchBadge
                                            key={`${value}_${index}`}
                                            id={value}
                                            text={title}
                                            withoutDelete={withoutDelete}
                                            maxWidthForText={
                                                chosenItemMobileMode
                                            }
                                            removeBadges={defaultOnRemove}
                                            data-testid="SelectedFilterValue"
                                            stopPropagation={stopPropagation}
                                        />
                                    );
                                })}

                                {filterItemIndex === lastSelectedItem &&
                                    clearAllDesktop && (
                                        <Button
                                            theme="link"
                                            text="Clear All"
                                            onClick={clearAllSelectedFilters}
                                            className={styles.ClearAll}
                                        />
                                    )}
                            </div>
                        </div>
                    ) : null;
                })}
            </>
        );
    };

    return spawnSelectedFilterValues();
};
