import { Pagination } from 'utils/types/general/general.types';

export const initialPaginationState: Pagination = {
    page: 1,
    pageSize: 10,
    pagesCount: 0,
    totalItemsCount: 0
};

export const defaultPerPageList: number[] = [10, 20, 50, 100];

export const initialPerPage = 10;

export enum PAGINATION_ERROR_CODES {
    NO_DATA_NO_MESSAGE = 1,
    NO_DATA = 3, // no data in our database
    NO_DATA_BY_FILTER = 4 // no data based on the given filter
}
