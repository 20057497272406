import React from 'react';

import { ILabelProps } from 'components/UI/atoms/label/Label';
import { Refresh } from 'components/UI/atoms/refresh/Refresh';
import {
    IComparedFilterValues,
    ISelectedFilters
} from 'components/UI/organisms/allFiltersWrapper/AllFiltersWrapper';
import { OrderStatusLabel } from 'components/UI/organisms/mirAndTracking/orderStatusLabel/OrderStatusLabel';
import styles from 'components/UI/organisms/mirAndTracking/orderStatusLabelsBar/OrderStatusLabelsBar.module.less';
import { areUniqueArraysEqual } from 'utils/helpers/array';
import {
    getStatusVariant,
    MIR_STATUSES,
    PROGRESS_STATUSES
} from 'utils/helpers/orderStatus';
import { Status, VoidFunction } from 'utils/types/general/general.types';

interface IProps {
    statuses: Status[];
    onRefreshClick: VoidFunction;
    onStatusSelect?: (status: PROGRESS_STATUSES | MIR_STATUSES) => void;
    lastUpdated?: string;
    defaultStatusVariant?: ILabelProps['variant'];
    disabled?: boolean;
    selectedStatus?: PROGRESS_STATUSES | MIR_STATUSES;
    isMobile?: boolean;
    setNewFilters?: (newFilter: ISelectedFilters) => void;
    setComparedResults?: (newComparedValue: IComparedFilterValues) => void;
    selectedStatusItem?: PROGRESS_STATUSES | MIR_STATUSES;
    setSelectedStatusItem?: (
        statusItem: PROGRESS_STATUSES | MIR_STATUSES
    ) => void;
}

export const OrderStatusLabelsBar = ({
    statuses,
    onRefreshClick,
    lastUpdated,
    defaultStatusVariant,
    disabled,
    selectedStatus,
    onStatusSelect,
    isMobile = false,
    selectedStatusItem,
    setSelectedStatusItem,
    setNewFilters,
    setComparedResults
}: IProps) => {
    const onCurrentStatusSelect = (
        status: PROGRESS_STATUSES | MIR_STATUSES
    ) => {
        setSelectedStatusItem?.(status);
        const isStatusesEqual = areUniqueArraysEqual(
            [selectedStatus],
            [status]
        );
        setComparedResults?.({ isStatusChanged: isStatusesEqual });

        setNewFilters?.({ selectedStatus: status });
    };

    return (
        <div
            className={isMobile ? styles.ContainerMobile : styles.Container}
            id={!isMobile ? 'OrderStatusLabelBar' : 'OrderStatusLabelBarMobile'}
        >
            <div
                className={styles.Labels}
                id={
                    !isMobile
                        ? 'OrderStatusLabelBar'
                        : 'OrderStatusLabelBarMobile'
                }
            >
                {statuses?.map((status, index) => {
                    return (
                        <OrderStatusLabel
                            variant={
                                defaultStatusVariant
                                    ? defaultStatusVariant
                                    : getStatusVariant(status.label)
                            }
                            status={status.label}
                            count={status.value}
                            key={`${status}_${index}`}
                            disabled={disabled}
                            selected={
                                (isMobile
                                    ? selectedStatusItem
                                    : selectedStatus) === status.label
                            }
                            isMobile={isMobile}
                            onStatusClick={
                                isMobile
                                    ? onCurrentStatusSelect
                                    : onStatusSelect
                            }
                        />
                    );
                })}
            </div>

            {!isMobile && (
                <Refresh
                    onRefreshClick={onRefreshClick}
                    lastUpdated={lastUpdated}
                />
            )}
        </div>
    );
};
