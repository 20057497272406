import React from 'react';

import { HomeTemplate } from 'components/templates/home/Home.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const HomePage = () => {
    usePageTitle(pageHeadersList.HOME_PAGE.ROOT);

    return <HomeTemplate />;
};
