import { lazy } from 'react';

const ProfilesPage = lazy(
    () => import('components/pages/adminPanel/profiles/Profiles.page')
);
const ProfilesCreatePage = lazy(
    () => import('components/pages/adminPanel/profiles/ProfilesCreate.page')
);

const OrderDetailsPage = lazy(
    () => import('components/pages/trackOrders/orderDetails/OrderDetails.page')
);

const OutboundBulkPage = lazy(
    () => import('components/pages/orders/outboundBulk/OutboundBulk.page')
);

const OrderByPartPage = lazy(
    () => import('components/pages/orders/customerOrder/CustomerOrder.page')
);

const OrderByWarehousePage = lazy(
    () => import('components/pages/orders/fromChoice/OrderFromChoice.page')
);

const ChoiceAdminPage = lazy(
    () => import('components/pages/adminPanel/choiceAdmin/ChoiceAdmin.page')
);

const ChoiceAdminCreatePage = lazy(
    () =>
        import('components/pages/adminPanel/choiceAdmin/ChoiceAdminCreate.page')
);

const AdminsPage = lazy(
    () => import('components/pages/adminPanel/admins/Admins.page')
);

const AdminCreatePage = lazy(
    () => import('components/pages/adminPanel/admins/AdminCreatePage')
);

const AdminUsersPage = lazy(
    () => import('components/pages/adminPanel/users/AdminUsers.page')
);

const AdminUserCreatePage = lazy(
    () => import('components/pages/adminPanel/users/AdminUserCreatePage')
);

const MirDetailsPage = lazy(
    () =>
        import(
            'components/pages/manageInventory/MaterialInReview/mirDetails/MirDetails.page'
        )
);

const InboundBulkPage = lazy(
    () => import('components/pages/materials/inboundBulk/InboundBulk.page')
);

const TransfersBulkPage = lazy(
    () => import('components/pages/materials/transfersBulk/TransfersBulk.page')
);

const MaterialInReviewPage = lazy(
    () =>
        import(
            'components/pages/manageInventory/MaterialInReview/MaterialInReview.page'
        )
);

const InboundReplenishmentPage = lazy(
    () =>
        import(
            'components/pages/materials/inboundReplenishment/InboundReplenishment.page'
        )
);

const InboundReturnPage = lazy(
    () => import('components/pages/materials/goodToChoice/GoodToChoice.page')
);

const TransferByWarehousePage = lazy(
    () =>
        import(
            'components/pages/materials/transferByWarehouse/TransferByWarehouse.page'
        )
);

export {
    OrderByPartPage,
    OrderByWarehousePage,
    ChoiceAdminPage,
    ChoiceAdminCreatePage,
    AdminsPage,
    AdminCreatePage,
    AdminUsersPage,
    AdminUserCreatePage,
    OutboundBulkPage,
    OrderDetailsPage,
    ProfilesPage,
    ProfilesCreatePage,
    MirDetailsPage,
    TransfersBulkPage,
    InboundBulkPage,
    MaterialInReviewPage,
    InboundReplenishmentPage,
    TransferByWarehousePage,
    InboundReturnPage
};
