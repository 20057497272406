import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCheckMark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={13}
        height={10}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.833 1.5 4.5 8.833 1.167 5.5"
            stroke={props.color || '#8E94A9'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
