import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={11}
        height={10}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.416 3.704c0 .1-.036.187-.108.26L5.756 6.557a.348.348 0 0 1-.256.11.348.348 0 0 1-.257-.11L2.691 3.964a.359.359 0 0 1-.108-.26c0-.1.036-.187.108-.26a.348.348 0 0 1 .257-.11h5.104c.098 0 .184.036.256.11.072.073.108.16.108.26Z"
            fill="#8E8EA9"
        />
    </svg>
);
