import * as React from 'react';
import { SVGProps } from 'react';
export const SvgClock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#Right_Icon_svg__a)">
            <path
                d="M8 4v4l2.666 1.333m4-1.333A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.333 0Z"
                stroke="#8E94A9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="Right_Icon_svg__a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
