import { useState } from 'react';

import { VoidFunction } from 'utils/types/general/general.types';

export type ModalData<T = string> = T | null;

export interface UseModalReturnType<T> {
    isOpen: boolean;
    toggle: VoidFunction;
    closeModal: VoidFunction;
    openModal: VoidFunction;
    modalData: ModalData<T>;
    setModalData: (data?: ModalData<T>) => void;
}

export function useModal<T = string>(): UseModalReturnType<T> {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [data, setData] = useState<ModalData<T>>(null);

    const toggle = () => {
        setIsOpen((prevState) => !prevState);
    };

    const setModalData = (data?: ModalData<T>) => {
        setData(data ?? null);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    return {
        isOpen,
        modalData: data,
        setModalData,
        toggle,
        closeModal,
        openModal
    };
}
