import { RootState } from 'store';

export const headerSelector = ({ activeOrder, bulk }: RootState) => {
    const data = {
        redirectBlocker: [activeOrder.redirectBlocker, bulk.redirectBlocker],
        activeSteps: {
            activeOrder: activeOrder.activeStep,
            bulk: bulk.activeStep
        },
        loadings: {
            activeOrder: activeOrder.loading
        },
        orderType: activeOrder.orderType
    };

    return {
        activeSteps: data.activeSteps,
        loadings: data.loadings,
        isRedirectBlocked: data.redirectBlocker.some(Boolean),
        orderType: data.orderType
    };
};
