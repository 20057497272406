import React from 'react';

import { DundasReport } from 'components/UI/organisms/reports/dundasReport/DundasReport';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { PERMISSIONS_KEYS } from 'utils/constants/permissionsKeys';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const PartMasterPage = () => {
    usePageTitle(pageHeadersList.MANAGE_PARTS.PART_MASTER);

    return <DundasReport permissionKey={PERMISSIONS_KEYS.partMaster} />;
};
