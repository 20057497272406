export const capitalize = (value: string): string => {
    const wordsArray = value.split(' ');
    const capitalizedArray = wordsArray.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedArray.join(' ');
};

export const escapeRegExp = (text: string, skipDivider = false): string => {
    const regExp = skipDivider ? /[,\\><]/g : /[,|\\><]/g;
    return text.replace(regExp, '\\$&').replace(/"/g, '\x22');
};

export const includesSubstring = (inputValue: string, sourceText: string) =>
    sourceText.toLowerCase().includes(inputValue.toLowerCase());

export const transformTextRegister = (textInfo: string) => {
    return capitalize(textInfo.toLowerCase());
};
