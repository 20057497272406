import * as React from 'react';
import { SVGProps } from 'react';
export const SvgManageParts = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m11 6.267-6-3.46M2.18 4.64 8 8.007l5.82-3.367M8 14.72V8m6 2.667V5.334a1.333 1.333 0 0 0-.667-1.154L8.667 1.514a1.333 1.333 0 0 0-1.334 0L2.667 4.18A1.333 1.333 0 0 0 2 5.334v5.333a1.333 1.333 0 0 0 .667 1.153l4.666 2.667a1.333 1.333 0 0 0 1.334 0l4.666-2.667A1.333 1.333 0 0 0 14 10.667Z"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
