export enum REQUEST_PATH {
    GET_PERMISSIONS = '/Permissions/GetPermissions'
}

interface BasePermission {
    displayName: string;
    permissionGroupId: number | null;
}

export interface Permission extends BasePermission {
    permissionId: number;
    requireTransactionalSubAccount?: boolean;
    keys: string[];
    sortOrder: number;
}

export type Groups = Omit<Permission, 'key'>;

export interface GetPermissionsResponse extends BasePermission {
    permissions: Permission[];
    groups: Groups[];
}

export interface GetTransformedPermissions extends Groups {
    permissions: Permission[] | null;
}
