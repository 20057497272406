import {
    AccountSubAccount,
    RequestStatusWithData
} from 'utils/types/api/api.types';

export enum REQUESTS_PATH {
    UPLOAD_INBOUND_FILE = '/Replenishments/GoodPartsUpload/UploadFile',
    UPLOAD_DEFECTIVE_INBOUND_FILE = '/ReturnParts/DefectiveToChoiceUpload/UploadFile',
    UPLOAD_OUTBOUND_FILE = '/CustomerOrders/GoodPartsUpload/UploadFile',
    UPLOAD_DEFECTIVE_OUTBOUND_FILE = '/ReturnParts/DefectiveFromChoiceUpload/UploadFile',
    UPLOAD_TRANSFERS_FILE = '/WarehouseTransfers/GoodPartsUpload/UploadFile',
    UPLOAD_DEFECTIVE_TRANSFERS_FILE = '/WarehouseTransfers/DefectivePartsUpload/UploadFile',
    ADD_DOCUMENT = '/TrackOrders/AddTicketDocument',
    UPDATE_DOCUMENT = '/TrackOrders/UpdateTicketDocument',
    ADD_MIR_DOCUMENT = '/MaterialInReview/AddDocument'
}

export type IUploadFileSuccessResponse = RequestStatusWithData<{
    fileId: string;
}>;

export interface IUploadFileDTO extends AccountSubAccount {
    file: FormData;
}

export interface IUploadFileError {
    error: {
        status: number;
        data: string;
    };
}

export type IUploadFileResponse = IUploadFileSuccessResponse | IUploadFileError;

export interface AddDocumentData {
    ticketDocumentHistoryId: number;
}
export type AddDocumentResponse = RequestStatusWithData<AddDocumentData>;
