import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCreateTransfers = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#CreateTransfers_svg__a)">
            <path
                d="M11.333.667 14 3.334m0 0L11.333 6M14 3.334H4.667A2.667 2.667 0 0 0 2 6v1.334m2.667 8L2 12.667m0 0L4.667 10M2 12.667h9.333A2.667 2.667 0 0 0 14 10V8.667"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="CreateTransfers_svg__a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
