import React, { useState } from 'react';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { OrderType } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { SvgOutboundOrder } from 'components/icons';
import { Checkbox, CheckboxGroup } from 'components/UI/atoms/checkbox/Checkbox';
import {
    Filter,
    IFilterProps
} from 'components/UI/organisms/trackOrders/orderList/components/filters/filter/Filter';
import { areUniqueArraysEqual } from 'utils/helpers/array';

import styles from './OrderTypeFilter.module.less';

interface IProps extends Partial<IFilterProps> {
    onApplyOrderTypes: (value: string[]) => void;
    selectedOrderTypes?: string[];
    orderTypesList?: OrderType[];
}

export const OrderTypeFilter = ({
    onApplyOrderTypes,
    selectedOrderTypes = [],
    orderTypesList = [],
    ...props
}: IProps) => {
    const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>([]);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);

    const onOpenFilter = () => {
        setIsApplyDisabled(true);
        setSelectedCheckbox(selectedOrderTypes);
    };

    const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
        setSelectedCheckbox(checkedValues as string[]);

        const areValuesEqual = areUniqueArraysEqual(
            selectedOrderTypes,
            checkedValues
        );
        setIsApplyDisabled(areValuesEqual);
    };

    const handleApplyOrderTypes = (value: string[]) => onApplyOrderTypes(value);

    const onClickApply = () => {
        setIsApplyDisabled(true);
        handleApplyOrderTypes(selectedCheckbox);
    };

    return (
        <Filter
            {...props}
            icon={<SvgOutboundOrder color="#273c83" />}
            filterName="Order Type"
            dataTestId="OrderTypeFilter"
            isSelected={Boolean(selectedOrderTypes.length)}
            isApplyDisabled={isApplyDisabled}
            mainFilterContent={
                <CheckboxGroup
                    value={selectedCheckbox}
                    onChange={onChangeCheckbox}
                    className={styles.Checkboxes}
                >
                    {orderTypesList.map(({ description, orderTypeId }) => (
                        <Checkbox
                            key={orderTypeId}
                            label={description}
                            value={description}
                            checked={selectedCheckbox.includes(description)}
                        />
                    ))}
                </CheckboxGroup>
            }
            onApply={onClickApply}
            onOpenFilter={onOpenFilter}
        />
    );
};
