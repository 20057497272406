import * as React from 'react';
import { SVGProps } from 'react';

export const SvgExpandUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={10}
        height={6}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 5L5 1L1 5"
            stroke={props.color || '#8E94A9'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
