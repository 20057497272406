import { Breakpoint } from 'antd/es/_util/responsiveObserve';

export const antdTableBreakpointsSmall: Breakpoint[] = ['xs', 'sm'];
export const antdTableBreakpointsLarge: Breakpoint[] = [
    'md',
    'lg',
    'xl',
    'xxl'
];
export const antdTableBreakpointsAll: Breakpoint[] = [
    ...antdTableBreakpointsSmall,
    ...antdTableBreakpointsLarge
];

export const maxMobileBreakpoint = 767;
export const maxTabletBreakpoint = 1024;
export const maxDesktopSmallBreakpoint = 1200;
export const maxLargestDesktopBreakpoint = 1920;

export enum DeviceType {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop'
}

export const isMobile = (width: number) => width <= maxMobileBreakpoint;
export const isTablet = (width: number) =>
    width >= maxMobileBreakpoint && width <= maxTabletBreakpoint;
export const isMobileAndTablet = (width: number) =>
    width <= maxTabletBreakpoint;
export const isDesktopSmall = (width: number) =>
    width <= maxDesktopSmallBreakpoint;
export const isDesktop = (width: number) => width > maxTabletBreakpoint;
export const isLargestDesktop = (width: number) =>
    width >= maxLargestDesktopBreakpoint;

export const getDeviceType = (width: number): DeviceType => {
    return isMobile(width)
        ? DeviceType.Mobile
        : isTablet(width)
        ? DeviceType.Tablet
        : DeviceType.Desktop;
};
