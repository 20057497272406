import * as React from 'react';
import { SVGProps } from 'react';
export const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 4.998v2.667m0 2.667h.007M6.86 1.572l-5.647 9.426a1.333 1.333 0 0 0 1.14 2h11.294a1.333 1.333 0 0 0 1.14-2L9.14 1.572a1.333 1.333 0 0 0-2.28 0Z"
            stroke="#F19101"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
