import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';

import { authEmailRule } from 'utils/helpers/validationHelpers';
import { useDebouncedValidation } from 'utils/hooks/useDebouncedValidation';
import { ForgotFormForm } from 'utils/types/users/authForm.types';

interface UseValidationReturn {
    codeRules: Rule[];
    emailRules: Rule[];
}

export const useValidation = (
    form: FormInstance<ForgotFormForm>,
    isLoading: boolean
): UseValidationReturn => {
    useDebouncedValidation({
        formInstance: form,
        fieldName: 'recoveryEmail',
        skipValidation: isLoading
    });

    useDebouncedValidation({
        formInstance: form,
        fieldName: 'code',
        skipValidation: isLoading
    });

    const codeRules: Rule[] = [
        {
            required: true,
            message: 'Enter Code'
        },
        {
            max: 100,
            message: 'Max 100 symbols'
        }
    ];

    const emailRules: Rule[] = [{ ...authEmailRule, required: true }];

    return {
        codeRules,
        emailRules
    };
};
