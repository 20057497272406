import React from 'react';

import { Radio as AntRadio, RadioProps, RadioGroupProps } from 'antd';
import cn from 'classnames';

import styles from './Radio.module.less';

interface IRadio extends RadioProps {
    label?: RadioProps['children'];
}

export const Radio = ({ label, disabled, ...props }: IRadio) => {
    return (
        <AntRadio className={styles.Container} disabled={disabled} {...props}>
            <div
                className={cn(styles.Label, {
                    [styles.Label__Disabled]: disabled
                })}
            >
                {label}
            </div>
        </AntRadio>
    );
};

type IRadioGroup = RadioGroupProps;

export const RadioGroup = ({ children, ...props }: IRadioGroup) => {
    return (
        <AntRadio.Group {...props} className={styles.Group}>
            {children}
        </AntRadio.Group>
    );
};

export const RadioButton = ({ children, className, ...props }: RadioProps) => {
    return (
        <AntRadio.Button
            data-testid="radio-button"
            className={cn(styles.RadioButton, className)}
            {...props}
        >
            {children}
        </AntRadio.Button>
    );
};
