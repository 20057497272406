import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgRefresh = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <g clipPath="url(#Refresh_svg__a)">
            <path
                stroke={color || '#273C83'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M.667 2.667v4m0 0h4m-4 0L3.76 3.76A6 6 0 0 1 13.66 6m1.673 7.333v-4m0 0h-4m4 0L12.24 12.24A6 6 0 0 1 2.34 10"
            />
        </g>
        <defs>
            <clipPath id="Refresh_svg__a">
                <path
                    stroke={color || '#273C83'}
                    fill="#fff"
                    d="M0 0h16v16H0z"
                />
            </clipPath>
        </defs>
    </svg>
);
