export const checkAvailability = (
    value: number,
    min?: number,
    max?: number
): boolean => {
    const isAfterMinAvailable = !min || value >= min;
    const isBeforeMaxAvailable = !max || value <= max;

    return isAfterMinAvailable && isBeforeMaxAvailable;
};
