import React from 'react';

import { Pagination as AntPagination, PaginationProps } from 'antd';

import { SvgArrow } from 'components/icons';

import styles from './Pagination.module.less';

export const Pagination = ({
    defaultCurrent,
    total,
    onChange,
    current,
    pageSize,
    ...props
}: PaginationProps) => {
    const itemRender: PaginationProps['itemRender'] = (
        _,
        type,
        originalElement
    ) => {
        if (type === 'prev') {
            return (
                <div
                    className={styles.Prev}
                    data-testid="previous"
                    id="paginationPreviousArrow"
                >
                    <SvgArrow color="var(--iconStroke)" />
                </div>
            );
        }
        if (type === 'next') {
            return (
                <div
                    className={styles.Next}
                    data-testid="next"
                    id="paginationNextArrow"
                >
                    <SvgArrow color="var(--iconStroke)" />
                </div>
            );
        }
        return originalElement;
    };

    const initialPaginationConfig = {
        ...props,
        className: styles.Container,
        current: current,
        defaultCurrent: defaultCurrent ?? 1,
        total: total,
        itemRender: itemRender,

        showSizeChanger: false,
        showQuickJumper: false,
        onChange: onChange
    };

    const paginationProps = pageSize
        ? {
              ...initialPaginationConfig,
              pageSize: pageSize
          }
        : initialPaginationConfig;

    return (
        <>
            <AntPagination {...paginationProps} />
        </>
    );
};
