import * as React from 'react';
import { SVGProps } from 'react';
export const SvgPerformance = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.666 7H12l-2 6L6 1 4 7H1.333"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
