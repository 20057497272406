import React from 'react';

import { Switch as AntSwitch, SwitchProps } from 'antd';
import cn from 'classnames';

import styles from './Switch.module.less';

export const Switch = ({
    containerClassName,
    ...props
}: SwitchProps & { containerClassName?: string }) => {
    return (
        <div className={cn(styles.Switch, containerClassName)}>
            <AntSwitch {...props} />
        </div>
    );
};
