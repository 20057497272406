export function allowOnlyNumbers(): (value: string) => string {
    return function normalizeValueNumbersChars(value: string) {
        return value.replace(/\D/g, '');
    };
}

export function phoneReplaceSymbols(): (value: string) => string {
    return function normalizeValueNumbersChars(value: string) {
        return value.replace(
            /[a-zA-Zа-яА-ЯЇїҐґЄєІі!"#$%&'*+,./:;<=>?@[\]^_`{|}]/g,
            ''
        );
    };
}

export function allowNumbersWithSlashes(): (value: string) => string {
    return function normalizeValueNumbersChars(value: string) {
        return value.replace(/[^0-9 /]/g, '');
    };
}

export function limitMaxLength(length: number): (value: string) => string {
    return function normalizeValueLength(value: string) {
        return value.slice(0, length);
    };
}

export function notAllowToStartFromZero(): (value: string) => string {
    return function normalizeValue(value: string) {
        return value.toString().slice(0, 1) === '0'
            ? value.replace('0', '')
            : value;
    };
}
