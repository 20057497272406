import React from 'react';

import { AuthTemplate } from 'components/templates/auth/Auth.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const AuthPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.AUTH_PAGE);

    return <AuthTemplate />;
};
