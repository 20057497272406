export interface IB2C {
    authority: string;
    authorityDomain: string;
    clientId: string;
    scope: string;
    authPath: string;
}

export interface IFeatureFlags {
    version: string;
    env: string;
    flags: Record<string, boolean>;
}

export interface IMainConfig {
    b2c: IB2C;
    api_host: string;
    api_sockets: string;
    google_maps_key: string;
    dundasBIUrl: string;
}

export interface IAppConfig {
    appConfig: IMainConfig;
    featureFlags?: IFeatureFlags;
}

export enum APP_ERRORS {
    BROKEN_FILE = 'Config file was broken',
    NO_FILE = 'No config file'
}
