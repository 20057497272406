import React, { useEffect } from 'react';

import { Outlet } from 'react-router';

import styles from 'App.module.less';
import { addDundas } from 'dundas/addDundas';
import { useAppDispatch } from 'store';
import { setError, setLoading } from 'store/slices/dundas/Dundas';

const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        addDundas({
            onLoad: () => {
                dispatch(setLoading(false));
                dispatch(setError(false));
            },
            onError: () => {
                dispatch(setError(true));
            }
        });
    }, []);

    return (
        <div className={styles.App}>
            <Outlet />
        </div>
    );
};

export default App;
