import React from 'react';

import { HelpCenterTemplate } from 'components/templates/helpCenter/HelpCenter.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const HelpCenterPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.HELP_CENTER);

    return <HelpCenterTemplate />;
};
