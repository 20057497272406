import React, { useMemo } from 'react';

import classNames from 'classnames';

import { ToolTip } from 'components/UI/atoms/tooltip/ToolTip';

import styles from './ReferenceNumbers.module.less';

interface IProps {
    referenceNumbers: string[];
    isMobile?: boolean;
    search?: string;
    isMobileViewSetting?: boolean;
}

export const ReferenceNumbers = ({
    referenceNumbers,
    search,
    isMobile = false,
    isMobileViewSetting = false
}: IProps) => {
    const references = referenceNumbers.join(', ');
    const hasEllipsis = references.length > 15;
    const ellipsisReferences = `${references.slice(0, 15)}...`;

    const isMatched = useMemo(() => {
        const searchValue = search?.toLowerCase();

        return Boolean(
            searchValue &&
                referenceNumbers.find(
                    (item) => item.toLowerCase() === searchValue
                )
        );
    }, [search, referenceNumbers]);

    const renderRefTitle = () => (
        <ul
            className={styles.Refs}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {referenceNumbers.map((ref) => (
                <li key={ref}>{ref}</li>
            ))}
        </ul>
    );

    return (
        <ToolTip
            overlayClassName={classNames(styles.Tooltip, {
                [styles.Tooltip__MobileViewSetting]: isMobileViewSetting
            })}
            title={renderRefTitle()}
            placement={isMobileViewSetting ? 'bottomLeft' : 'bottom'}
        >
            <div
                className={classNames(styles.Refs__Trigger, {
                    [styles.Text__Matched]: isMatched,
                    [styles.Text]: true
                })}
            >
                {hasEllipsis ? ellipsisReferences : references}
            </div>
        </ToolTip>
    );
};
