import { useEffect } from 'react';

import { Form, FormInstance } from 'antd';
import { DebouncedState, useDebouncedCallback } from 'use-debounce';

import { IProfileCreateForm } from 'components/UI/organisms/adminPanel/profiles/create/useProfilesFormCreate';
import { ItemReferenceFieldName } from 'utils/types/general/referenceAndSerial.types';
import { InboundReplenishmentFormValues } from 'utils/types/materials/replenishment.types';
import { TransferByWarehouseFormValues } from 'utils/types/materials/transferByWarehouse.types';
import { OutboundOrderFormValues } from 'utils/types/order/outboundOrder.types';
import {
    CreatePasswordForm,
    ForgotFormForm,
    SignInForm
} from 'utils/types/users/authForm.types';
import { User } from 'utils/types/users/users.types';

const WAIT = 2000;

type FieldsNameType =
    | keyof OutboundOrderFormValues
    | keyof InboundReplenishmentFormValues
    | keyof User
    | keyof IProfileCreateForm
    | keyof CreatePasswordForm
    | keyof ForgotFormForm
    | ItemReferenceFieldName;

type FormInstanceType =
    | FormInstance<User>
    | FormInstance<OutboundOrderFormValues>
    | FormInstance<InboundReplenishmentFormValues>
    | FormInstance<IProfileCreateForm>
    | FormInstance<TransferByWarehouseFormValues>
    | FormInstance<CreatePasswordForm>
    | FormInstance<SignInForm>
    | FormInstance<ForgotFormForm>;

interface IArgs {
    formInstance: FormInstanceType;
    fieldName: FieldsNameType;
    skipValidation?: boolean;
    delay?: number;
}

export const useDebouncedValidation = ({
    formInstance,
    fieldName,
    skipValidation = false,
    delay = WAIT
}: IArgs): DebouncedState<(fieldName: FieldsNameType) => void> => {
    const fieldValueWatch = Form.useWatch(fieldName, formInstance);

    const debounce = useDebouncedCallback((fieldName: FieldsNameType) => {
        manuallyValidateField(fieldName);
    }, delay);

    useEffect(() => {
        if (skipValidation) {
            debounce.cancel();
        }
    }, [skipValidation]);

    useEffect(() => {
        if (!formInstance.isFieldTouched(fieldName)) return;
        debounce(fieldName);
    }, [fieldValueWatch]);

    const manuallyValidateField = (fieldName: FieldsNameType) => {
        formInstance.validateFields([fieldName]).then().catch(Error);
    };

    return debounce;
};
