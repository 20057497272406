import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSettings = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
            stroke={color || '#273C83'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.933 10a1.1 1.1 0 0 0 .22 1.214l.04.04a1.332 1.332 0 0 1-.433 2.176 1.333 1.333 0 0 1-1.454-.29l-.04-.04a1.1 1.1 0 0 0-1.213-.22 1.1 1.1 0 0 0-.667 1.007V14a1.333 1.333 0 1 1-2.667 0v-.06A1.1 1.1 0 0 0 6 12.934a1.099 1.099 0 0 0-1.213.22l-.04.04a1.335 1.335 0 1 1-1.887-1.887l.04-.04a1.1 1.1 0 0 0 .22-1.213 1.1 1.1 0 0 0-1.006-.667h-.114A1.333 1.333 0 1 1 2 6.72h.06A1.1 1.1 0 0 0 3.066 6a1.1 1.1 0 0 0-.22-1.213l-.04-.04A1.333 1.333 0 1 1 4.693 2.86l.04.04a1.1 1.1 0 0 0 1.213.22h.053a1.1 1.1 0 0 0 .667-1.006V2a1.333 1.333 0 1 1 2.667 0v.06a1.1 1.1 0 0 0 .666 1.007 1.1 1.1 0 0 0 1.214-.22l.04-.04a1.333 1.333 0 1 1 1.886 1.887l-.04.04a1.101 1.101 0 0 0-.22 1.213V6a1.101 1.101 0 0 0 1.007.667h.113a1.333 1.333 0 1 1 0 2.667h-.06a1.1 1.1 0 0 0-1.006.666Z"
            stroke={color || '#273C83'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
