import { createSlice } from '@reduxjs/toolkit';

import { IAction } from 'store/index';
import { ProfilesState } from 'utils/types/users/profiles.types';

interface IInitialState {
    profiles: ProfilesState;
    selectedAccountAndSubAccount: ISelectedAccountAndSubAccount;
    isChangedAccount: boolean;
    redirectBlocker: boolean;
}

interface ISelectedAccountAndSubAccount {
    accountNumber: string;
    subAccountNumber: string;
    description: string;
    isExpandedRef?: boolean;
    isMandatoryRef2?: boolean;
    itemRef?: boolean;
    irpItemRef?: boolean;
    selectedAccountIds: string;
}

const initialState: IInitialState = {
    profiles: {
        chosenPermissions: [],
        haveNewAccount: false,
        haveNewPermission: false,
        permissionButtonDisabled: true
    },
    selectedAccountAndSubAccount: {
        accountNumber: '',
        subAccountNumber: '',
        description: '',
        isExpandedRef: false,
        isMandatoryRef2: false,
        itemRef: false,
        irpItemRef: false,
        selectedAccountIds: ''
    },
    isChangedAccount: false,
    redirectBlocker: false
};

export const adminPanelSlice = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {
        setChosenPermissions(state, action: IAction<string[]>) {
            state.profiles.chosenPermissions = action.payload;
        },
        setRedirectBlocker(state, action: IAction<boolean>) {
            state.redirectBlocker = action.payload;
        },
        resetChosenPermissions(state) {
            state.profiles.chosenPermissions = [];
        },
        setSelectedAccountAndSubAccount(
            state,
            action: IAction<ISelectedAccountAndSubAccount>
        ) {
            state.selectedAccountAndSubAccount = {
                ...state.selectedAccountAndSubAccount,
                ...action.payload
            };
        },
        resetSelectedAccountAndSubAccount(state) {
            state.selectedAccountAndSubAccount =
                initialState.selectedAccountAndSubAccount;
        },
        setIsChangedAccount(state, action: IAction<boolean>) {
            state.isChangedAccount = action.payload;
        },
        setHaveNewPermission(state, action: IAction<boolean>) {
            state.profiles.haveNewPermission = action.payload;
        },
        setHaveNewAccount(state, action: IAction<boolean>) {
            state.profiles.haveNewAccount = action.payload;
        },
        setPermissionButtonDisabled(state, action: IAction<boolean>) {
            state.profiles.permissionButtonDisabled = action.payload;
        }
    }
});

export const {
    setChosenPermissions,
    resetChosenPermissions,
    setSelectedAccountAndSubAccount,
    resetSelectedAccountAndSubAccount,
    setIsChangedAccount,
    setRedirectBlocker,
    setHaveNewPermission,
    setHaveNewAccount,
    setPermissionButtonDisabled
} = adminPanelSlice.actions;
