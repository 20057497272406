export enum LOCAL_STORAGE_FIELDS {
    DARK_MODE = 'darkMode',
    ACCESS_TOKEN = 'access_token',
    REFRESH_TOKEN = 'refresh_token'
}

export const setLocalStorageItem = (key: string, value: string) =>
    localStorage.setItem(key, value);

export const getLocalStorageItem = (key: string) => localStorage.getItem(key);

export const removeLocalStorageItem = (key: string) =>
    localStorage.removeItem(key);
