export const REQUESTS_PATH = {
    GET_SERVICES: '/ReturnParts/GoodFromChoice/AvailableServices',
    ORDER_REFERENCE_LABELS:
        '/ReturnParts/GoodFromChoice/GetOrderReferenceNumberLabels',
    SERIAL_NUMBERS: '/ReturnParts/GoodFromChoice/GetInventoryPartSerialNumbers',
    PRE_CHECK: '/ReturnParts/GoodFromChoice/PreCheck',
    SUBMIT_FULL_ORDER: '/ReturnParts/GoodFromChoice/Submit',
    SUBMIT_REDUCED_ORDER: '/ReturnParts/GoodFromChoice/SubmitReduced',
    ACCOUNT_WAREHOUSE: '/ReturnParts/GoodFromChoice/AccountSSLs'
} as const;

export const REQUESTS_PATH_DEFECTIVE = {
    GET_SERVICES: '/ReturnParts/DefectiveFromChoice/AvailableServices',
    ORDER_REFERENCE_LABELS:
        '/ReturnParts/DefectiveFromChoice/GetOrderReferenceNumberLabels',
    SERIAL_NUMBERS:
        '/ReturnParts/DefectiveFromChoice/GetInventoryPartSerialNumbers',
    PRE_CHECK: '/ReturnParts/DefectiveFromChoice/PreCheck',
    SUBMIT_FULL_ORDER: '/ReturnParts/DefectiveFromChoice/Submit',
    SUBMIT_REDUCED_ORDER: '/ReturnParts/DefectiveFromChoice/SubmitReduced',
    ACCOUNT_WAREHOUSE: '/ReturnParts/DefectiveFromChoice/AccountSSLs',
    INVENTORY: '/ReturnParts/DefectiveFromChoice/SslInventory'
} as const;
