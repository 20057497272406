import * as React from 'react';
import { SVGProps } from 'react';
export const SvgTicketSmall = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <g clipPath="url(#clip0_26674_18402)">
            <path
                d="M2.6665 5.99992V7.99992L2.6665 13.9999C2.6665 14.7363 3.26346 15.3333 3.99984 15.3333H5.99984C5.99984 15.3333 6.49967 13.3333 7.99984 13.3333C9.5 13.3333 9.99984 15.3333 9.99984 15.3333H11.9998C12.7362 15.3333 13.3332 14.7363 13.3332 13.9999V7.99992V5.99992M2.6665 5.99992L2.6665 1.99992C2.6665 1.26354 3.26346 0.666582 3.99984 0.666582H5.99984C5.99984 0.666582 6.49967 2.66658 7.99984 2.66658C9.5 2.66658 9.99984 0.666582 9.99984 0.666582H11.9998C12.7362 0.666582 13.3332 1.26354 13.3332 1.99992V5.99992M2.6665 5.99992H3.99984M13.3332 5.99992H11.9998M7 5.99992H9"
                stroke="#273C83"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_26674_18402">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="matrix(0 -1 1 0 0 16)"
                />
            </clipPath>
        </defs>
    </svg>
);
