import { GetShippingServices } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account } from 'APIServices/users/UsersApi.types';
import { getSubAccountDescription } from 'components/UI/organisms/trackOrders/orderList/components/accountFilter/useAccountFilter';
import { SelectedFilterItems } from 'utils/hooks/useOrdersListFilters';

export interface SelectedAccountsAndSubsData {
    selectedAccountsAndSubs: SelectedFilterItems;
    onRemoveAccountsAndSubs: (id: string) => void;
}

export const useSelectedAccountsAndSubs = ({
    data,
    rawData,
    onApply
}: {
    data?: string[];
    rawData?: Account[];
    onApply: (data: string[]) => void;
}): SelectedAccountsAndSubsData => {
    const accountMap = rawData
        ? new Map(rawData.map((account) => [account.accountId, account]))
        : new Map();

    const selectedAccountsAndSubs = (
        data?.map((item) => {
            const [accId, subId] = item.split('-').map(Number);
            const account = accountMap.get(accId);

            if (!account) {
                return null;
            }

            const title = subId
                ? getSubAccountDescription(subId, account.subAccounts)
                : account.accountNumber.toString();

            return {
                title: title,
                value: item
            };
        }) || []
    ).filter(Boolean) as SelectedFilterItems;

    const onRemoveAccountsAndSubs = (idToRemove: string) => {
        const isSubAccount = idToRemove.includes('-');
        const withoutRemoved = (data || []).filter((item) => {
            if (isSubAccount) {
                return item !== idToRemove;
            } else {
                const [accountId] = item.split('-');
                return accountId !== idToRemove;
            }
        });
        onApply(withoutRemoved);
    };

    return {
        selectedAccountsAndSubs,
        onRemoveAccountsAndSubs
    };
};

export const useSelectedTransportType = (
    selectedTransportTypes?: string[],
    allTransportTypes?: GetShippingServices['data']
): {
    selectedTransportTypes: {
        title: string;
        value: string;
        shippingIds: number[] | null;
    }[];
} => {
    if (!selectedTransportTypes || !allTransportTypes)
        return { selectedTransportTypes: [] };

    const transportMap = new Map(
        allTransportTypes.map((item) => [item.level1Id, item])
    );

    const selectedItems = selectedTransportTypes
        .map((item) => {
            const [parentId, secondLevelId, thirdLevelId] = item
                .split('_')
                .map(Number);

            const parentItem = transportMap.get(parentId);

            if (!parentItem) return null;

            const level2data = parentItem.level2Item.find(
                (el) => el.level2Id === secondLevelId
            );

            const level2Shipping = parentItem.level2Item
                ? parentItem.level2Item.flatMap(
                      (item) => item.shippingServiceIds || []
                  )
                : [];

            const level3Shipping = parentItem.level2Item
                ? parentItem.level2Item.flatMap(
                      (item) =>
                          item.level3Item?.flatMap(
                              (el) => el.shippingServiceIds || []
                          ) || []
                  )
                : [];

            if (!secondLevelId && parentItem) {
                return {
                    title: parentItem.level1Description,
                    value: String(parentId),
                    shippingIds: [...level2Shipping, ...level3Shipping]
                };
            }
            if (!level2data) return null;

            if (thirdLevelId) {
                const level3Data = level2data.level3Item?.find(
                    (item) => item.level3Id === thirdLevelId
                );

                if (!level3Data) return null;
                return {
                    title: level3Data.level3ItemDescription,
                    value: `${parentItem.level1Id}_${level2data.level2Id}_${thirdLevelId}`,
                    shippingIds: level3Data.shippingServiceIds
                };
            }

            return {
                title: level2data.level2Description,
                value: `${parentId}_${level2data.level2Id}`,
                shippingIds: level2data.shippingServiceIds?.length
                    ? level2data.shippingServiceIds
                    : level3Shipping || []
            };
        })
        .filter(Boolean) as {
        title: string;
        value: string;
        shippingIds: number[] | null;
    }[];

    return { selectedTransportTypes: selectedItems };
};

export const getParsedTransportData = (
    allTransportTypes?: GetShippingServices['data']
) => {
    if (!allTransportTypes) return [];

    return allTransportTypes.map((item) => {
        return {
            name: item.level1Description,
            code: String(item.level1Id),
            children: item.level2Item.map((el) => {
                return {
                    name: el.level2Description,
                    code: String(el.level2Id),
                    children:
                        el.level3Item?.map((data) => {
                            return {
                                name: data.level3ItemDescription,
                                code: String(data.level3Id),
                                description: ''
                            };
                        }) || []
                };
            })
        };
    });
};
