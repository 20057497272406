import React, { ReactNode } from 'react';

import cn from 'classnames';

import { SvgInfo } from 'components/icons';

import styles from './InfoAlert.module.less';

interface IProps {
    text: string | ReactNode;
    className?: string;
}

export const InfoAlert = ({ text, className }: IProps) => (
    <div data-testid="info-alert" className={cn(styles.InfoAlert, className)}>
        <div className={styles.InfoAlert__IconContainer}>
            <SvgInfo />
        </div>
        <div className={styles.InfoAlert__TextContainer}>{text}</div>
    </div>
);
