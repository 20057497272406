import * as React from 'react';
import { SVGProps } from 'react';
export const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13 9v2.667A1.334 1.334 0 0 1 11.667 13H2.333A1.334 1.334 0 0 1 1 11.667V9m2.667-3.333L7 9m0 0 3.333-3.333M7 9V1"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
