import { currentYear, defaultYearsPeriod } from 'utils/constants/datePicker';

const minYear = currentYear - defaultYearsPeriod;

export const getDefaultYearsList = (
    startYear: number = minYear,
    endYear: number = currentYear
) => {
    const years = [];

    for (let i = startYear; i <= endYear; i += 1) {
        years.push(i);
    }

    return years;
};
