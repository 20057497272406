import * as React from 'react';
import { SVGProps } from 'react';
export const SvgManageInventory = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.5 8H1.778C1.348 8 1 8.298 1 8.667v4.666c0 .368.348.667.778.667h5.444c.43 0 .778-.299.778-.667V8.667C8 8.298 7.652 8 7.222 8H4.5Zm0 0v1.286M11.5 8H8.778C8.348 8 8 8.298 8 8.667v4.666c0 .368.348.667.778.667h5.444c.43 0 .778-.299.778-.667V8.667c0-.369-.348-.667-.778-.667H11.5Zm0 0v1.286M11.509 2H8.89c-.492 0-.89.298-.89.667v4.666c0 .369.398.667.89.667h5.22c.492 0 .89-.298.89-.667V2.667c0-.369-.399-.667-.89-.667h-2.601Zm0 0v1.286"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
