import { OrderType } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Sorting } from 'utils/types/general/general.types';

export function sortByKeys(keyA: string, keyB: string) {
    if (keyA > keyB) {
        return 1;
    }
    if (keyA < keyB) {
        return -1;
    }
    return 0;
}

export const getFieldSorting = (field: string, sorting?: Sorting) => {
    return sorting?.field === field ? sorting?.direction : undefined;
};

export const sortOrderTypesList = (orderTypesList: OrderType[]): OrderType[] =>
    [...orderTypesList].sort((a, b) =>
        a.description === 'Outbound'
            ? -1
            : a.description.localeCompare(b.description)
    );
