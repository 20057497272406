import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from 'components/UI/atoms/emptyState/EmptyState.module.less';

interface IProps {
    title: string;
    subtitle?: ReactNode;
    titleClass?: string;
    children?: ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const EmptyState = ({
    title,
    subtitle,
    titleClass,
    children,
    className,
    style = {}
}: IProps) => {
    return (
        <div
            className={classNames(styles.Empty, className)}
            data-testid="EmptyStateContainer"
            style={{ ...style }}
        >
            <span
                className={classNames(styles.Empty__Title, titleClass)}
                data-testid="EmptyStateTitle"
            >
                {title}
            </span>
            {subtitle && (
                <span
                    className={styles.Empty__Subtitle}
                    data-testid="EmptyStateSubTitle"
                >
                    {subtitle}
                </span>
            )}
            {Boolean(children) && (
                <div className={styles.ActionBlock}>{children}</div>
            )}
        </div>
    );
};
