import React from 'react';

import { UserSettingsTemplate } from 'components/templates/userSettings/UserSettings.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const UserSettingsPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.USER_SETTINGS);

    return <UserSettingsTemplate />;
};
