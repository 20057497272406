import React from 'react';

import classNames from 'classnames';

import { SvgSuitcase } from 'components/icons';

import styles from './AccountSelectTriggerBtn.module.less';

export const AccountSelectTriggerBtn = ({
    account,
    subAccount,
    className
}: {
    account?: string;
    subAccount?: string;
    className?: string;
}) => (
    <div className={classNames(styles.SelectAccountTrigger, className)}>
        <div
            id="badge"
            className={styles.SelectAccountTrigger__SuitcaseDesktop}
        >
            <SvgSuitcase color={'#8E94A9'} width={22} />
        </div>
        {account ? (
            <>
                <div className={styles.AccountTitle} id="Account">
                    {account}:
                </div>
                <span
                    id="SelectedSubAccount"
                    className={styles.SubAccountTitle}
                >
                    {subAccount}
                </span>
            </>
        ) : (
            <span
                className={classNames(
                    styles.AccountTitle,
                    styles.AccountTitle__Grey
                )}
                id="Account"
            >
                Select Account
            </span>
        )}
        <div id="badge" className={styles.SelectAccountTrigger__SuitcaseMobile}>
            <SvgSuitcase />
        </div>
    </div>
);
