export enum REQUESTS_PATH {
    DOWNLOAD_TEMPLATE = '/Replenishments/GoodPartsUpload/DownloadTemplateFile',
    RESET_FILE = '/Replenishments/GoodPartsUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING = '/Replenishments/GoodPartsUpload/GetColumnsMapping',
    VALIDATE_FILE = '/Replenishments/GoodPartsUpload/ValidateFile',
    GET_GROUPING = '/Replenishments/GoodPartsUpload/ReviewOrders',
    SUBMIT_ORDERS = '/Replenishments/GoodPartsUpload/SubmitOrders'
}

export enum REQUESTS_PATH_DEFECTIVE {
    DOWNLOAD_TEMPLATE = '/ReturnParts/DefectiveToChoiceUpload/DownloadTemplateFile',
    RESET_FILE = '/ReturnParts/DefectiveToChoiceUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING = '/ReturnParts/DefectiveToChoiceUpload/GetColumnsMapping',
    VALIDATE_FILE = '/ReturnParts/DefectiveToChoiceUpload/ValidateFile',
    GET_GROUPING = '/ReturnParts/DefectiveToChoiceUpload/ReviewOrders',
    SUBMIT_ORDERS = '/ReturnParts/DefectiveToChoiceUpload/SubmitOrders'
}
