import React from 'react';

import { TransformedQuickAccessResponse } from 'APIServices/users/UsersApi.types';
import { SvgSettings } from 'components/icons';
import { Button } from 'components/UI/atoms/button/Button';
import { Checkbox, CheckboxGroup } from 'components/UI/atoms/checkbox/Checkbox';
import { Popover } from 'components/UI/atoms/popover/Popover';
import { useCustomize } from 'components/UI/organisms/home/customize/useCustomize';

import styles from './Customize.module.less';

interface Props {
    items?: TransformedQuickAccessResponse[];
    isDarkModeEnabled?: boolean;
}

export const Customize = ({ items, isDarkModeEnabled }: Props) => {
    const {
        isOpen,
        isLoading,
        selectedValues,
        noNewItemsInSelection,
        onSave,
        onClose,
        toggleVisible,
        onChangeCheckbox,
        handleVisibleChange
    } = useCustomize({ items });

    const spawnOptions = () => {
        return items?.map((item, i) => (
            <div
                key={i}
                className={styles.PopoverContainer__Permission}
                data-testid="Permission"
            >
                <div className={styles.Title} data-testid="PermissionTitle">
                    {item.title}
                </div>
                <div className={styles.PopoverContainer__PermissionContent}>
                    {item.permissions.map((permission) => {
                        const isChecked = selectedValues.includes(
                            permission.permissionId
                        );

                        return (
                            <Checkbox
                                key={permission.permissionId}
                                label={permission.displayName}
                                value={permission.permissionId}
                                checked={isChecked}
                                disabled={
                                    selectedValues.length === 5 && !isChecked
                                }
                            />
                        );
                    })}
                </div>
            </div>
        ));
    };

    const popoverContent = (
        <div
            className={styles.PopoverContainer}
            data-testid="QuickAccessPopover"
        >
            <div className={styles.Head}>
                <div className={styles.Head__HeadTitle}>
                    Quick Access ({selectedValues.length} of 5)
                </div>
                <div className={styles.Head__SubTitle}>
                    Up to 5 items can be selected
                </div>
            </div>
            <div className={styles.PopoverContainer__Content}>
                <CheckboxGroup
                    value={selectedValues}
                    onChange={onChangeCheckbox}
                    disabled={isLoading}
                >
                    <div className={styles.PopoverContainer__Permissions}>
                        {spawnOptions()}
                    </div>
                </CheckboxGroup>
            </div>
            <div className={styles.PopoverContainer__Actions}>
                <Button
                    text="Save"
                    theme="primary"
                    onClick={onSave}
                    disabled={noNewItemsInSelection}
                    isLoading={isLoading}
                    data-testid="QuickAccessSave"
                />
                <Button
                    text="Cancel"
                    theme="tertiary"
                    onClick={onClose}
                    data-testid="QuickAccessCancel"
                />
            </div>
        </div>
    );

    return (
        <Popover
            popoverContent={popoverContent}
            triggerContent={
                <div
                    className={styles.TriggerContent}
                    onClick={toggleVisible}
                    data-testid="CustomizeButton"
                >
                    <span>Customize</span>
                    <SvgSettings
                        color={isDarkModeEnabled ? '#B2C0F2' : '#273C83'}
                    />
                </div>
            }
            onVisibleChange={handleVisibleChange}
            visible={isOpen}
            showArrow={false}
            showIcon={false}
            trigger="click"
            placement="bottomLeft"
            destroyTooltipOnHide={true}
        />
    );
};
