import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import {
    getNotification,
    NOTIFICATION_TYPES
} from 'utils/notification/Notification';

const endpointWithoutNotifications = [
    'getDownloadDocument',
    'deleteDocument',
    'getTicketDocuments',
    'updateTicketDocument',
    'addTicketDocument'
];

export const ErrorLogger: Middleware = () => (next) => (action) => {
    const skipEndpoint = endpointWithoutNotifications.some(
        (item) => item === action.meta?.arg?.endpointName
    );

    const skipAction = () => {
        return (
            skipEndpoint ||
            action.meta.baseQueryMeta?.response?.status === 401 ||
            action.meta.action?.error?.message === 'invalid_grant' ||
            action.payload?.error === 'invalid_grant'
        );
    };

    if (isRejectedWithValue(action) && !skipAction()) {
        getNotification({
            title: action.payload.data?.title ?? 'Something went wrong',
            type: NOTIFICATION_TYPES.ERROR
        });
    }

    return next(action);
};
