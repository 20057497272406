import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgOrderWatchlist = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke={props.stroke || '#8E94A9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m15 19-7-5-7 5V3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
        />
    </svg>
);
