import * as React from 'react';
import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
    color?: string;
}

export const SvgArrow = (props: IProps) => (
    <svg
        width={12}
        height={8}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.293.293 6 4.586 1.707.293.293 1.707 6 7.414l5.707-5.707L10.293.293Z"
            fill={props.color || '#8E94A'}
        />
    </svg>
);
