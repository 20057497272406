import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCreateInbound = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.14 10.593a6.666 6.666 0 1 1-8.807-8.706M9 2.667v4m0 0h4m-4 0L13.666 2"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
