import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSuitcase = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width || 34}
        height={props.width || 34}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.0293 19V3C15.0293 2.46957 14.8186 1.96086 14.4435 1.58579C14.0684 1.21071 13.5597 1 13.0293 1H9.0293C8.49886 1 7.99016 1.21071 7.61508 1.58579C7.24001 1.96086 7.0293 2.46957 7.0293 3V19M3.0293 5H19.0293C20.1339 5 21.0293 5.89543 21.0293 7V17C21.0293 18.1046 20.1339 19 19.0293 19H3.0293C1.92473 19 1.0293 18.1046 1.0293 17V7C1.0293 5.89543 1.92473 5 3.0293 5Z"
            stroke={props.color || 'white'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
