import React from 'react';

import { NotAllowedTemplate } from 'components/templates/notAllowed/NotAllowed.template';
import { NotAllowedOnMobileTemplate } from 'components/templates/notAllowedOnMobile/NotAllowedOnMobile.template';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';

interface Props {
    isOnMobile?: boolean;
}

export const NotAllowedPage = ({ isOnMobile = false }: Props) => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.NOT_ALLOWED);

    return isOnMobile ? <NotAllowedOnMobileTemplate /> : <NotAllowedTemplate />;
};
