import * as React from 'react';
import { SVGProps } from 'react';
export const SvgInfo = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 10.167V7.5m0-2.667h.007m6.66 2.667a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0Z"
            stroke={color || '#585E74'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
