import * as React from 'react';
import { SVGProps } from 'react';
export const SvgReUpload = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={15}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M.668 1.666v4m0 0h4m-4 0L3.761 2.76A6 6 0 1 1 2.341 9"
            stroke={props.color || '#273C83'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
