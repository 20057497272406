import { DefaultOptionType } from 'antd/lib/select';

import { filterString } from './filtering';

export const searchCountry = (
    inputValue: string,
    option?: DefaultOptionType,
    isDigitCode?: boolean
): boolean => {
    const defaultFilterCondition =
        filterString(inputValue, option?.data.countryCode) ||
        filterString(inputValue, option?.data.countryName);

    const withDigitCodeCondition =
        defaultFilterCondition ||
        filterString(inputValue, option?.data.internationalCallingCode);

    return isDigitCode ? withDigitCodeCondition : defaultFilterCondition;
};
