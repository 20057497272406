import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    useAddToWatchListMutation,
    useDeleteFromWatchListMutation
} from 'APIServices/trackOrders/TrackOrders.api';
import { TransformedTrackOrder } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Label } from 'components/UI/atoms/label/Label';
import { Loader } from 'components/UI/atoms/loader/Loader';
import { AddedWatchlistItems } from 'components/UI/organisms/home/watchList/watchListItems/WatchListItems';
import styles from 'components/UI/organisms/home/watchList/watchListItems/WatchListItems.module.less';
import { renderDateColumn } from 'components/UI/organisms/trackOrders/orderList/columns';
import { RoutesList } from 'utils/constants/RoutesList';
import { getStatusVariant, PROGRESS_STATUSES } from 'utils/helpers/orderStatus';
import { useUserSettings } from 'utils/hooks/useUserSettings';
import {
    getNotification,
    NOTIFICATION_TYPES
} from 'utils/notification/Notification';
import { STATUS_CODES } from 'utils/types/api/api.types';

interface Props {
    isMobileVersion?: boolean;
    watchlistItems?: TransformedTrackOrder[];
    addedToWatchListItems: AddedWatchlistItems[];
    setAddedToWatchListItems: Dispatch<SetStateAction<AddedWatchlistItems[]>>;
}

interface Return {
    toggleWatchList: (
        itemId: string,
        isAddedToWatchList: boolean
    ) => Promise<void>;
    renderDateInfo: ({
        dateActual,
        dateEstimated,
        dateTimeZone,
        isTimeZone,
        watchListMobileMode
    }: {
        dateActual: string | null;
        dateEstimated: string | null;
        dateTimeZone: string | null;
        isTimeZone?: boolean | null;
        watchListMobileMode?: boolean | null;
    }) => '' | React.ReactNode;
    goToDetailsPage: (record: TransformedTrackOrder) => void;
    checkMatchedFlagItems: ({
        dataItemId,
        isInList
    }: {
        dataItemId: string;
        isInList?: boolean;
    }) => boolean;
    renderWatchListItemStatus: (
        orderStatus: PROGRESS_STATUSES
    ) => JSX.Element | null;
    spawnLoaderSection: () => JSX.Element;
}

export const useWatchListItems = ({
    isMobileVersion = false,
    watchlistItems,
    addedToWatchListItems,
    setAddedToWatchListItems
}: Props): Return => {
    const [addToWatchlist] = useAddToWatchListMutation();
    const [removeFromWatchlist] = useDeleteFromWatchListMutation();
    const { timeFormatKey, dateFormatKey } = useUserSettings();
    const navigate = useNavigate();

    useEffect(() => {
        const watchListFlagsData = watchlistItems?.map((item) => {
            return {
                watchlistItemId: item.id,
                isInOrderWatchList: item.isInOrderWatchList
            };
        });

        if (watchListFlagsData) {
            setAddedToWatchListItems(watchListFlagsData);
        }
    }, [watchlistItems]);

    const addToWatchList = async (itemId: string) => {
        const response = itemId ? await addToWatchlist(itemId).unwrap() : null;

        if (!response) return;

        if (response.statusCode === STATUS_CODES.SUCCESS && !isMobileVersion) {
            getNotification({
                title: 'Order has been successfully added to the watchlist',
                type: NOTIFICATION_TYPES.SUCCESS
            });
        }
    };

    const removeFromWatchList = async (itemId: string) => {
        const response = itemId
            ? await removeFromWatchlist(itemId).unwrap()
            : null;

        if (!response) return;

        if (response.statusCode === STATUS_CODES.SUCCESS && !isMobileVersion) {
            getNotification({
                title: 'Order has been successfully removed from the watchlist',
                type: NOTIFICATION_TYPES.SUCCESS
            });
        }
    };

    const renderWatchListItemStatus = (orderStatus: PROGRESS_STATUSES) => {
        const variant = getStatusVariant(orderStatus);
        return variant ? (
            <Label
                variant={variant}
                name={orderStatus}
                data-testid="progress-status-label"
            />
        ) : null;
    };

    const spawnLoaderSection = () => {
        return (
            <div
                className={styles.LoaderContainer}
                data-testid="loader-container"
            >
                <Loader size="large" data-testid="loader" fullHeight />
            </div>
        );
    };

    const checkMatchedFlagItems = ({
        dataItemId,
        isInList = true
    }: {
        dataItemId: string;
        isInList?: boolean;
    }): boolean => {
        return addedToWatchListItems.some((el) => {
            const isElementChecked = isInList
                ? el.isInOrderWatchList
                : !el.isInOrderWatchList;
            return el.watchlistItemId === dataItemId && isElementChecked;
        });
    };

    const toggleWatchList = async (
        itemId: string,
        isAddedToWatchList: boolean
    ) => {
        if (
            itemId &&
            checkMatchedFlagItems({
                dataItemId: itemId,
                isInList: false
            })
        ) {
            await addToWatchList(itemId);
            setAddedToWatchListItems((prevState) =>
                prevState.map((item) => {
                    if (item.watchlistItemId === itemId) {
                        return {
                            ...item,
                            isInOrderWatchList: true
                        };
                    }
                    return item;
                })
            );

            return;
        }

        if (itemId && isAddedToWatchList) {
            await removeFromWatchList(itemId);
            setAddedToWatchListItems((prevState) =>
                prevState.map((item) => {
                    if (item.watchlistItemId === itemId) {
                        return {
                            ...item,
                            isInOrderWatchList: false
                        };
                    }
                    return item;
                })
            );

            return;
        }
    };

    const renderDateInfo = ({
        dateActual,
        dateEstimated,
        dateTimeZone,
        isTimeZone,
        watchListMobileMode
    }: {
        dateActual: string | null;
        dateEstimated: string | null;
        dateTimeZone: string | null;
        isTimeZone?: boolean | null;
        watchListMobileMode?: boolean | null;
    }) =>
        renderDateColumn({
            dateActual: dateActual,
            dateEstimated: dateEstimated,
            isTimeZone: isTimeZone,
            timeZone: dateTimeZone,
            dateFormatKey,
            timeFormatKey,
            watchListMobileMode
        });

    const goToDetailsPage = (record: TransformedTrackOrder) => {
        const path = RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL.replace(
            ':id',
            String(record.orderId)
        );

        navigate(`/${path}`);
    };

    return {
        toggleWatchList,
        renderDateInfo,
        goToDetailsPage,
        checkMatchedFlagItems,
        renderWatchListItemStatus,
        spawnLoaderSection
    };
};
