import * as React from 'react';
import { SVGProps } from 'react';

export const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.059 5.5a2 2 0 0 1 3.886.666c0 1.334-2 2-2 2M8 10.833h.006m6.66-3.333a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Z"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
