import React, { ReactElement, Suspense } from 'react';

import { Navigate, RouteProps, useLocation, useParams } from 'react-router-dom';

import { NotAllowedPage } from 'components/pages/notAllowedPage/NotAllowed.page';
import { Loader } from 'components/UI/atoms/loader/Loader';
import { getAllSideBarItems } from 'components/UI/molecules/siderMenu/SiderMenuItems';
import { useSiderMenuItems } from 'components/UI/molecules/siderMenu/useSiderMenuItems';
import { FeatureFlagsController } from 'config/FeatureFlagsController';
import { useAppSelector } from 'store';
import { DeviceType, getDeviceType } from 'utils/constants/breakpoints';
import { RoutesList } from 'utils/constants/RoutesList';
import { getPage } from 'utils/helpers/getPage';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';

import styles from './Routes.module.less';

interface ProtectedRouteProps extends RouteProps {
    children: ReactElement;
}

const isDeviceBlocked = (
    deviceType: DeviceType,
    blockedValue?: DeviceType
): boolean => {
    if (deviceType === DeviceType.Desktop) {
        return false;
    }
    if (blockedValue === DeviceType.Tablet) {
        return true;
    }
    return blockedValue === deviceType;
};

export function ProtectedRoute({ children }: ProtectedRouteProps) {
    const location = useLocation();
    const params = useParams();
    const { width } = useWindowDimentions();
    const { sideBarItems, isLoading } = useSiderMenuItems();
    const userId = useAppSelector(({ user: { userId } }) => userId);
    const userInfo = useAppSelector(({ user: { userInfo } }) => userInfo);
    const isUserAuthenticated = Boolean(userId);

    if (!isUserAuthenticated) {
        return <Navigate to={`/${RoutesList.AUTH.ROOT}`} />;
    }

    const isChoiceAdmin = Boolean(userInfo?.isChoiceAdmin);

    const fromPage = getPage(location.pathname, userInfo, params.id);

    const pageKey = fromPage?.key;
    const pageLabel = fromPage?.label;

    const isRouteAccessibleByFeatureFlag =
        FeatureFlagsController.getInstance().getRouteBlocker(
            getAllSideBarItems(userInfo, true),
            pageKey
        );

    const currentRoute = sideBarItems.find((item) => {
        return (
            item.key === pageKey ||
            item.children?.find((el) => el.key === pageKey)
        );
    });

    const currentChildRoute = currentRoute?.children?.find(
        (item) => item.key === pageKey
    );

    const routeAccessPermitted = sideBarItems.some(
        ({ key, children }) =>
            key === pageKey || children?.some(({ key }) => key === pageKey)
    );

    const isRouteAccessible = isChoiceAdmin || routeAccessPermitted;

    if (!isRouteAccessibleByFeatureFlag) {
        return (
            <Navigate
                to={RoutesList.NOT_FOUND}
                replace={true}
                state={{ from: pageLabel }}
            />
        );
    }

    if (isLoading && !isChoiceAdmin) {
        return (
            <div className={styles.Loader}>
                <Loader />
            </div>
        );
    }

    const deviceType = getDeviceType(width);
    const blockedValue = currentRoute?.blocked || currentChildRoute?.blocked;

    if (isDeviceBlocked(deviceType, blockedValue)) {
        return <NotAllowedPage isOnMobile />;
    }

    if (isRouteAccessible) {
        return <Suspense fallback={<Loader />}>{children}</Suspense>;
    }

    return (
        <Navigate
            to={`/${RoutesList.NOT_ALLOWED}`}
            state={{ from: pageLabel }}
        />
    );
}
