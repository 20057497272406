import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import { REQUESTS_PATH } from 'APIServices/hazard/HazardApi.types';
import { RequestStatusWithData } from 'utils/types/api/api.types';
import { HazardClass } from 'utils/types/general/stepper.types';

export const hazardApi = createApi({
    reducerPath: 'hazardApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getHazard: builder.query<RequestStatusWithData<HazardClass[]>, void>({
            query: () => ({ url: REQUESTS_PATH.HAZARD })
        })
    })
});

export const { useGetHazardQuery } = hazardApi;
