import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgTracking = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={16}
        fill="none"
        {...props}
    >
        <g clipPath="url(#Tracking_svg__a)">
            <path
                stroke="#26A671"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11.16 5.173a4 4 0 0 1 0 5.66m-5.653-.006a4 4 0 0 1 0-5.66m7.54-1.88a6.666 6.666 0 0 1 0 9.426m-9.427 0a6.667 6.667 0 0 1 0-9.426M9.667 8A1.333 1.333 0 1 1 7 8a1.333 1.333 0 0 1 2.667 0Z"
            />
        </g>
        <defs>
            <clipPath id="Tracking_svg__a">
                <path fill="#fff" d="M.333 0h16v16h-16z" />
            </clipPath>
        </defs>
    </svg>
);
