import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';

import { passwordRegExp } from 'utils/helpers/validationHelpers';
import { useDebouncedValidation } from 'utils/hooks/useDebouncedValidation';
import { CreatePasswordForm } from 'utils/types/users/authForm.types';

interface UseValidationReturn {
    passwordRules: Rule[];
    repeatPasswordRules: Rule[];
}

export const useValidation = (
    form: FormInstance<CreatePasswordForm>,
    isLoading: boolean
): UseValidationReturn => {
    const passwordValidationMessage =
        'Password must be minimum 10 characters, contain uppercase and lowercase letter and at least 1 special symbol and digit';

    useDebouncedValidation({
        formInstance: form,
        fieldName: 'password',
        skipValidation: isLoading
    });

    useDebouncedValidation({
        formInstance: form,
        fieldName: 'confirmPassword',
        skipValidation: isLoading
    });

    const passwordRules: Rule[] = [
        {
            message: passwordValidationMessage,
            required: true,
            pattern: passwordRegExp
        }
    ];

    const repeatPasswordRules: Rule[] = [
        ...passwordRules,
        {
            required: true,
            message: 'Confirm your password'
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    new Error(`Password and Confirm Password does not match`)
                );
            }
        })
    ];

    return {
        passwordRules,
        repeatPasswordRules
    };
};
