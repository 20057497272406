import * as React from 'react';
import { SVGProps } from 'react';
export const SvgAlertCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#AlertCircle_svg__a)">
            <path
                d="M8 5.334v2.667m0 2.666h.007m6.66-2.666A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z"
                stroke="#585E74"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="AlertCircle_svg__a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
