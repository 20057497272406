import * as React from 'react';
import { SVGProps } from 'react';
export const SvgTickets = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4 9v12a2 2 0 0 0 2 2h3s.75-3 3-3 3 3 3 3h3a2 2 0 0 0 2-2V9M4 9V3a2 2 0 0 1 2-2h3s.75 3 3 3 3-3 3-3h3a2 2 0 0 1 2 2v6M4 9h2m14 0h-2m-7.5 0h3"
            stroke="#2C2F3A"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
