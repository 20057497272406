import React from 'react';

import classNames from 'classnames';

import { GoodDefectiveLabel } from 'components/UI/organisms/orders/shared/goodDefectiveLabel/GoodDefectiveLabel';
import {
    getNotification,
    NOTIFICATION_TYPES
} from 'utils/notification/Notification';

import styles from './TitleField.module.less';

interface IProps {
    blockTitle?: string;
    blockText?: React.ReactNode;
    children?: React.ReactNode | React.ReactNode[];
    theme?: string;
    className?: string;
    customTitleClassName?: string;
    hasLabel?: boolean;
    icon?: React.ReactNode;
    isDefective?: boolean;
}

export const TitleField = ({
    blockTitle,
    blockText,
    children,
    theme,
    className,
    hasLabel,
    icon,
    isDefective
}: IProps) => {
    const copyBlockText = async () => {
        try {
            if (blockText) {
                await navigator.clipboard.writeText(blockText.toString());
                getNotification({
                    title: 'Copied',
                    duration: 5,
                    type: NOTIFICATION_TYPES.SUCCESS
                });
            }
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const blockClassName = classNames(styles.Block, className);
    const textClassName = classNames(styles.BlockText, {
        [styles.BlockText__Large]: theme === 'large',
        [styles.BlockText__OnlyLargeTitle]: theme === 'largeTitle'
    });
    const titleClassName = classNames({
        [styles.Block__Title]: theme !== 'large',
        [styles.Block__TitleLarge]: theme === 'large'
    });
    return (
        <div className={blockClassName}>
            {blockTitle && (
                <div id={blockTitle} className={styles.Block__TitleBox}>
                    <span className={titleClassName}>{blockTitle}</span>
                    {hasLabel && (
                        <GoodDefectiveLabel isDefective={isDefective} />
                    )}
                </div>
            )}
            <div className={styles.Block__Content}>
                {blockText && (
                    <div className={styles.Content__Text}>
                        <span className={textClassName}>{blockText}</span>
                        <span
                            className={styles.BlockText__Icon}
                            onClick={copyBlockText}
                        >
                            {icon}
                        </span>
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};
