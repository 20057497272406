import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { AxiosError } from 'axios';

import AuthApi from 'APIServices/auth/auth.api';
import { B2CController } from 'config/B2CController';
import {
    getNotification,
    NOTIFICATION_TYPES
} from 'utils/notification/Notification';

export type IQueryWithIntercept = BaseQueryFn<
    FetchArgs,
    unknown,
    unknown,
    {
        onUploadProgress?: (data: ProgressEvent) => void;
        flowStart?: boolean;
        flowEnd?: boolean;
    }
>;

const mutex = new Mutex();

const SIGN_OUT_DELAY = 500;
const signOutWithDelay = () => {
    setTimeout(() => {
        AuthApi.signOut();
    }, SIGN_OUT_DELAY);
};

export const queryWithIntercept =
    (
        baseQuery: (token: string | null) => IQueryWithIntercept
    ): IQueryWithIntercept =>
    async (args, api, extraOptions) => {
        const acToken = AuthApi.getAccessToken();
        const b2cConfig = B2CController.getInstance().getConfig();

        let result = await baseQuery(acToken)(args, api, extraOptions);
        const resultMeta = result.meta as { response: Response };
        const resultHeaders = resultMeta?.response?.headers;
        const resultError = result.error as AxiosError | FetchBaseQueryError;

        const unauthorizedCode = resultHeaders?.get('Unauthorized-Reason-Code');
        const unauthorizedReason = resultHeaders?.get('Unauthorized-Reason');
        const flowId = resultHeaders?.get('x-correlation-id-flow');

        if (extraOptions?.flowStart && flowId) {
            api.dispatch({
                type: 'user/setCurrentFlowId',
                payload: flowId
            });
        }

        if (extraOptions?.flowEnd) {
            api.dispatch({
                type: 'user/setCurrentFlowId',
                payload: ''
            });
        }

        if (unauthorizedCode && unauthorizedReason) {
            getNotification({
                title: unauthorizedReason,
                type: NOTIFICATION_TYPES.ERROR
            });
        }

        if (result.error && Number(resultError.status) === 401) {
            const refreshToken = AuthApi.getRefreshToken();

            if (!refreshToken) {
                signOutWithDelay();
                return result;
            }

            const release = await mutex.acquire();

            try {
                const newToken = await AuthApi.refreshToken({
                    refresh_token: refreshToken,
                    response_type: 'token id_token',
                    client_id: b2cConfig.clientId,
                    grant_type: 'refresh_token'
                });

                if (newToken) {
                    result = await baseQuery(newToken.data.access_token)(
                        args,
                        api,
                        extraOptions
                    );
                } else {
                    signOutWithDelay();
                }
            } catch (err) {
                signOutWithDelay();
            } finally {
                release();
            }
        }
        return result;
    };
