import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

export const useLastUpdate = () => {
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [fromLastRefresh, setFromLastRefresh] = useState<string | undefined>(
        'Just now'
    );

    const getLastUpdatedInMinutes = () => {
        const withinOneMinute = DateTime.now().minus({ minutes: 1 }).toMillis();
        if (lastUpdated >= withinOneMinute) {
            setFromLastRefresh('Just now');
        } else {
            setFromLastRefresh(
                DateTime.fromMillis(lastUpdated, {
                    locale: 'en'
                })
                    .toRelative({
                        style: 'short',
                        padding: 0
                    })
                    ?.replace('.', '')
            );
        }
    };

    useEffect(() => {
        getLastUpdatedInMinutes();
        const interval = setInterval(() => {
            getLastUpdatedInMinutes();
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [lastUpdated]);

    return {
        setLastUpdated,
        fromLastRefresh,
        lastUpdated,
        getLastUpdatedInMinutes
    };
};
