import { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    IRedirectList,
    RedirectList
} from 'components/UI/molecules/siderMenu/SiderMenuItems';
import { useAppDispatch } from 'store';
import { RoutesList } from 'utils/constants/RoutesList';
import { useModal } from 'utils/hooks/useModal';
import { VoidFunction } from 'utils/types/general/general.types';

export interface ConfirmRedirectReturnType {
    isModalOpen: boolean;
    modalToggle: VoidFunction;
    onOkRedirect: VoidFunction;
    redirectPath: string;
    setRedirectPath: Dispatch<SetStateAction<string>>;
}

export const useConfirmRedirect = (
    signOut: VoidFunction
): ConfirmRedirectReturnType => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isOpen: isModalOpen, toggle: modalToggle, closeModal } = useModal();
    const [redirectPath, setRedirectPath] = useState('');

    const onOkRedirect = () => {
        navigate(redirectPath);
        closeModal();

        const locationState = RedirectList.find(
            (el: IRedirectList) => redirectPath === el.redirectTo
        );

        if (locationState?.resetStateAction) {
            dispatch(locationState.resetStateAction());
        }

        if (redirectPath === RoutesList.ROOT) {
            closeModal();
            signOut();
            return;
        }
    };

    return {
        isModalOpen,
        modalToggle,
        redirectPath,
        setRedirectPath,
        onOkRedirect
    };
};
