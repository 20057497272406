import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgSliders = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <g clipPath="url(#Sliders_svg__a)">
            <path
                stroke="#273C83"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M2.667 14V9.333m0-2.666V2M8 14V8m0-2.667V2m5.333 12v-3.333m0-2.667V2M.667 9.333h4m1.333-4h4m1.333 5.334h4"
            />
        </g>
        <defs>
            <clipPath id="Sliders_svg__a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
