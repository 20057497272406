import { FormInstance } from 'antd';

import {
    ENTER_QUANTITY,
    INVALID_AIR_WAYBILL,
    INVALID_AUTH_EMAIL,
    INVALID_CITY,
    INVALID_EMAIL,
    INVALID_PHONE_NUMBER,
    INVALID_POSTAL_CODE,
    ONLY_POSITIVE_NUMBERS,
    SHOULD_INCLUDE_NUMBERS_OR_LETTERS
} from 'utils/constants/errorMessages';
import { ValidationTriggerEvents } from 'utils/types/form/form.types';
import { Country } from 'utils/types/general/general.types';

import { limitMaxLength } from './normalize';

export const patternRegExp = new RegExp(/[a-zA-Z0-9]/);

export const frontEndValidationPostalCodeRegExPattern =
    /^(?![\s-])([a-zA-Z0-9\- ]{1,})$/;

export const anyCharacterAndSymbolRegExp = new RegExp(
    /^([a-zA-Z0-9]{1})|^(?=.*[a-zA-Z0-9].*)(.*){2,}$/
);

export const serialNumberRegExp = new RegExp(
    /^(?=.*[A-Z0-9])[A-Z0-9\- .]{3,}$/
);

export const passwordRegExp = new RegExp(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[><%^@#$&\-_+=[\]{}|:',.?/`~"();!\\*]+).{10,}$/gm
);

export const emailRegExp = new RegExp(
    /^([a-zA-Z0-9-._]+)@([a-zA-Z0-9-._]+)\.([a-zA-Z]{2,5})$/
);
export const phoneRegExp = new RegExp(/^(?![\s\-()])([0-9\-() ]{1,14})$/);

export const cityRegExp = new RegExp(/^([a-zA-Z0-9])(.*){1,}$/);

export const airWaybillRegExp = /^.{5,22}$/;

export const postalCodeRegExp = new RegExp(
    frontEndValidationPostalCodeRegExPattern
);

export const inputEmailRule = {
    pattern: emailRegExp,
    message: INVALID_EMAIL,
    validateTrigger: [ValidationTriggerEvents.ON_BLUR]
};

export const authEmailRule = {
    ...inputEmailRule,
    message: INVALID_AUTH_EMAIL
};

export const airWaybillRule = {
    pattern: airWaybillRegExp,
    message: INVALID_AIR_WAYBILL
};

export const inputPhoneRule = {
    pattern: phoneRegExp,
    message: INVALID_PHONE_NUMBER,
    validateTrigger: [ValidationTriggerEvents.ON_BLUR]
};

export const inputCityRule = {
    pattern: cityRegExp,
    message: INVALID_CITY,
    validateTrigger: [ValidationTriggerEvents.ON_BLUR]
};

export const getPatternRule = (message?: string) => {
    return {
        pattern: patternRegExp,
        message: message || SHOULD_INCLUDE_NUMBERS_OR_LETTERS,
        validateTrigger: [ValidationTriggerEvents.ON_BLUR]
    };
};

export const inputSerialNumberRule = (errorMessage: string) => {
    return {
        pattern: serialNumberRegExp,
        message: errorMessage,
        validateTrigger: [ValidationTriggerEvents.ON_BLUR]
    };
};

export const inputAnyCharacterAndSymbolRule = (errorMessage: string) => {
    return {
        pattern: anyCharacterAndSymbolRegExp,
        message: errorMessage,
        validateTrigger: [ValidationTriggerEvents.ON_BLUR]
    };
};

export const inputPostalCodeRule = (
    backEndValidationRegExPattern: string | null
) => {
    return {
        pattern: new RegExp(
            backEndValidationRegExPattern ??
                frontEndValidationPostalCodeRegExPattern
        ),
        message: INVALID_POSTAL_CODE,
        validateTrigger: [ValidationTriggerEvents.ON_BLUR]
    };
};

export const getRequiredRuleMessage = (name: string, prefix = 'Enter') => {
    return `${prefix} ${name}`;
};

export const getRequiredRule = (name: string, prefix = 'Enter') => {
    return {
        required: true,
        message: getRequiredRuleMessage(name, prefix)
    };
};

export const quantityDefaultRules = [
    {
        required: true,
        message: ENTER_QUANTITY
    },
    {
        pattern: new RegExp(/^[1-9]+[0-9]*$/),
        message: ONLY_POSITIVE_NUMBERS
    }
];

export const textInputMaxLength = (value: string, maxLength = 50) =>
    limitMaxLength(maxLength)(value);

export const isPostalCodeOrCityProvinceValid = (
    form: FormInstance,
    country: Country | undefined,
    hasStates: boolean
): boolean => {
    const isPostalCodeValid =
        form.getFieldValue('postalCode') &&
        new RegExp(country?.postalCodeRegEx || postalCodeRegExp).test(
            form.getFieldValue('postalCode') || ''
        );

    const isCityValid =
        form.getFieldValue('city') &&
        new RegExp(cityRegExp).test(form.getFieldValue('city') || '');

    const isCityAndProvinceValid = hasStates
        ? isCityValid && form.getFieldValue('state')
        : isCityValid;

    return Boolean(isCityAndProvinceValid || isPostalCodeValid);
};

export const setFieldError = (
    form: FormInstance,
    fieldName: string,
    error: string
) => {
    form.setFields([
        {
            name: fieldName,
            errors: [error]
        }
    ]);
};
