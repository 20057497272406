import { getBasePaginationBody } from 'utils/helpers/getBasePaginationBody';
import { GetBasicDtoPagination } from 'utils/types/api/api.types';
import {
    IGetPartResponse,
    GetSerialsResponse,
    GetSerialsTransformedResponse
} from 'utils/types/parts/parts.types';

export const getTransformedDataWithHazard = (response: IGetPartResponse) => {
    return {
        ...response,
        data: {
            ...response.data,
            items: response.data?.items?.map((item) => ({
                ...item,
                id: item.partId.toString(),
                hazard: {
                    hazardClassId: item.hazardClassId
                }
            }))
        }
    };
};

export const getSerialsQuery = (dto: GetBasicDtoPagination, url: string) => {
    return {
        url,
        method: 'POST',
        body: getBasePaginationBody({
            ...dto,
            filterFields: [
                'ClientSerial',
                'InboundTicketReferenceNumber',
                'InboundTicketClientItemRef'
            ],
            withoutCaseSensitive: true,
            fixedSorter:
                dto.sorts.field === 'initialInboundDate'
                    ? 'clientSerial'
                    : 'initialInboundDate'
        })
    };
};

export const getTransformedSerialNumbersData = (
    response: GetSerialsResponse
): GetSerialsTransformedResponse => {
    return {
        ...response,
        data: {
            ...response.data,
            items: response.data?.items
                ? response.data?.items.map((item) => ({
                      ...item,
                      id: item.clientSerial.toString()
                  }))
                : []
        }
    };
};
