import { MutableRefObject } from 'react';

import { VoidFunction } from 'utils/types/general/general.types';

export enum DundasControllerType {
    DASHBOARD = 'DASHBOARD',
    REPORT = 'REPORT',
    SCORECARD = 'SCORECARD',
    SHORTLINK = 'SHORTLINK',
    SLIDESHOW = 'SLIDESHOW',
    SMALL_MULTIPLE = 'SMALL_MULTIPLE',
    METRIC_SET = 'METRIC_SET',
    DATA_CUBE = 'DATA_CUBE',
    CUBE_PERSPECTIVE = 'CUBE_PERSPECTIVE',
    HIERARCHY = 'HIERARCHY',
    ADMIN = 'ADMIN',
    HOME = 'HOME'
}

export enum DundasViewOptions {
    TOOLBAR_ONLY = 'TOOLBAR_ONLY',
    VIEW_ONLY = 'VIEW_ONLY',
    NONE = 'NONE',
    MENU_TOOLBAR = 'MENU_TOOLBAR',
    MENU_TOOLBAR_TASKBAR = 'MENU_TOOLBAR_TASKBAR'
}

export interface EmbedOptions {
    dundasBIUrl: string;
    controllerType: string;
    fileSystemId: string;
    viewOptions: string;
    logonTokenId: string;
    parameterValues: string[];
}

type GetLogonToken = (
    biUrl: string,
    options: { isWindowsLogOn: boolean },
    cb: (data: { logOnToken: string }) => void
) => void;

interface Tokens {
    DateRange: { [key: string]: string };
    SingleDate: { [key: string]: string };
    basic: { [key: string]: string };
}

interface Logon {
    getLogonToken: GetLogonToken;
}

interface HelperMethods {
    createGuid: VoidFunction;
}

export interface DundasEmbed {
    ControllerType: { [key: string]: DundasControllerType };
    ParameterValue: VoidFunction;
    ViewOptions: { [key: string]: DundasViewOptions };
    create: (
        ref: HTMLDivElement | null | undefined,
        options: EmbedOptions
    ) => { load: VoidFunction };
    helperMethods: HelperMethods;
    logon: Logon;
    tokens: Tokens;
}

export interface IConfig {
    controllerType?: DundasControllerType;
    viewOptionsType?: DundasViewOptions;
    targetBlock?: MutableRefObject<HTMLDivElement | null>;
    fileSystemId?: string;
}

declare global {
    interface DundasType {
        embed: DundasEmbed;
    }
}
