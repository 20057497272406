import { Middleware } from '@reduxjs/toolkit';

import { setRedirectBlocker } from 'store/slices/bulk/bulk';

export const ErrorRedirectBlocker: Middleware =
    (store) => (next) => (action) => {
        const skipAction =
            action.payload?.error === 'Error' ||
            action.payload?.statusCode === 500;

        if (skipAction) {
            store.dispatch(setRedirectBlocker(false));
        }

        return next(action);
    };
