import React from 'react';

import { DundasReport } from 'components/UI/organisms/reports/dundasReport/DundasReport';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { PERMISSIONS_KEYS } from 'utils/constants/permissionsKeys';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const TransactionHistoryPage = () => {
    usePageTitle(
        pageHeadersList.MANAGE_INVENTORY.INVENTORY_TRANSACTION_HISTORY
    );

    return (
        <DundasReport
            permissionKey={PERMISSIONS_KEYS.inventoryTransactionHistory}
        />
    );
};
