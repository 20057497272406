import {
    getPatternRule,
    getRequiredRule
} from 'utils/helpers/validationHelpers';

export const ShipmentDetailsValidationRules = {
    shipmentStateRequired: [
        getRequiredRule('State/Province', 'Select'),
        getPatternRule()
    ]
};
