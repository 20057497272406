import { IElements } from 'components/UI/molecules/siderMenu/SiderMenuItem.types';
import {
    getAllSideBarItems,
    RedirectList
} from 'components/UI/molecules/siderMenu/SiderMenuItems';
import { UserTokenInfo } from 'store/slices/user/user';

export const getPage = (
    pathname: string,
    userInfo: UserTokenInfo | null,
    paramId?: string
) => {
    const findCallback = (el: string) => {
        if (paramId) return `/${el.replace(':id', paramId)}` === pathname;

        return `/${el}` === pathname;
    };

    const currentLocation = RedirectList.find((item) => {
        const nestItemTrigger = item.nestedItems?.find(findCallback);

        return `/${item.redirectTo}` === pathname || nestItemTrigger;
    });

    const getPageLabelValue = (
        items: Pick<IElements, 'key' | 'label' | 'title' | 'header'>[]
    ) => {
        return items.find((item) => item?.key === currentLocation?.key);
    };

    const page =
        getPageLabelValue(getAllSideBarItems(userInfo, true)) ||
        getPageLabelValue(
            getAllSideBarItems(userInfo, true).flatMap(
                ({ children }) => children || []
            )
        );

    return { ...page, ...currentLocation };
};
