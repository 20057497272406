import {
    BasicPaginationResponse,
    RequestStatusWithData
} from 'utils/types/api/api.types';
import { Status, Pagination } from 'utils/types/general/general.types';
import { BaseWarehouse } from 'utils/types/general/warehouse.type';

export enum REQUEST_PATH {
    GET_MIR_RECORDS = '/MaterialInReview/GetMirRecords',
    GET_MIR_RECORD = '/MaterialInReview/MirRecords/:id',
    GET_MIR_STATUS = '/MaterialInReview/GetMirStatus/:id',
    GET_MIR_REASONS = '/MaterialInReview/Reasons',
    GET_MIR_CARRIERS = '/MaterialInReview/Carriers',
    GET_MIR_ACCOUNT_WAREHOUSES = '/MaterialInReview/AccountSSLs',
    GET_MIR_DOCUMENT = '/MaterialInReview/DownloadMirDocument/:id',
    SEND_TO_MIR_REVIEW = '/MaterialInReview/SendToMIRReview'
}

export enum MATERIAL_ITEM_CONDITION {
    GOOD = 'Good',
    DEFECTIVE = 'Defective'
}

export interface MaterialItemsRaw {
    mirId: number;
    mirNum: string;
    mirDate: string;
    mirLastStatusDate: string;
    status: string;
    sslName: string;
    sslAlias: string | null;
    airWayBill: string | null;
    carrierName: string;
    mirReason: string;
    referenceNumber: string | null;
    serialNumbers?: string[];
    partNumber?: string;
    condition?: MATERIAL_ITEM_CONDITION;
    quantity?: number;
    itemRefNumber?: string;
    timeZone?: string;
    customSearchParam?: string | null;
    updatedDaysAgo: number;
}

export type MirWarehouseItem = Pick<
    BaseWarehouse,
    'sslId' | 'code' | 'alias' | 'city'
>;

export interface AccountWarehousesDataItem {
    regionCode: string;
    regionName: string;
    countries: {
        ssls: MirWarehouseItem[];
        countryCode: string;
        countryName: string;
    }[];
}

export interface MaterialItems extends MaterialItemsRaw {
    id: string;
}

export interface GetMaterialsDTO extends Pick<Pagination, 'page' | 'pageSize'> {
    sorts: string;
    filters: string;
}

export interface DownloadMirDocumentDTO {
    id: string;
    fileName: string;
}

export interface SendToMirReviewDTO {
    mirId: number;
    comment: string;
}

interface GetOrderData extends BasicPaginationResponse {
    items: MaterialItems[];
    mirStatuses: {
        [key: string]: number;
    };
}

export enum MIR_STATUSES_FOR_RESPONSE {
    ALL = 'all',
    CLOSED = 'closed',
    IN_CHOICE_REVIEW = 'inChoiceReview',
    IN_CLIENT_REVIEW = 'inClientReview',
    NEW_RECORD = 'newMirRecord'
}

export enum MIR_DETAILS_STATUSES {
    CLOSED = 'Closed',
    IN_MIR_CLOSED = 'MIR Review Closed',
    IN_CLIENT_REVIEW = 'In Client Review',
    NEW_RECORD = 'New MIR Record'
}

interface GetTransformedOrderData extends GetOrderData {
    items: MaterialItems[];
    statusTotals: Status[];
}

export type GetMaterialsResponse = RequestStatusWithData<GetOrderData>;
export type GetMaterialsTransformedResponse =
    RequestStatusWithData<GetTransformedOrderData>;

export type GetMirReasonsResponse = RequestStatusWithData<string[]>;
export type GetMirCarriersResponse = RequestStatusWithData<string[]>;
export type GetMirAccountWarehousesResponse = RequestStatusWithData<
    AccountWarehousesDataItem[]
>;

export type MirRecordDataSerialNumberItem = {
    serialNumber: string;
    serialNumberOutside: string;
};

export type MirDocumentsItem = {
    documentId: number;
    fileName: string;
    extension: string;
    description: string;
    createDate: string;
    owner: string;
};

export type MirCommentsItem = {
    comment: string;
    createDate: string;
    timeZone: string;
    owner: string;
};

export interface MirRecordData
    extends Omit<MaterialItemsRaw, 'serialNumbers' | 'status'> {
    account: string;
    subAccount: string;
    subAccountDescription: string;
    updatedDaysAgo: number;
    outsidePartNumber?: string;
    itemRef?: string;
    serialNumbers: MirRecordDataSerialNumberItem[];
    sslCity: string;
    sslCountry: string;
    partDescription?: string;
    documents: MirDocumentsItem[];
    comments: MirCommentsItem[];
    status: MIR_DETAILS_STATUSES;
}

export type GetMirRecordResponse = RequestStatusWithData<MirRecordData>;

export type GetMirStatusResponse = RequestStatusWithData<{
    status: MIR_DETAILS_STATUSES;
}>;
