import { initialPaginationState } from 'utils/constants/pagination';
import {
    Pagination,
    Sorting,
    SORTING_DIRECTION
} from 'utils/types/general/general.types';

export const getPaginationObject = (
    page?: number,
    pageSize?: number
): Pagination => {
    const pagination = {
        ...initialPaginationState
    };

    if (typeof page !== 'undefined') {
        pagination.page = page;
    }

    if (typeof pageSize !== 'undefined') {
        pagination.pageSize = pageSize;
    }

    return pagination;
};

export const getSortingObject = (
    sortingString: string | null,
    initialSorting: Sorting
): Sorting => {
    const sortingData = sortingString
        ? {
              direction: sortingString.startsWith('-')
                  ? SORTING_DIRECTION.DESC
                  : SORTING_DIRECTION.ASC,
              field: sortingString.replace(/^-/, '')
          }
        : initialSorting;

    return sortingData;
};
