import {
    GetTransformedPermissions,
    Groups,
    Permission
} from 'APIServices/permissions/Permissions.type';
import { SubAccountsProfileData } from 'APIServices/profiles/Profiles.type';
import { ITreeData } from 'utils/helpers/treeHelper';

interface ICheckerArgs {
    initialData: ITreeData[];
    currentData: ITreeData[];
}

const NOT_GROUPED_PERMISSIONS_TITLE = 'Other';

export const convertPermissionsToTreeSelectValueFormat = (
    data: GetTransformedPermissions[]
) => {
    return data
        .map(({ permissions, permissionGroupId, permissionId }) => {
            if (!permissionGroupId) {
                return permissionId.toString();
            } else {
                return (permissions || []).map(
                    (el) => `${el.permissionGroupId}-${el.permissionId}`
                );
            }
        })
        .flat();
};

export const getPermissionDataBySelectedKeys = (
    selectedKeys: string[],
    data: GetTransformedPermissions[]
) => {
    const result: {
        [key: string]: { name: string; id: number }[];
    } = {};

    selectedKeys.forEach((item) => {
        const [groupId, permissionId] = item.includes('-')
            ? item.split('-').map(Number)
            : [null, Number(item)];

        if (groupId !== null) {
            const group = data.find(
                (item) => item.permissionGroupId === groupId
            );

            if (group) {
                const permissionItem = group?.permissions?.find(
                    (item) => item.permissionId === permissionId
                );

                if (permissionItem) {
                    const groupTitle = group.displayName;

                    if (!result[groupTitle]) {
                        result[groupTitle] = [];
                    }
                    result[groupTitle].push({
                        name: permissionItem.displayName,
                        id: permissionItem.permissionId
                    });
                }
            }
        } else {
            const itemWithoutGroup = data.find(
                (item) => item.permissionId === permissionId
            );

            if (itemWithoutGroup) {
                if (!result[NOT_GROUPED_PERMISSIONS_TITLE]) {
                    result[NOT_GROUPED_PERMISSIONS_TITLE] = [];
                }
                result[NOT_GROUPED_PERMISSIONS_TITLE].push({
                    name: itemWithoutGroup.displayName,
                    id: itemWithoutGroup.permissionId
                });
            }
        }
    });

    return result;
};

export const profilesNewDataChecker = ({
    initialData,
    currentData
}: ICheckerArgs) => {
    return currentData.reduce((acc, item) => {
        const nestedParent = initialData.find((el) => el.name === item.name);
        const childrenElements = nestedParent?.children;

        const nestedChildren =
            childrenElements?.length === item.children?.length
                ? childrenElements?.every((el) =>
                      item.children?.find((child) => {
                          return child.id === el.id;
                      })
                  )
                : undefined;

        const isNewItem = !nestedParent || !nestedChildren;

        return isNewItem ? [...acc, item] : acc;
    }, [] as ITreeData[]);
};

export const permissionsDataTransformer = (
    groups?: Groups[],
    permissions?: Permission[]
) => {
    if (!groups || !permissions) return [];

    const data = groups.reduce((acc, item) => {
        const currentPermission = permissions.filter(
            (permission) =>
                permission.permissionGroupId === item.permissionGroupId
        );

        currentPermission.sort((a, b) => a.sortOrder - b.sortOrder);

        return [
            ...acc,
            {
                ...item,
                permissions: currentPermission
            }
        ];
    }, [] as GetTransformedPermissions[]);

    const permissionsWithoutGroup = permissions
        .filter((permission) => !permission.permissionGroupId)
        .map((item) => {
            return {
                ...item,
                permissions: null
            };
        });

    const allPermissionsItems = [...data, ...permissionsWithoutGroup];

    allPermissionsItems.sort((a, b) => a.sortOrder - b.sortOrder);

    return allPermissionsItems;
};

export const accountAndSubsDisplayInfo = (
    subAccountItem: SubAccountsProfileData[]
): SubAccountsProfileData[] => {
    return subAccountItem.map((el) => ({
        id: el.id,
        name:
            el.isTransactional === false
                ? `${el.name} (Billing Only)`
                : el.name,
        isTransactional: el.isTransactional
    }));
};
