export const downloadBlob = async (
    response: Response,
    fileName?: string
): Promise<void> => {
    const link = document.createElement('a');
    const blobData = await response.blob();

    if (blobData.type) {
        const url = window.URL.createObjectURL(blobData);

        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
};
