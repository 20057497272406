import { OrderWarehouseDataItem } from 'APIServices/trackOrders/TrackOrdersApi.types';
import { Account } from 'APIServices/users/UsersApi.types';
import { getSubAccountDescription } from 'components/UI/organisms/trackOrders/orderList/components/accountFilter/useAccountFilter';

export const getAccountTagLabel = (
    value: string,
    accounts: Account[] | undefined
) => {
    if (!accounts || accounts.length === 0) return '';

    const [accId, subAccId] = value.split('-').map(Number);
    const account = accounts.find((item) => item.accountId === accId);

    if (!account) return '';

    const accNumber = account.accountNumber || '';
    if (!subAccId) return accNumber;

    const subAccLabel = getSubAccountDescription(subAccId, account.subAccounts);
    return `${accNumber}: ${subAccLabel}`;
};

export const getSslTagLabel = (
    value: string,
    data?: OrderWarehouseDataItem
) => {
    if (!data?.availableSslList?.length) return '';

    const parts = value.split('_');

    for (const region of data?.availableSslList) {
        if (region.regionCode === parts[0]) {
            if (parts.length === 1) {
                return region.region;
            }

            for (const country of region.availableCountries) {
                if (parts.length === 2 && country.countryCode === parts[1]) {
                    return country.country;
                }

                if (parts.length === 3 && country.countryCode === parts[1]) {
                    const ssl = country.sslList.find(
                        (ssl) => ssl.code === parts[2]
                    );
                    if (ssl) {
                        return ssl.code;
                    }
                }
            }
        }
    }

    return '';
};
