import * as React from 'react';
import { SVGProps } from 'react';
export const SvgActionDotsVertical = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={4}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 7.664a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM2 2.997a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM2 12.33a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.334Z"
            stroke={props.color || '#8E94A9'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
