import React from 'react';

import { LocateClosestWarehouseTemplate } from 'components/templates/manageInventory/locateClosestSsl/LocateClosestWarehouseTemplate';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { usePageTitle } from 'utils/helpers/usePageTitle';
import { WithAccountWrapper } from 'utils/HOCs/WithAccountWrapper';

export const LocateClosestWarehousePage = () => {
    usePageTitle(pageHeadersList.MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE);

    return (
        <WithAccountWrapper>
            <LocateClosestWarehouseTemplate />
        </WithAccountWrapper>
    );
};
