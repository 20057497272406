import {
    useGetUserInfoQuery,
    useGetUserSettingsQuery
} from 'APIServices/users/Users.api';
import { UserInfo, UserSettings } from 'APIServices/users/UsersApi.types';
import { useAppSelector } from 'store';

export interface IUserSettingsData
    extends Partial<UserSettings>,
        Partial<UserInfo> {
    loading: boolean;
}

export const useUserSettings = (): IUserSettingsData => {
    const userId = useAppSelector(({ user: { userId } }) => userId);
    const { data: userSettings, isLoading: userSettingsLoading } =
        useGetUserSettingsQuery();
    const { data: userData, isLoading: userDataLoading } = useGetUserInfoQuery(
        userId || ''
    );

    return {
        ...(userData?.data || {}),
        ...(userSettings?.data || {}),
        loading: userSettingsLoading || userDataLoading
    };
};
