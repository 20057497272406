import React from 'react';

import { IInput, TextInput } from 'components/UI/atoms/input/Input';
import { BaseFormBlock } from 'components/UI/molecules/form/BaseFormBlock';
import { FormField } from 'utils/types/form/form.types';

type IProps = FormField &
    Omit<IInput, 'name'> & { isHiddenContainer?: boolean };

export const FormTextInput = ({
    title,
    fieldName,
    rules,
    value,
    initialValue,
    required,
    validateTrigger,
    isHiddenContainer,
    wrapperClassName,
    ...props
}: IProps) => {
    return (
        <BaseFormBlock
            title={title}
            fieldName={fieldName}
            rules={rules}
            required={required}
            initialValue={initialValue}
            validateTrigger={validateTrigger}
            isHidden={isHiddenContainer}
            wrapperClassName={wrapperClassName}
        >
            <TextInput value={value} required={required} {...props} />
        </BaseFormBlock>
    );
};
