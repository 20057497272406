import { createSlice } from '@reduxjs/toolkit';

import {
    inboundReplenishmentApi,
    inboundOrderByPartDefectiveApi
} from 'APIServices/inboundReplenishment/InboundReplenishment.api';
import { inboundReturnApi } from 'APIServices/inboundReturn/InboundReturn.api';
import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from 'APIServices/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import { outboundOrderByPartApi } from 'APIServices/outboundOrderByPart/OutboundOrderByPart.api';
import { outboundOrderByWarehouseDefectiveApi } from 'APIServices/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { warehouseApi } from 'APIServices/warehouse/Warehouse.api';
import { IAction } from 'store/index';
import { initialPaginationState } from 'utils/constants/pagination';
import { handleFulfilledPagination } from 'utils/helpers/handleFulfilledPagination';
import { MasterInitialState } from 'utils/types/api/api.types';
import { Pagination } from 'utils/types/general/general.types';
import { IGetPartTransformedResponse } from 'utils/types/parts/parts.types';

export interface IInitialPartMaster extends MasterInitialState {
    data: IGetPartTransformedResponse['data']['items'];
}

const initialState: IInitialPartMaster = {
    loading: false,
    data: [],
    pagination: initialPaginationState,
    errors: { code: 0, message: '' }
};

export const partMasterSlice = createSlice({
    name: 'partMaster',
    initialState,
    reducers: {
        editPagination(
            state,
            action: IAction<
                Partial<Omit<Pagination, 'pagesCount' | 'totalItemsCount'>>
            >
        ) {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },
        resetPagination(state) {
            state.pagination = initialPaginationState;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            outboundOrderByPartApi.endpoints.getOutboundOrderParts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundOrderByPartDefectiveApi.endpoints.getPartsMaterials
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            warehouseApi.endpoints.getWarehouseParts.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            outboundOrderByWarehouseDefectiveApi.endpoints.getWarehouseParts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseApi.endpoints.getWarehouseParts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            materialsTransferByWarehouseDefectiveApi.endpoints.getWarehouseParts
                .matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundReplenishmentApi.endpoints.getPartsMaterials.matchFulfilled,
            handleFulfilledPagination
        );
        builder.addMatcher(
            inboundReturnApi.endpoints.getParts.matchFulfilled,
            handleFulfilledPagination
        );
    }
});

export const { editPagination, resetPagination } = partMasterSlice.actions;
