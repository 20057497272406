import * as React from 'react';
import { SVGProps } from 'react';
export const SvgDeactivate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.666 13v-1.333A2.666 2.666 0 0 0 7.999 9H3.333a2.667 2.667 0 0 0-2.667 2.667V13m11.333-8.667 3.334 3.334m0-3.334-3.334 3.334m-3.666-4a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
