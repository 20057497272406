export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const currentYear = new Date().getFullYear();

export const currentPlusOneYears = [currentYear, currentYear + 1];

export const defaultYearsPeriod = 2;

export const datePickerMonthPlaceholder = 'MM/DD/YYYY';
export const datePickerDaysPlaceholder = 'DD.MM.YYYY';
