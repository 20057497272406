import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCube = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={96}
        height={96}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.08 27.84 48 48.04l34.92-20.2M48 88.32V48m36 16V32a8 8 0 0 0-4-6.92l-28-16a8 8 0 0 0-8 0l-28 16A8 8 0 0 0 12 32v32a8 8 0 0 0 4 6.92l28 16a8 8 0 0 0 8 0l28-16A8.001 8.001 0 0 0 84 64Z"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
