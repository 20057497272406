import React from 'react';

import { DundasReport } from 'components/UI/organisms/reports/dundasReport/DundasReport';
import { pageHeadersList } from 'utils/constants/pageTitles';
import { PERMISSIONS_KEYS } from 'utils/constants/permissionsKeys';
import { usePageTitle } from 'utils/helpers/usePageTitle';

export const OrderHistoryPage = () => {
    usePageTitle(pageHeadersList.ANALYTICS.ORDER_HISTORY);

    return (
        <DundasReport permissionKey={PERMISSIONS_KEYS.analyticsOrderHistory} />
    );
};
