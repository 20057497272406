import { useEffect, useState } from 'react';

import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { useUpdateQuickAccessMutation } from 'APIServices/users/Users.api';
import { TransformedQuickAccessResponse } from 'APIServices/users/UsersApi.types';
import { areUniqueArraysEqual } from 'utils/helpers/array';
import { getSelectedPermissions } from 'utils/helpers/home';

interface Props {
    items?: TransformedQuickAccessResponse[];
}

export const useCustomize = ({ items }: Props) => {
    const [updateQuickAccess, { isLoading }] = useUpdateQuickAccessMutation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<number[]>([]);

    const toggleVisible = () => {
        setIsOpen((prev) => !prev);
    };

    const onSave = async () => {
        await updateQuickAccess({ links: selectedValues });
        handleVisibleChange(false);
    };

    const getInitialIds = (
        selectedPermissions: TransformedQuickAccessResponse[]
    ) => {
        return selectedPermissions.reduce((acc, item) => {
            const permissionsIds = item.permissions.map(
                (el) => el.permissionId
            );

            return [...acc, ...permissionsIds];
        }, [] as number[]);
    };

    const saveInitialIds = (
        allPermissions: TransformedQuickAccessResponse[]
    ) => {
        const selectedPermissions = getSelectedPermissions(allPermissions);

        const selectedIds = getInitialIds(selectedPermissions);

        setSelectedValues(selectedIds);
    };

    useEffect(() => {
        if (items) {
            saveInitialIds(items);
        }
    }, [items]);

    const noNewItemsInSelection = areUniqueArraysEqual(
        selectedValues,
        getInitialIds(getSelectedPermissions(items ?? []))
    );

    const onClose = () => {
        handleVisibleChange(false);
        if (items) {
            saveInitialIds(items);
        }
    };

    const handleVisibleChange = (visible: boolean) => {
        setIsOpen(visible);
        if (!visible && items) {
            saveInitialIds(items);
        }
    };

    const onChangeCheckbox = (values: CheckboxValueType[]) => {
        setSelectedValues(values as number[]);
    };

    return {
        isOpen,
        isLoading,
        selectedValues,
        noNewItemsInSelection,
        onSave,
        onClose,
        toggleVisible,
        onChangeCheckbox,
        handleVisibleChange
    };
};
