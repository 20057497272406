import {
    GetTransformedPermissions,
    GetPermissionsResponse
} from 'APIServices/permissions/Permissions.type';
import { Account } from 'APIServices/users/UsersApi.types';
import {
    BasicPaginationResponse,
    RequestStatusWithData
} from 'utils/types/api/api.types';
import { Profile, TransformedProfile } from 'utils/types/users/profiles.types';

export interface SaveProfileDTO {
    displayName: string;
    clientId: string;
    permissions: { permissionId: string }[];
    subAccounts: { subAccountId: string }[];
}

export interface EditProfileDTO extends SaveProfileDTO {
    profileId: number;
}

export interface ProfileAccounts
    extends Omit<Account, 'accountNumber' | 'subAccounts'> {
    subAccounts: {
        subAccountId: number;
        name: string;
        isTransactional: boolean;
    }[];
}

export interface SubAccountsProfileData {
    id: number;
    name: string;
    isTransactional?: boolean;
}

export interface UserProfileReturnData {
    displayName: string;
    subAccounts: SubAccountsProfileData[];
}

export type GetProfilesResponse = RequestStatusWithData<
    BasicPaginationResponse & {
        items: Profile[];
    }
>;

export interface GetProfilesTransformedResponse extends GetProfilesResponse {
    items: TransformedProfile[];
}

interface ProfileAccountsAndSubs {
    accounts: ProfileAccounts[];
}

interface GetProfileData extends Profile, ProfileAccountsAndSubs {
    permissionsAndGroups: GetPermissionsResponse;
}

export interface GetTransformedProfileData
    extends Profile,
        ProfileAccountsAndSubs {
    permissionsAndGroups: GetTransformedPermissions[];
}

export type GetProfileByIdResponse = RequestStatusWithData<GetProfileData>;

export type GetTransformedProfileByIdResponse =
    RequestStatusWithData<GetTransformedProfileData>;

export enum REQUEST_PATH {
    SAVE_PROFILE = '/Profiles',
    DELETE_PROFILE = '/Profiles/:id',
    GET_LIST = '/Profiles/GetProfiles',
    GET_PROFILE_BY_ID = '/Profiles/:id',
    GET_PROFILES_CLIENTS = '/Profiles/GetProfilesClients'
}
