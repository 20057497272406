import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './HomeBlock.module.less';
interface Props {
    title: string;
    actionContent: ReactNode;
    children: ReactNode;
    additionalHeaderContent?: ReactNode;
    actionDisabled?: boolean;
    watchListHeaderSetting?: boolean;
}

export const HomeBlock = ({
    title,
    actionContent,
    children,
    additionalHeaderContent,
    actionDisabled = false,
    watchListHeaderSetting = false
}: Props) => {
    return (
        <div className={styles.Wrapper}>
            <div
                className={classNames(styles.BlockHead, {
                    [styles.BlockHead__WatchlistMobileSetting]:
                        watchListHeaderSetting
                })}
            >
                <div className={styles.BlockHead__Head}>
                    <span className={styles.BlockHead__Head__Title}>
                        {title}
                    </span>
                    {additionalHeaderContent && (
                        <div className={styles.BlockHead__Head__Additional}>
                            {additionalHeaderContent}
                        </div>
                    )}
                </div>
                {actionContent && (
                    <div
                        className={classNames(styles.BlockHead__Actions, {
                            [styles.BlockHead__Disabled]: actionDisabled
                        })}
                    >
                        {actionContent}
                    </div>
                )}
            </div>
            <div className={styles.Content}>{children}</div>
        </div>
    );
};
