import React, { ReactNode } from 'react';

import { ModalProps, Modal as AntModal } from 'antd';
import classNames from 'classnames';

import { SvgCross24 } from 'components/icons';

import styles from './Modal.module.less';

interface IProps extends ModalProps {
    children: ReactNode;
    isColumnMinWidthAvailable?: boolean;
    isWithUnset?: boolean;
    isCrossDisabled?: boolean;
    fullScreen?: boolean;
}

export const Modal = ({
    children,
    className,
    wrapClassName,
    fullScreen = false,
    centered = true,
    isColumnMinWidthAvailable = false,
    isWithUnset = true,
    isCrossDisabled = false,
    ...props
}: IProps) => {
    return (
        <AntModal
            {...props}
            centered={centered}
            wrapClassName={classNames(styles.ModalWrapper, wrapClassName, {
                [styles.ModalWrapper__FullScreen]: fullScreen
            })}
            className={classNames(styles.Modal, className, {
                [styles.Modal__WithMinWidth]: isColumnMinWidthAvailable,
                [styles.Modal__WithUnset]: isWithUnset,
                [styles.Modal__DisabledCross]: isCrossDisabled
            })}
            maskClosable={false}
            closeIcon={
                <div data-testid="cross">
                    <SvgCross24 />
                </div>
            }
        >
            {children}
        </AntModal>
    );
};
