import React from 'react';

import { Form } from 'antd';

import { Button } from 'components/UI/atoms/button/Button';
import { FormTextInput } from 'components/UI/molecules/form/formTextInput/FormTextInput';
import { AuthConfirmModal } from 'components/UI/organisms/auth/authConfirmModal/AuthConfirmModal';
import styles from 'components/UI/organisms/auth/AuthForm.module.less';
import { useCreatePassword } from 'components/UI/organisms/auth/passwordRecovery/createPassword/useCreatePassword';
import { ValidationTriggerEvents } from 'utils/types/form/form.types';

export const CreatePassword = () => {
    const {
        form,
        isLoading,
        initialData,
        passwordRules,
        isTokenExpired,
        isSuccessModalOpen,
        repeatPasswordRules,
        confirmIsDisabled,
        onConfirm,
        onContinue,
        onStartNewSession,
        passwordChangeHandler
    } = useCreatePassword();

    const showMainForm = !isSuccessModalOpen && !isTokenExpired;

    return (
        <>
            {isSuccessModalOpen && (
                <AuthConfirmModal
                    title="Password changed"
                    description="Your password has been successfully changed"
                    actionText="Sign In"
                    onButtonClick={onConfirm}
                />
            )}
            {isTokenExpired && (
                <AuthConfirmModal onButtonClick={onStartNewSession} />
            )}
            {showMainForm && (
                <Form
                    name="createPass_form"
                    className={styles.Form}
                    initialValues={{ recoveryEmail: initialData?.email }}
                    onFinish={onContinue}
                    form={form}
                    data-testid="createPass_form"
                >
                    <div className={styles.Message}>
                        <span
                            className={styles.Message__Confirmed}
                            data-testid="MessageTitle"
                        >
                            Please create password for Choice Logistics Portal
                        </span>
                    </div>
                    <div className={styles.Form__Fields}>
                        <FormTextInput
                            title="Email"
                            fieldName="recoveryEmail"
                            placeholder="Enter your email"
                            disabled={true}
                        />
                        <div className={styles.Form__Passwords}>
                            <FormTextInput
                                title="New Password"
                                fieldName="password"
                                type="password"
                                placeholder="Enter new password"
                                validateTrigger={[
                                    ValidationTriggerEvents.ON_BLUR
                                ]}
                                rules={passwordRules}
                                data-testid="pass_field"
                                className={styles.Password}
                                onChange={passwordChangeHandler('password')}
                            />
                            <FormTextInput
                                title="Confirm New Password"
                                fieldName="confirmPassword"
                                type="password"
                                placeholder="Confirm new password"
                                validateTrigger={[
                                    ValidationTriggerEvents.ON_BLUR
                                ]}
                                rules={repeatPasswordRules}
                                data-testid="confirmPass_field"
                                onChange={passwordChangeHandler(
                                    'confirmPassword'
                                )}
                            />
                        </div>
                    </div>
                    <Button
                        text="Continue"
                        theme="primary"
                        testId="CreatePass_submit"
                        className={styles.Form__Submit}
                        htmlType="submit"
                        isLoading={isLoading}
                        disabled={confirmIsDisabled}
                    />
                </Form>
            )}
        </>
    );
};
