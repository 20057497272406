import React, { ReactNode } from 'react';

import { Popover as AntPopover, PopoverProps, Space } from 'antd';
import classNames from 'classnames';

import { SvgArrow } from 'components/icons';

import styles from './Popover.module.less';

interface IProps extends PopoverProps {
    title?: ReactNode;
    showIcon?: boolean;
    arrowColor?: string;
    popoverContent: ReactNode;
    triggerContent: ReactNode;
    overlayClass?: string;
    triggerClass?: string;
}

export const Popover = ({
    showIcon = true,
    arrowColor = '#8E94A9',
    popoverContent,
    title,
    triggerContent,
    overlayClass,
    triggerClass,
    visible = false,
    ...props
}: IProps) => {
    return (
        <AntPopover
            {...props}
            visible={visible}
            content={popoverContent}
            title={title}
            overlayClassName={classNames(styles.Container, overlayClass)}
            overlayStyle={{ paddingTop: 0 }}
            trigger="click"
        >
            <div className={triggerClass}>
                <Space>
                    {triggerContent}
                    {showIcon && (
                        <div
                            className={classNames(styles.Container__Arrow, {
                                [styles.Container__Arrow__Rotate]: visible
                            })}
                        >
                            <SvgArrow color={arrowColor} />
                        </div>
                    )}
                </Space>
            </div>
        </AntPopover>
    );
};
