import { downloadBlob } from 'utils/helpers/downloadBlob';
import {
    DownloadTemplateDTO,
    BULK_TEMPLATES_NAMES
} from 'utils/types/bulkOrder/bulk.types';
import { FILE_EXTENSIONS } from 'utils/types/general/general.types';

export const bulkResponseHandler = async (
    response: Response,
    dto: DownloadTemplateDTO,
    fileName: BULK_TEMPLATES_NAMES
): Promise<void> => {
    const fullName =
        dto.fileExtension === FILE_EXTENSIONS.CSV
            ? `${fileName}.csv`
            : `${fileName}.xlsx`;

    return downloadBlob(response, fullName);
};
