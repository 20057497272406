import { VoidFunction } from 'utils/types/general/general.types';

interface IActions {
    onLoad: VoidFunction;
    onError: VoidFunction;
}

export const addDundas = ({ onLoad, onError }: IActions) => {
    const script = document.createElement('script');

    script.src =
        'https://www.dundas.com/files/dbi/dundas.embed.js/4.0.0.1000/dundas.embed.min.js';
    script.id = 'dundasBI';
    script.onload = onLoad;
    script.onerror = onError;
    document.body.appendChild(script);
};
