import React from 'react';

import { Form } from 'antd';

import { Button } from 'components/UI/atoms/button/Button';
import { FormTextInput } from 'components/UI/molecules/form/formTextInput/FormTextInput';
import { AuthConfirmModal } from 'components/UI/organisms/auth/authConfirmModal/AuthConfirmModal';
import styles from 'components/UI/organisms/auth/AuthForm.module.less';
import { useEmailVerification } from 'components/UI/organisms/auth/passwordRecovery/emailVerification/useEmailVerification';
import { ValidationTriggerEvents } from 'utils/types/form/form.types';

export const EmailVerification = () => {
    const {
        resendCode,
        onSendCode,
        onFinish,
        form,
        isLoading,
        codeRules,
        emailRules,
        recoveryStep,
        isTokenExpired,
        isSendCodeAvailable,
        isCodeCodeVerifyAvailable
    } = useEmailVerification();

    const isFirstStep = recoveryStep === 0;
    const isSecondStep = recoveryStep === 1;

    const txtBeforeCodeSending =
        'For security reasons your email should be confirmed';

    const txtAfterCodeSending = (
        <>
            <span>Verification code has been sent.</span>
            <span>Enter the code to confirm</span>
        </>
    );

    return (
        <>
            {isTokenExpired ? (
                <AuthConfirmModal onButtonClick={resendCode} />
            ) : (
                <Form
                    name="forgotPass_form"
                    className={styles.Form}
                    initialValues={{ remember: true }}
                    onFinish={isFirstStep ? onSendCode : onFinish}
                    form={form}
                    data-testid="forgotPass_form"
                >
                    <div className={styles.Message}>
                        <span
                            className={styles.Message__Verification}
                            data-test="MessageTitle"
                        >
                            Account verification
                        </span>
                        <div
                            className={styles.Message__Confirmed}
                            data-test="MessageDescription"
                        >
                            {isFirstStep
                                ? txtBeforeCodeSending
                                : txtAfterCodeSending}
                        </div>
                    </div>
                    <div className={styles.Form__Fields}>
                        <FormTextInput
                            title="Email"
                            fieldName="recoveryEmail"
                            placeholder="Enter your email"
                            rules={emailRules}
                            disabled={isSecondStep}
                            validateTrigger={[ValidationTriggerEvents.ON_BLUR]}
                            data-testid="email_field"
                            onChange={(value) => {
                                form.setFieldsValue({ recoveryEmail: value });
                            }}
                        />

                        {isSecondStep && (
                            <>
                                <FormTextInput
                                    title="Verification Code"
                                    fieldName="code"
                                    placeholder="Enter verification code"
                                    validateTrigger={[
                                        ValidationTriggerEvents.ON_BLUR
                                    ]}
                                    rules={codeRules}
                                    data-testid="verificationCode_field"
                                    onChange={(value) => {
                                        form.setFieldsValue({ code: value });
                                    }}
                                />
                                <div className={styles.Message__DidntReceive}>
                                    <span className={styles.DidntReceiveCode}>
                                        Didn’t receive a code?
                                    </span>
                                    <div>
                                        <span
                                            className={styles.Resend}
                                            onClick={resendCode}
                                        >
                                            <span>Resend Code</span>
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <Button
                        text={
                            isFirstStep ? 'Send Verification Code' : 'Confirm'
                        }
                        theme="primary"
                        testId="EmailVerification_submit"
                        className={styles.Form__Submit}
                        htmlType="submit"
                        isLoading={isLoading}
                        disabled={
                            isFirstStep
                                ? !isSendCodeAvailable
                                : !isCodeCodeVerifyAvailable
                        }
                    />
                </Form>
            )}
        </>
    );
};
