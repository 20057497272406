import { useMemo, useRef, useState } from 'react';

import { areUniqueArraysEqual } from 'utils/helpers/array';
import useDeepCompareEffect from 'utils/helpers/useDeepCompareEffect';

interface Props {
    defaultSelectedKeys: string[];
    isDataLoading?: boolean;
    isDefaultKeysDependency?: boolean;
}

export const useTreeData = ({
    isDataLoading,
    defaultSelectedKeys,
    isDefaultKeysDependency
}: Props) => {
    const popupRef = useRef<HTMLDivElement | null>(null);

    const [selectedKeys, setSelectedKeys] =
        useState<string[]>(defaultSelectedKeys);

    const hasKeys = selectedKeys.length > 0;

    useDeepCompareEffect(() => {
        if (!isDataLoading) {
            setSelectedKeys(defaultSelectedKeys);
        }
    }, [isDataLoading, isDefaultKeysDependency, defaultSelectedKeys]);

    const areSelectedKeysChanged = useMemo(() => {
        return !areUniqueArraysEqual(defaultSelectedKeys, selectedKeys);
    }, [defaultSelectedKeys, selectedKeys]);

    const onTreeInit = () => {
        setSelectedKeys(defaultSelectedKeys);
    };

    const handleChange = (newValue: string[]) => {
        setSelectedKeys(newValue);
    };

    const onRemoveSearchBadge = (value: string) => {
        const withoutRemoved = selectedKeys.filter((item) => item !== value);

        setSelectedKeys(withoutRemoved);
    };

    const getCustomPopupContainer = (triggerNode: HTMLElement) => {
        if (triggerNode && popupRef?.current) {
            return popupRef.current;
        }
        return document.body;
    };

    const onClearClick = () => {
        setSelectedKeys([]);
    };

    return {
        popupRef,
        selectedKeys,
        setSelectedKeys,
        handleChange,
        onClearClick,
        onTreeInit,
        onRemoveSearchBadge,
        getCustomPopupContainer,
        areSelectedKeysChanged,
        hasKeys
    };
};
