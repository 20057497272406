import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import {
    GetTransformedPermissions,
    GetPermissionsResponse,
    REQUEST_PATH
} from 'APIServices/permissions/Permissions.type';
import { permissionsDataTransformer } from 'utils/helpers/profilesHelpers';
import { RequestStatusWithData } from 'utils/types/api/api.types';

export const permissionsApi = createApi({
    reducerPath: 'permissionsApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getPermissions: builder.query<GetTransformedPermissions[], void>({
            query: () => {
                return {
                    url: REQUEST_PATH.GET_PERMISSIONS,
                    method: 'GET'
                };
            },
            transformResponse: (
                response: RequestStatusWithData<GetPermissionsResponse>
            ): GetTransformedPermissions[] => {
                return permissionsDataTransformer(
                    response.data.groups,
                    response.data.permissions
                );
            }
        })
    })
});

export const { useGetPermissionsQuery } = permissionsApi;
