import {
    ActionCreatorWithPayload,
    configureStore,
    combineReducers
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { clientsApi } from 'APIServices/clients/Clients.api';
import { countriesApi } from 'APIServices/countries/Countries.api';
import { dundasApi } from 'APIServices/dundas/Dundas.api';
import { filesApi } from 'APIServices/files/Files.api';
import { hazardApi } from 'APIServices/hazard/Hazard.api';
import {
    inboundByBulkApi,
    inboundByBulkDefectiveApi
} from 'APIServices/inboundByBulk/InboundByBulk.api';
import {
    inboundReplenishmentApi,
    inboundOrderByPartDefectiveApi
} from 'APIServices/inboundReplenishment/InboundReplenishment.api';
import { inboundReturnApi } from 'APIServices/inboundReturn/InboundReturn.api';
import { closestWarehousesApi } from 'APIServices/manageInventory/GetClosestWarehouses.api';
import { materialsInReviewApi } from 'APIServices/materialsInReview/MaterialsInReview.api';
import {
    materialsTransferByWarehouseApi,
    materialsTransferByWarehouseDefectiveApi
} from 'APIServices/materialsTransferByWarehouse/MaterialsTransferByWarehouse.api';
import { notificationsApi } from 'APIServices/notifications/Notifications.api';
import {
    outboundByBulkApi,
    outboundByBulkDefectiveApi
} from 'APIServices/outboundByBulk/OutboundByBulk.api';
import { outboundOrderByPartApi } from 'APIServices/outboundOrderByPart/OutboundOrderByPart.api';
import {
    outboundOrderByWarehouseApi,
    outboundOrderByWarehouseDefectiveApi
} from 'APIServices/outboundOrderByWarehouse/OutboundOrderByWarehouse.api';
import { permissionsApi } from 'APIServices/permissions/Permissions.api';
import { profilesApi } from 'APIServices/profiles/Profiles.api';
import { trackOrdersApi } from 'APIServices/trackOrders/TrackOrders.api';
import {
    transfersByBulkApi,
    transfersByBulkDefectiveApi
} from 'APIServices/transfersByBulk/TransfersByBulk.api';
import { usersApi } from 'APIServices/users/Users.api';
import { warehouseApi } from 'APIServices/warehouse/Warehouse.api';
import { ErrorRedirectBlocker } from 'store/middlewares/errorRedirectBlocker/ErrorRedirectBlocker';
import { ErrorLogger } from 'store/middlewares/logger/Logger';
import { activeOrderSlice } from 'store/slices/activeOrder/activeOrder';
import { adminPanelSlice } from 'store/slices/adminPanel/AdminPanel';
import { bulkSlice } from 'store/slices/bulk/bulk';
import { dundasSlice } from 'store/slices/dundas/Dundas';
import { filesSlice } from 'store/slices/files/files';
import { notificationsSlice } from 'store/slices/notifications/notifications';
import { partMasterSlice } from 'store/slices/partMaster/partMaster';
import { preCheckMasterSlice } from 'store/slices/preCheckMaster/preCheckMaster';
import { serialsMasterSlice } from 'store/slices/serialsMaster/serialsMaster';
import { trackOrdersSlice } from 'store/slices/trackOrders/trackOrders';
import { userSlice } from 'store/slices/user/user';

const middlewares = [
    ErrorLogger,
    ErrorRedirectBlocker,
    hazardApi.middleware,
    outboundOrderByPartApi.middleware,
    outboundOrderByWarehouseApi.middleware,
    outboundOrderByWarehouseDefectiveApi.middleware,
    inboundReplenishmentApi.middleware,
    inboundOrderByPartDefectiveApi.middleware,
    inboundReturnApi.middleware,
    materialsTransferByWarehouseApi.middleware,
    materialsTransferByWarehouseDefectiveApi.middleware,
    inboundByBulkApi.middleware,
    inboundByBulkDefectiveApi.middleware,
    outboundByBulkApi.middleware,
    outboundByBulkDefectiveApi.middleware,
    transfersByBulkApi.middleware,
    transfersByBulkDefectiveApi.middleware,
    filesApi.middleware,
    dundasApi.middleware,
    countriesApi.middleware,
    clientsApi.middleware,
    usersApi.middleware,
    warehouseApi.middleware,
    closestWarehousesApi.middleware,
    trackOrdersApi.middleware,
    materialsInReviewApi.middleware,
    permissionsApi.middleware,
    profilesApi.middleware,
    notificationsApi.middleware
];

const rootReducer = combineReducers({
    [userSlice.name]: userSlice.reducer,
    [activeOrderSlice.name]: activeOrderSlice.reducer,
    [bulkSlice.name]: bulkSlice.reducer,
    [filesSlice.name]: filesSlice.reducer,
    [partMasterSlice.name]: partMasterSlice.reducer,
    [preCheckMasterSlice.name]: preCheckMasterSlice.reducer,
    [serialsMasterSlice.name]: serialsMasterSlice.reducer,
    [trackOrdersSlice.name]: trackOrdersSlice.reducer,
    [adminPanelSlice.name]: adminPanelSlice.reducer,
    [dundasSlice.name]: dundasSlice.reducer,
    [hazardApi.reducerPath]: hazardApi.reducer,
    [outboundOrderByPartApi.reducerPath]: outboundOrderByPartApi.reducer,
    [outboundOrderByWarehouseApi.reducerPath]:
        outboundOrderByWarehouseApi.reducer,
    [outboundOrderByWarehouseDefectiveApi.reducerPath]:
        outboundOrderByWarehouseDefectiveApi.reducer,
    [inboundReplenishmentApi.reducerPath]: inboundReplenishmentApi.reducer,
    [inboundReturnApi.reducerPath]: inboundReturnApi.reducer,
    [inboundOrderByPartDefectiveApi.reducerPath]:
        inboundOrderByPartDefectiveApi.reducer,
    [inboundByBulkApi.reducerPath]: inboundByBulkApi.reducer,
    [inboundByBulkDefectiveApi.reducerPath]: inboundByBulkDefectiveApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [materialsTransferByWarehouseApi.reducerPath]:
        materialsTransferByWarehouseApi.reducer,
    [materialsTransferByWarehouseDefectiveApi.reducerPath]:
        materialsTransferByWarehouseDefectiveApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [closestWarehousesApi.reducerPath]: closestWarehousesApi.reducer,
    [trackOrdersApi.reducerPath]: trackOrdersApi.reducer,
    [materialsInReviewApi.reducerPath]: materialsInReviewApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [profilesApi.reducerPath]: profilesApi.reducer,
    [dundasApi.reducerPath]: dundasApi.reducer,
    [outboundByBulkApi.reducerPath]: outboundByBulkApi.reducer,
    [outboundByBulkDefectiveApi.reducerPath]:
        outboundByBulkDefectiveApi.reducer,
    [transfersByBulkApi.reducerPath]: transfersByBulkApi.reducer,
    [transfersByBulkDefectiveApi.reducerPath]:
        transfersByBulkDefectiveApi.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middlewares)
});

export interface IAction<A> {
    type: string;
    payload: A;
}

export type IActionCreator<T> = ActionCreatorWithPayload<T, string>;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export default store;
