import * as React from 'react';
import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
    color?: string;
}
export const SvgFilterArrow = (props: IProps) => (
    <svg
        width={6}
        height={4}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.25 3.125 3 .875.75 3.125"
            stroke={props.color || '#2C2F3A'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
