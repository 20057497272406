import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { bulkResponseHandler } from 'APIServices/bulkResponseHandler';
import { baseQueryWithIntercept } from 'APIServices/fetchBaseQuery';
import { IQueryWithIntercept } from 'APIServices/queryWithIntercept';
import {
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from 'APIServices/transfersByBulk/TransfersByBulk.type';
import { getServicesWithIds } from 'utils/helpers/activeOrder';
import { IRequestStatusWithEmptyData } from 'utils/types/api/api.types';
import {
    DownloadTemplateDTO,
    BULK_TEMPLATES_NAMES,
    ColumnMappingDTO,
    ColumnMappingResponse,
    IValidateFileResponse,
    IValidateFileDTO,
    IGetAvailableCarriersResponse,
    IGetAvailableCarriersDTO,
    IGroupingOrdersResponse,
    IGroupingOrdersDTO,
    ISubmitOrdersDTO
} from 'utils/types/bulkOrder/bulk.types';

const getCommonEndpoints = (
    builder: EndpointBuilder<IQueryWithIntercept, never, string>,
    requestsPath: typeof REQUESTS_PATH | typeof REQUESTS_PATH_DEFECTIVE,
    isDefective?: boolean
) => ({
    getDownloadTransfersTemplate: builder.mutation<File, DownloadTemplateDTO>({
        query: (dto) => ({
            url: requestsPath.DOWNLOAD_TEMPLATE,
            headers: {
                responseType: 'blob',
                'content-type': 'application/octet-stream'
            },
            responseHandler: async (response) =>
                bulkResponseHandler(
                    response,
                    dto,
                    isDefective
                        ? BULK_TEMPLATES_NAMES.TRANSFERS_DEFECTIVE
                        : BULK_TEMPLATES_NAMES.TRANSFERS
                ),
            cache: 'no-cache',
            method: 'POST',
            body: dto
        })
    }),
    resetTransfersFile: builder.mutation<IRequestStatusWithEmptyData, string>({
        query: (id) => ({
            url: requestsPath.RESET_FILE.replace(':id', id),
            method: 'DELETE'
        })
    }),
    getTransfersColumnsMapping: builder.mutation<
        ColumnMappingResponse,
        ColumnMappingDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_COLUMNS_MAPPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    validateTransfersFile: builder.mutation<
        IValidateFileResponse,
        IValidateFileDTO
    >({
        query: (dto) => ({
            url: requestsPath.VALIDATE_FILE,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    getAvailableCarriers: builder.mutation<
        IGetAvailableCarriersResponse,
        IGetAvailableCarriersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_AVAILABLE_CARRIERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        }),
        transformResponse(response: IGetAvailableCarriersResponse) {
            return getServicesWithIds(
                response
            ) as IGetAvailableCarriersResponse;
        }
    }),
    getTransfersGroupingOrders: builder.mutation<
        IGroupingOrdersResponse,
        IGroupingOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_GROUPING,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    }),
    submitTransfersOrders: builder.mutation<
        IRequestStatusWithEmptyData,
        ISubmitOrdersDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT_ORDERS,
            method: 'POST',
            body: dto,
            cache: 'no-cache'
        })
    })
});

export const transfersByBulkApi = createApi({
    reducerPath: 'transfersByBulkApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH)
});
export const transfersByBulkDefectiveApi = createApi({
    reducerPath: 'transfersByBulkDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) =>
        getCommonEndpoints(builder, REQUESTS_PATH_DEFECTIVE, true)
});

export const {
    useGetDownloadTransfersTemplateMutation,
    useResetTransfersFileMutation,
    useGetTransfersColumnsMappingMutation,
    useValidateTransfersFileMutation,
    useGetAvailableCarriersMutation,
    useGetTransfersGroupingOrdersMutation,
    useSubmitTransfersOrdersMutation
} = transfersByBulkApi;

export const {
    useGetDownloadTransfersTemplateMutation:
        useDefectiveGetDownloadTransfersTemplateMutation,
    useResetTransfersFileMutation: useDefectiveResetTransfersFileMutation,
    useGetTransfersColumnsMappingMutation:
        useDefectiveGetTransfersColumnsMappingMutation,
    useValidateTransfersFileMutation: useDefectiveValidateTransfersFileMutation,
    useGetAvailableCarriersMutation: useDefectiveGetAvailableCarriersMutation,
    useGetTransfersGroupingOrdersMutation:
        useDefectiveGetTransfersGroupingOrdersMutation,
    useSubmitTransfersOrdersMutation: useDefectiveSubmitTransfersOrdersMutation
} = transfersByBulkDefectiveApi;
