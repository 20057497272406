import { IElements } from 'components/UI/molecules/siderMenu/SiderMenuItem.types';
import { IFeatureFlags } from 'utils/types/general/appConfig.types';
import { SHOW_MODE } from 'utils/types/general/featureFlags.types';

export class FeatureFlagsController {
    protected static instance: FeatureFlagsController;
    private _flagsConfig?: IFeatureFlags;

    public static getInstance(): FeatureFlagsController {
        if (!FeatureFlagsController.instance) {
            FeatureFlagsController.instance = new FeatureFlagsController();
        }

        return FeatureFlagsController.instance;
    }

    public get config() {
        return this._flagsConfig;
    }

    public set config(flagConfig) {
        this._flagsConfig = flagConfig;
    }

    private getFeatureByKey = (key: string) => {
        return this.config?.flags[key];
    };

    private getNestedItems(item: IElements) {
        if (!item.children) return item;
        const alwaysAllowedChildren = item.children?.filter(
            (item) =>
                item.mode === SHOW_MODE.ALWAYS || this.getFeatureByKey(item.key)
        );

        return {
            ...item,
            children: alwaysAllowedChildren
        };
    }

    public getRouteBlocker(
        sideBarItems: IElements[],
        pageKey?: string
    ): boolean {
        const currentSiderItem = sideBarItems.find((item) => {
            const pageByChild = item.children?.find((el) => el.key === pageKey);
            return item.key === pageKey || pageByChild;
        });
        const childElement = currentSiderItem?.children?.find(
            (item) => item.key === pageKey
        );

        if (!this.config) return true;
        const isAlwaysAllowed =
            currentSiderItem?.mode === SHOW_MODE.ALWAYS ||
            childElement?.mode === SHOW_MODE.ALWAYS;

        return pageKey && currentSiderItem
            ? this.getFeatureByKey(pageKey) || isAlwaysAllowed
            : false;
    }

    public getFormattedSiderMenu(allItems: IElements[]) {
        if (!this.config) return allItems;

        return allItems.reduce((acc, item) => {
            const allowedChildrenExist = item.children?.some(
                (item) => item.mode === SHOW_MODE.ALWAYS
            );

            const isParentAlwaysAllowed = item.mode === SHOW_MODE.ALWAYS;
            if (isParentAlwaysAllowed || allowedChildrenExist) {
                const nestedItems = this.getNestedItems(item);
                return [...acc, nestedItems];
            }

            const flagByParent = this.getFeatureByKey(item.key);
            const flagByChild = item.children?.filter((child) =>
                Boolean(this.getFeatureByKey(child.key))
            );

            const featureIsDisabled = !flagByParent && !flagByChild?.length;
            const filteredChildrenByFlags = item.children?.filter((child) => {
                const isChildExist = flagByChild?.find(
                    (childFlag) => childFlag.key === child.key
                );
                return Boolean(isChildExist);
            });

            const formattedChildren = [
                ...acc,
                {
                    ...item,
                    children: filteredChildrenByFlags
                }
            ];
            const formattedParent = flagByParent ? [...acc, item] : acc;

            const formattedItem =
                item.children && filteredChildrenByFlags?.length
                    ? formattedChildren
                    : formattedParent;

            return featureIsDisabled ? acc : formattedItem;
        }, [] as IElements[]);
    }
}
