import {
    useGetCountriesQuery,
    useGetStatesQuery
} from 'APIServices/countries/Countries.api';
import { Country } from 'utils/types/general/general.types';

export const useCountryStateData = (country?: Country) => {
    const { data: countriesData } = useGetCountriesQuery();
    const countries = countriesData?.data;

    const isShowPostalCode = Boolean(country?.countryName);

    const countryId = country?.countryId;
    const { data: statesData } = useGetStatesQuery(countryId || 0, {
        skip: !countryId
    });
    const states = statesData?.data;
    const hasStates = Boolean(states?.length);

    return {
        countries,
        isShowPostalCode,
        states,
        hasStates
    };
};
