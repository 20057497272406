import * as React from 'react';
import { SVGProps } from 'react';

export const SvgIconLeft = ({ color, ...props }: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.667 8H3.333m0 0L8 12.667M3.333 8 8 3.333"
            stroke={color || '#fff'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
