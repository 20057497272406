import * as React from 'react';
import { SVGProps } from 'react';
export const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6 14.5H3.333A1.334 1.334 0 0 1 2 13.167V3.833A1.333 1.333 0 0 1 3.333 2.5H6m4.667 9.333L14 8.5m0 0-3.333-3.333M14 8.5H6"
            stroke="#273C83"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
