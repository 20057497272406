import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';

import { authEmailRule } from 'utils/helpers/validationHelpers';
import { useDebouncedValidation } from 'utils/hooks/useDebouncedValidation';
import { SignInForm } from 'utils/types/users/authForm.types';

interface UseValidationReturn {
    emailRules: Rule[];
    passwordValidation: Rule[];
}

export const useValidation = (
    form: FormInstance<SignInForm>
): UseValidationReturn => {
    useDebouncedValidation({
        formInstance: form,
        fieldName: 'email'
    });

    useDebouncedValidation({
        formInstance: form,
        fieldName: 'password'
    });

    const emailRules: Rule[] = [{ ...authEmailRule, required: true }];

    const passwordValidation: Rule[] = [
        {
            required: true,
            message: 'Please input your password'
        }
    ];

    return {
        emailRules,
        passwordValidation
    };
};
