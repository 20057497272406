import React from 'react';

import { ColumnTitleProps } from 'antd/es/table/interface';
import classNames from 'classnames';

import { SvgFilterArrow } from 'components/icons';
import { SORTING_DIRECTION } from 'utils/types/general/general.types';

import styles from './SortingCell.module.less';

interface IProps<T> extends ColumnTitleProps<T> {
    children: React.ReactNode | React.ReactNode[];
    id: string;
    className?: string;
}

export const SortingCell = <T extends Record<'id', string>>({
    sortColumns,
    children,
    id,
    className
}: IProps<T>) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === id);
    const spawnArrows = () => {
        if (sortedColumn?.order) {
            return sortedColumn?.order === SORTING_DIRECTION.DESC ? (
                <SvgFilterArrow
                    className={styles.Arrows__Down}
                    color={'var(--tableSortingText)'}
                />
            ) : (
                <SvgFilterArrow
                    className={styles.Arrows__Up}
                    color={'var(--tableSortingText)'}
                />
            );
        }
        return (
            <>
                <SvgFilterArrow
                    className={styles.Arrows__Up}
                    color={'var(--tableSortingText)'}
                />
                <SvgFilterArrow
                    className={styles.Arrows__Down}
                    color={'var(--tableSortingText)'}
                />
            </>
        );
    };

    return (
        <div
            className={classNames(styles.SortingCell, className, {
                [styles.SortingCell__Sort]: Boolean(sortedColumn?.order)
            })}
            data-testid={`${id}Sort`}
        >
            {children}
            <div className={styles.SortingCell__Arrows}>{spawnArrows()}</div>
        </div>
    );
};
