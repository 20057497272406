import React, { MouseEvent } from 'react';

import classNames from 'classnames';

import { Loader } from 'components/UI/atoms/loader/Loader';

import styles from './OrderStatusButtonMobile.module.less';

export interface OrderStatusButton {
    text: string;
    id?: string;
    className?: string;
    status?: string;
    size?: 'small';
    htmlType?: 'button' | 'submit';
    isMobileStatus?: boolean;
    testId?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    startIcon?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    endIcon?: React.ReactNode;
}

export const OrderStatusButtonMobile = ({
    text,
    className,
    status,
    testId,
    onClick,
    disabled,
    htmlType = 'button',
    startIcon,
    endIcon,
    isLoading,
    id
}: OrderStatusButton) => {
    const buttonTitle = (
        <div className={styles.MobileLabelItem}>
            <div className={styles.MobileLabelItem__StatusTitle}>
                <span>{status}</span>
            </div>
            <div className={styles.MobileLabelItem__ItemText}>
                <span>{text}</span>
            </div>
        </div>
    );

    return (
        <>
            <button
                className={classNames(styles.Button, className)}
                onClick={onClick}
                data-testid={testId}
                type={htmlType}
                disabled={disabled}
                id={id}
            >
                {!isLoading ? (
                    <>
                        {startIcon}
                        {buttonTitle}
                        {endIcon}
                    </>
                ) : (
                    <>
                        Loading
                        <Loader size="xSmall" theme="white" />
                    </>
                )}
            </button>
        </>
    );
};
