import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCross = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={10}
        height={11}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m9 1.5-8 8m0-8 8 8"
            stroke={props.color || 'var(--iconStroke)'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
