import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgMangePartsSideIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M5.759 4.286a.75.75 0 1 0 1.5 0zM5.5 9.75a.75.75 0 0 0 0-1.5zM9.25 5a.75.75 0 0 0 1.5 0zm-.14-1.25c.1 0 .16.031.18.046.008.006 0 .002-.013-.02a.2.2 0 0 1-.027-.11h1.5c0-.473-.258-.844-.561-1.071A1.8 1.8 0 0 0 9.11 2.25zm-5.22 4.5c-.1 0-.16-.031-.18-.046-.008-.006 0-.002.013.02a.2.2 0 0 1 .027.11h-1.5c0 .473.258.844.561 1.071s.687.345 1.079.345zm-.14.083V3.667h-1.5v4.666zm0-4.666a.2.2 0 0 1-.027.109c-.012.022-.021.026-.013.02a.3.3 0 0 1 .18-.046v-1.5c-.392 0-.776.118-1.079.345s-.561.598-.561 1.072zm.14.083h2.619v-1.5H3.89zm2.619 0H9.11v-1.5H6.509zM5.759 3v1.286h1.5V3zM3.89 9.75H5.5v-1.5H3.89zm5.36-6.083V5h1.5V3.667z"
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.031 10.5a2 2 0 1 0 4 0 2 2 0 0 0-4 0M11.031 7v1.5M11.031 12.5V14M14.065 8.75l-1.3.75M9.3 11.5l-1.3.75M8 8.75l1.3.75M12.766 11.5l1.3.75"
        />
    </svg>
);
