import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={17}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.167 1.333V4M5.833 1.333V4M2.5 6.667h12m-10.667-4h9.334c.736 0 1.333.597 1.333 1.333v9.333c0 .737-.597 1.334-1.333 1.334H3.833A1.333 1.333 0 0 1 2.5 13.333V4c0-.736.597-1.333 1.333-1.333Z"
            stroke={props.color || '#8E94A9'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
