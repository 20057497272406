import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgWarehouse = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#8E94A9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5 14.667V8h3m3 6.667V8H8m0 0v2M2 6l6-4.667L14 6v7.334a1.333 1.333 0 0 1-1.333 1.333H3.333A1.333 1.333 0 0 1 2 13.334V6Z"
        />
    </svg>
);
