import React from 'react';

import classNames from 'classnames';

import { Welcome } from 'components/UI/atoms/welcome/Welcome';
import { PanelBlock } from 'components/UI/organisms/adminPanel/shared/panelBlock/PanelBlock';
import { QuickAccess } from 'components/UI/organisms/home/quickAccess/QuickAccess';
import { WatchList } from 'components/UI/organisms/home/watchList/WatchList';
import { useAppSelector } from 'store';
import { isMobile, isTablet } from 'utils/constants/breakpoints';
import { WelcomeMessage } from 'utils/constants/messages';
import { useUserSettings } from 'utils/hooks/useUserSettings';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';

import styles from './HomeTemplate.module.less';

export const HomeTemplate = () => {
    const { isOrderWatchlistEnabled, isQuickAccessEnabled, isDarkModeEnabled } =
        useUserSettings();

    const lastName = useAppSelector((state) => state.user.userInfo.lastName);
    const firstName = useAppSelector((state) => state.user.userInfo.firstName);
    const { width } = useWindowDimentions();
    const isMobileVersion = isMobile(width) || isTablet(width);

    return (
        <PanelBlock
            fullHeight={true}
            fullWidth={true}
            contentClassName={styles.ContentWrapper}
        >
            <div
                className={classNames(styles.Container, {
                    [styles.Container__Mobile]: isMobileVersion
                })}
            >
                <Welcome
                    name={firstName}
                    lastName={lastName}
                    welcomeMessage={WelcomeMessage}
                />
                {isQuickAccessEnabled && (
                    <QuickAccess
                        isMobileVersion={isMobileVersion}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                )}
                {isOrderWatchlistEnabled && (
                    <WatchList
                        isMobileVersion={isMobileVersion}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                )}
            </div>
        </PanelBlock>
    );
};
