import { FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useDispatch } from 'react-redux';

import {
    resetOrderState,
    setRedirectBlocker
} from 'store/slices/activeOrder/activeOrder';
import { resetSelectedAccountAndSubAccount } from 'store/slices/adminPanel/AdminPanel';
import { resetPreCheckMaster } from 'store/slices/preCheckMaster/preCheckMaster';
import { Sorting, SORTING_DIRECTION } from 'utils/types/general/general.types';
import { StepperFormValues } from 'utils/types/general/stepper.types';

export const warehouseKeys = [
    'code',
    'alias',
    'city',
    'countryCode',
    'countryName'
];
export const countriesKeys = [
    'countryCode',
    'countryName',
    'internationalCallingCode'
];

export const getKeys = <T extends keyof DefaultOptionType['data']>(
    option?: DefaultOptionType,
    keys: T[] = []
) => {
    return keys.reduce(
        (acc: (DefaultOptionType['data'][T] | undefined)[], key: T) => {
            const optionValue = option?.data ? option?.data[key] : null;
            return optionValue ? [...acc, optionValue] : acc;
        },
        []
    );
};

export const sortingFilteredValue = (inputValue: string, keys: string[]) => {
    return (optionA: DefaultOptionType, optionB: DefaultOptionType) => {
        const optionADefaultFilter = optionA?.data?.code;
        const optionBDefaultFilter = optionB?.data?.code;
        const searchString = inputValue && inputValue.toLowerCase();

        const hasFullMatch = (option: DefaultOptionType) =>
            getKeys(option, keys).some(
                (key) => key?.toLowerCase() === searchString
            );

        if (optionA?.disabled || optionB?.disabled) {
            return 1;
        }
        if (
            inputValue &&
            (optionADefaultFilter === searchString || hasFullMatch(optionA))
        ) {
            return -1;
        }
        if (inputValue && hasFullMatch(optionB)) {
            return 1;
        }

        return optionADefaultFilter?.localeCompare(optionBDefaultFilter);
    };
};

export const startNewOrder = (
    dispatch: ReturnType<typeof useDispatch>,
    isChoiceAdmin?: boolean,
    form?: FormInstance<StepperFormValues>
) => {
    dispatch(resetPreCheckMaster());
    dispatch(resetOrderState());
    dispatch(setRedirectBlocker(false));
    if (isChoiceAdmin) {
        dispatch(resetSelectedAccountAndSubAccount());
    }
    form?.resetFields();
};

export const checkIsSortingBySurName = (sorting: Sorting) => {
    return (
        sorting.field === 'surName' &&
        sorting.direction === SORTING_DIRECTION.DESC
    );
};
