import * as React from 'react';
import { SVGProps } from 'react';
export const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#Pencil_svg__a)">
            <path
                d="M11.333 1.998a1.884 1.884 0 0 1 3.22 1.333A1.887 1.887 0 0 1 14 4.664l-9 9-3.667 1 1-3.666 9-9Z"
                stroke={props.color || '#273C83'}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="Pencil_svg__a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
