export const REQUESTS_PATH = {
    DOWNLOAD_TEMPLATE:
        '/WarehouseTransfers/GoodPartsUpload/DownloadTemplateFile',
    RESET_FILE: '/WarehouseTransfers/GoodPartsUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING:
        '/WarehouseTransfers/GoodPartsUpload/GetColumnsMapping',
    VALIDATE_FILE: '/WarehouseTransfers/GoodPartsUpload/ValidateFile',
    GET_AVAILABLE_CARRIERS:
        '/WarehouseTransfers/GoodPartsUpload/AvailableServices',
    GET_GROUPING: '/WarehouseTransfers/GoodPartsUpload/ReviewOrders',
    SUBMIT_ORDERS: '/WarehouseTransfers/GoodPartsUpload/SubmitOrders'
} as const;

export const REQUESTS_PATH_DEFECTIVE = {
    DOWNLOAD_TEMPLATE:
        '/WarehouseTransfers/DefectivePartsUpload/DownloadTemplateFile',
    RESET_FILE: '/WarehouseTransfers/DefectivePartsUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING:
        '/WarehouseTransfers/DefectivePartsUpload/GetColumnsMapping',
    VALIDATE_FILE: '/WarehouseTransfers/DefectivePartsUpload/ValidateFile',
    GET_AVAILABLE_CARRIERS:
        '/WarehouseTransfers/DefectivePartsUpload/AvailableServices',
    GET_GROUPING: '/WarehouseTransfers/DefectivePartsUpload/ReviewOrders',
    SUBMIT_ORDERS: '/WarehouseTransfers/DefectivePartsUpload/SubmitOrders'
} as const;
