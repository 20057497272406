import * as React from 'react';
import { SVGProps } from 'react';

export const SvgActionDotsHorizontal = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={5}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 3.5C9.55228 3.5 10 3.05228 10 2.5C10 1.94772 9.55228 1.5 9 1.5C8.44771 1.5 8 1.94772 8 2.5C8 3.05228 8.44771 3.5 9 3.5Z"
            fill="#8F969E"
        />
        <path
            d="M16 3.5C16.5523 3.5 17 3.05228 17 2.5C17 1.94772 16.5523 1.5 16 1.5C15.4477 1.5 15 1.94772 15 2.5C15 3.05228 15.4477 3.5 16 3.5Z"
            fill="#8F969E"
        />
        <path
            d="M2 3.5C2.55228 3.5 3 3.05228 3 2.5C3 1.94772 2.55228 1.5 2 1.5C1.44772 1.5 1 1.94772 1 2.5C1 3.05228 1.44772 3.5 2 3.5Z"
            fill="#8F969E"
        />
        <path
            d="M9 3.5C9.55228 3.5 10 3.05228 10 2.5C10 1.94772 9.55228 1.5 9 1.5C8.44771 1.5 8 1.94772 8 2.5C8 3.05228 8.44771 3.5 9 3.5Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 3.5C16.5523 3.5 17 3.05228 17 2.5C17 1.94772 16.5523 1.5 16 1.5C15.4477 1.5 15 1.94772 15 2.5C15 3.05228 15.4477 3.5 16 3.5Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2 3.5C2.55228 3.5 3 3.05228 3 2.5C3 1.94772 2.55228 1.5 2 1.5C1.44772 1.5 1 1.94772 1 2.5C1 3.05228 1.44772 3.5 2 3.5Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
