import React from 'react';

import classNames from 'classnames';

import styles from './ScreenContainer.module.less';

interface IProps {
    children: React.ReactNode;
    id?: string;
    className?: string;
}

export const ScreenContainer = ({ children, id, className }: IProps) => {
    return (
        <div id={id} className={classNames(styles.Container, className)}>
            {children}
        </div>
    );
};
