export const REQUESTS_PATH = {
    DOWNLOAD_TEMPLATE: '/CustomerOrders/GoodPartsUpload/DownloadTemplateFile',
    RESET_FILE: '/CustomerOrders/GoodPartsUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING: '/CustomerOrders/GoodPartsUpload/GetColumnsMapping',
    VALIDATE_FILE: '/CustomerOrders/GoodPartsUpload/ValidateFile',
    GET_AVAILABLE_SERVICES: '/CustomerOrders/GoodPartsUpload/AvailableServices',
    GET_GROUPING: '/CustomerOrders/GoodPartsUpload/ReviewOrders',
    SUBMIT_ORDERS: '/CustomerOrders/GoodPartsUpload/SubmitOrders'
} as const;

export const REQUESTS_PATH_DEFECTIVE = {
    DOWNLOAD_TEMPLATE:
        '/ReturnParts/DefectiveFromChoiceUpload/DownloadTemplateFile',
    RESET_FILE: '/ReturnParts/DefectiveFromChoiceUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING:
        '/ReturnParts/DefectiveFromChoiceUpload/GetColumnsMapping',
    VALIDATE_FILE: '/ReturnParts/DefectiveFromChoiceUpload/ValidateFile',
    GET_AVAILABLE_SERVICES:
        '/ReturnParts/DefectiveFromChoiceUpload/AvailableServices',
    GET_GROUPING: '/ReturnParts/DefectiveFromChoiceUpload/ReviewOrders',
    SUBMIT_ORDERS: '/ReturnParts/DefectiveFromChoiceUpload/SubmitOrders'
} as const;
