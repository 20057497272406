import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgSideChoiceLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M12.482 3.33a4.8 4.8 0 0 0-1.254-.828c1.433.769 1.784 3.098 1.405 4.927-.505 2.432-2.855 4.365-5.329 4.449l.042-.153L9.935 2h-.708l-.699 1.661-.473 1.23-3.168 8.536-.041.113-.055.146c.54.188 1.13.3 1.763.315h.18a7 7 0 0 0 2.812-.626c1.954-1.01 3.542-2.98 4.018-5.276.394-1.897-.057-3.613-1.082-4.77M6.239 8.61c-.709-.353-1.18-1.036-1.21-1.928-.047-1.42 1.037-2.86 2.492-3.412L8.542 2a7 7 0 0 0-.83.144c-1.377.33-2.632 1.054-3.643 2.004-.94 1.012-1.64 2.28-1.928 3.672-.522 2.521.444 4.72 2.27 5.714z"
        />
    </svg>
);
