import React from 'react';

import { Account } from 'APIServices/users/UsersApi.types';
import { Popover } from 'components/UI/atoms/popover/Popover';
import { AccountSelectPopoverContent } from 'components/UI/organisms/header/accountSelectPopoverContent/AccountSelectPopoverContent';
import { AccountSelectTriggerBtn } from 'components/UI/organisms/header/accountSelectTriggerBtn/AccountSelectTriggerBtn';

import styles from './AccountSelect.module.less';

export interface Props {
    value?: string;
    data?: Account[];
    onSelect: (value: string) => void;
    visible: boolean;
    onVisibleChange: (visible: boolean) => void;
    account?: string;
    subAccount?: string;
    disable: boolean;
    triggerBtnClass?: string;
}

export const AccountSelect = ({
    data = [],
    value,
    onSelect,
    visible,
    onVisibleChange,
    account,
    subAccount,
    disable,
    triggerBtnClass
}: Props) => {
    const popoverContentVisible = !disable && (
        <AccountSelectPopoverContent
            value={value}
            data={data}
            onSelect={onSelect}
        />
    );

    return (
        <Popover
            overlayClass={styles.AccountSelectOverlay}
            triggerClass={styles.AccountSelectTrigger}
            showIcon={!disable}
            placement="bottomLeft"
            triggerContent={
                <AccountSelectTriggerBtn
                    className={triggerBtnClass}
                    account={account}
                    subAccount={subAccount}
                />
            }
            onVisibleChange={onVisibleChange}
            visible={visible}
            popoverContent={popoverContentVisible}
            destroyTooltipOnHide
        />
    );
};
