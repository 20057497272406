import React from 'react';

import cn from 'classnames';

import styles from './UnexpectedError.module.less';

export const UnexpectedError = ({
    fullHeight = false
}: {
    fullHeight?: boolean;
}) => {
    return (
        <div
            className={cn(styles.Container, {
                [styles.Container__FullHeight]: fullHeight
            })}
        >
            <div className={styles.Content}>
                <div className={styles.Content__Title}>
                    <span>Unexpected error occurred</span>
                </div>
            </div>
        </div>
    );
};
