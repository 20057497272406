import * as React from 'react';
import { SVGProps } from 'react';
export const SvgCreateOutbound = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.14 10.593a6.666 6.666 0 1 1-8.807-8.706M13.667 6V2m0 0h-4m4 0L9 6.667"
            stroke={props.stroke || '#fff'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
