import * as React from 'react';
import { SVGProps } from 'react';
export const SvgBasket = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2 4.003h1.333m0 0H14m-10.667 0v9.333a1.333 1.333 0 0 0 1.334 1.333h6.666a1.333 1.333 0 0 0 1.334-1.333V4.003H3.333Zm2 0V2.669a1.333 1.333 0 0 1 1.334-1.333h2.666a1.333 1.333 0 0 1 1.334 1.333v1.334"
            stroke={props.color || '#273C83'}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
