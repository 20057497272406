import React, { useMemo, useRef, useState } from 'react';

import { Account } from 'APIServices/users/UsersApi.types';
import { Radio, RadioGroup } from 'components/UI/atoms/radio/Radio';
import { TreeSelect } from 'components/UI/molecules/treeSelect/TreeSelect';

import styles from './AccountSelectPopoverContent.module.less';

export interface HeaderPopoverType {
    data: Account[];
    value?: string;
    onSelect: (value: string) => void;
}

const CustomTreeNode = ({ title, value }: { title: string; value: string }) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Radio label={title} value={value} />
        </div>
    );
};

const LIST_HEIGHT = 284;
const LIST_ITEM_HEIGHT = 36;

export const AccountSelectPopoverContent = ({
    data,
    value,
    onSelect
}: HeaderPopoverType) => {
    const popupRef = useRef<HTMLDivElement | null>(null);
    const [selectedSubAcc, setSelectedSubAcc] = useState<string | undefined>(
        value
    );

    const onChange = (newValue: string) => {
        setSelectedSubAcc(newValue);
        onSelect(newValue);
    };

    const getCustomPopupContainer = (triggerNode: HTMLElement) => {
        if (triggerNode && popupRef?.current) {
            return popupRef.current;
        }
        return document.body;
    };

    const treeData = useMemo(() => {
        return data.map(
            ({ accountNumber, accountId, subAccounts }) =>
                ({
                    title: accountNumber,
                    value: accountId.toString(),
                    children: subAccounts.map((el) => {
                        const value = `${accountId}-${el.subAccountId}`;

                        return {
                            title: (
                                <CustomTreeNode
                                    title={el.description}
                                    value={value}
                                />
                            ),
                            value
                        };
                    })
                } || [])
        );
    }, [data]);

    return (
        <div
            className={styles.AccountSelectPopoverContent}
            id="AccountList"
            ref={popupRef}
        >
            <RadioGroup
                value={selectedSubAcc}
                onChange={(e) => onChange(e.target.value)}
            >
                <TreeSelect
                    size="large"
                    treeData={treeData}
                    placeholder="Account"
                    multiple={false}
                    listHeight={LIST_HEIGHT}
                    listItemHeight={LIST_ITEM_HEIGHT}
                    treeCheckable={false}
                    maxTagCount={0}
                    open={true}
                    showArrow={false}
                    treeDefaultExpandedKeys={
                        selectedSubAcc ? [selectedSubAcc] : []
                    }
                    dropdownClassName={styles.TreeSelectDropdown}
                    getPopupContainer={getCustomPopupContainer}
                />
            </RadioGroup>
        </div>
    );
};
