import React from 'react';

import styles from './NotAllowedOnMobileTemplate.module.less';

export const NotAllowedOnMobileTemplate = () => (
    <div className={styles.Container}>
        <span className={styles.Container__Text}>
            This feature is only available in desktop version
        </span>
    </div>
);
