import React from 'react';

import { Checkbox as AntCheckbox, CheckboxProps } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import classNames from 'classnames';

import styles from './Checkbox.module.less';

interface ICheckbox extends CheckboxProps {
    label?: CheckboxProps['children'];
}

export const Checkbox = ({ label, disabled, ...props }: ICheckbox) => {
    return (
        <AntCheckbox
            className={styles.Container}
            disabled={disabled}
            {...props}
        >
            <div
                className={classNames(styles.Label, {
                    [styles.Label__Disabled]: disabled
                })}
                data-testid="CheckboxLabel"
            >
                {label}
            </div>
        </AntCheckbox>
    );
};

type ICheckboxGroup = CheckboxGroupProps;

export const CheckboxGroup = ({ children, ...props }: ICheckboxGroup) => {
    return <AntCheckbox.Group {...props}>{children}</AntCheckbox.Group>;
};
