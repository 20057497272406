import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    inboundByBulkApi,
    inboundByBulkDefectiveApi
} from 'APIServices/inboundByBulk/InboundByBulk.api';
import {
    outboundByBulkApi,
    outboundByBulkDefectiveApi
} from 'APIServices/outboundByBulk/OutboundByBulk.api';
import {
    transfersByBulkApi,
    transfersByBulkDefectiveApi
} from 'APIServices/transfersByBulk/TransfersByBulk.api';
import { IAction } from 'store/index';
import { IRequestStatusWithEmptyData } from 'utils/types/api/api.types';
import {
    BULK_TYPES,
    DELIVERY_TYPES_VALUES,
    ColumnMappingResponse,
    MappingResultsItem,
    IValidateFileData,
    IValidateFileError,
    IGroupedOrder,
    IValidateFileResponse,
    IDelivery,
    ISelectedService
} from 'utils/types/bulkOrder/bulk.types';
import { ByChoiceService } from 'utils/types/deliveryServices/deliveryServices.types';

export type IManuallyMappedItem = MappingResultsItem & {
    manuallyMappedValue?: string;
};

export interface IBulkState {
    activeStep: number;
    mappedColumns: MappingResultsItem[];
    manualMappedColumns: IManuallyMappedItem[];
    unmappedColumns: string[];
    redirectBlocker: boolean;
    uploadStepFormCompleted: boolean;
    fileData?: string;
    columnsLoading: boolean;
    validatedFileData: IValidateFileData[];
    validatedFileErrors: IValidateFileError[];
    fileValidationProgress: number;
    submitBulkOrderProgress: number;
    fileValidationId: string;
    orderListId: string;
    groupedOrders: IGroupedOrder[];
    bulkSubmit: IRequestStatusWithEmptyData | null;
    deliveryMethod?: DELIVERY_TYPES_VALUES;
    bulkType: BULK_TYPES | null;
    isBrowserBackButtonBlocked: boolean;
    delivery: IDelivery[];
    selectedService?: ISelectedService;
    carriers?: ByChoiceService[];
    carriersLoading?: boolean;
    servicesError?: Omit<IRequestStatusWithEmptyData, 'data'> | null;
    returnPackAvailability?: boolean;
}

const initialState: IBulkState = {
    activeStep: 1,
    fileData: undefined,
    mappedColumns: [],
    unmappedColumns: [],
    redirectBlocker: false,
    uploadStepFormCompleted: false,
    columnsLoading: false,
    manualMappedColumns: [],
    validatedFileErrors: [],
    validatedFileData: [],
    fileValidationProgress: 0,
    submitBulkOrderProgress: 0,
    fileValidationId: '',
    groupedOrders: [],
    orderListId: '',
    bulkSubmit: null,
    bulkType: null,
    isBrowserBackButtonBlocked: false,
    delivery: []
};

const setColumnMappingsResult = (
    state: IBulkState,
    { payload }: PayloadAction<ColumnMappingResponse, string>
) => {
    state.mappedColumns = payload.data?.mappingResults;
    state.manualMappedColumns = payload.data?.mappingResults;
    state.unmappedColumns = payload.data?.unmappedHeaders;
    state.columnsLoading = false;
};

const setValidationResult = (
    state: IBulkState,
    { payload }: PayloadAction<IValidateFileResponse>
) => {
    state.validatedFileErrors = payload.data?.errors;
    state.validatedFileData = payload.data?.fileData;
    state.fileValidationId = payload.data?.fileValidationId;
};

export const bulkSlice = createSlice({
    name: 'bulk',
    initialState,
    reducers: {
        setActiveStep(state, action: IAction<number>) {
            state.activeStep = action.payload;
        },
        resetColumns(state) {
            state.mappedColumns = [];
            state.unmappedColumns = [];
        },
        setManualMappedColumns(state, action: IAction<IManuallyMappedItem[]>) {
            state.manualMappedColumns = action.payload;
        },
        setFileData(state, action: IAction<string | undefined>) {
            state.fileData = action.payload;
        },
        setRedirectBlocker(state, action: IAction<boolean>) {
            state.redirectBlocker = action.payload;
        },
        setUploadStepFormState(state, action: IAction<boolean>) {
            state.uploadStepFormCompleted = action.payload;
        },
        setFileValidationProgress(state, action: IAction<number>) {
            state.fileValidationProgress = action.payload;
        },
        setBulkSubmit(
            state,
            action: IAction<IRequestStatusWithEmptyData | null>
        ) {
            state.bulkSubmit = action.payload;
        },
        setGroupedOrders(state, action: IAction<IGroupedOrder[]>) {
            state.groupedOrders = action.payload;
        },
        setOrderListId(state, action: IAction<string>) {
            state.orderListId = action.payload;
        },
        setSubmitBulkOrdersProgress(state, action: IAction<number>) {
            state.submitBulkOrderProgress = action.payload;
        },
        resetBulkState() {
            return initialState;
        },
        resetBulkStateKeepDeliveryMethod({
            deliveryMethod,
            redirectBlocker,
            bulkType
        }) {
            return {
                ...initialState,
                deliveryMethod,
                redirectBlocker,
                bulkType
            };
        },
        setDeliveryMethod(state, action: IAction<DELIVERY_TYPES_VALUES>) {
            state.deliveryMethod = action.payload;
        },
        setReturnPackAvailability(state, action: IAction<boolean>) {
            state.returnPackAvailability = action.payload;
        },
        resetDeliveryMethod(state) {
            state.deliveryMethod = initialState.deliveryMethod;
        },
        setBulkType(state, action: IAction<BULK_TYPES>) {
            state.bulkType = action.payload;
        },
        setService(state, action: IAction<ISelectedService>) {
            state.selectedService = action.payload;
        },
        resetSelectedService(state) {
            state.selectedService = initialState.selectedService;
        },
        setDelivery(state, action: IAction<IDelivery>) {
            state.delivery = [...state.delivery, action.payload];
        },
        resetDelivery(state) {
            state.delivery = initialState.delivery;
        },
        setServicesError(state, action) {
            state.servicesError = {
                statusCode: action.payload.statusCode,
                statusMessage: action.payload.statusMessage
            };
        },
        resetServicesError(state) {
            state.servicesError = null;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.validateInboundFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.validateInboundFile
                .matchFulfilled,
            setValidationResult
        );

        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping.matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping.matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.validateOutboundFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.validateOutboundFile
                .matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.validateTransfersFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.validateTransfersFile
                .matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getAvailableCarriers.matchPending,
            (state) => {
                state.carriersLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getAvailableCarriers.matchRejected,
            (state) => {
                state.carriersLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getAvailableCarriers
                .matchPending,
            (state) => {
                state.carriersLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getAvailableCarriers
                .matchRejected,
            (state) => {
                state.carriersLoading = false;
            }
        );
    }
});

export const {
    setActiveStep,
    resetColumns,
    resetBulkState,
    setRedirectBlocker,
    setFileData,
    setUploadStepFormState,
    setManualMappedColumns,
    setFileValidationProgress,
    setBulkSubmit,
    setSubmitBulkOrdersProgress,
    setGroupedOrders,
    setOrderListId,
    setDeliveryMethod,
    resetBulkStateKeepDeliveryMethod,
    setBulkType,
    resetDeliveryMethod,
    setService,
    setDelivery,
    resetDelivery,
    setServicesError,
    resetServicesError,
    setReturnPackAvailability,
    resetSelectedService
} = bulkSlice.actions;
