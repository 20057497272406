import React from 'react';

import { notification } from 'antd';

import { SvgSuccess, SvgError, SvgWarning } from 'components/icons';

import styles from './Notification.module.less';

export enum NOTIFICATION_TYPES {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    OK = 'OK'
}

interface IConfig {
    title: string;
    description?: string | JSX.Element;
    type: NOTIFICATION_TYPES;
    duration?: number;
    key?: string;
}

export const getNotification = ({
    title,
    description,
    type,
    duration = 4.5,
    key
}: IConfig) => {
    const getNotificationTypes = () => {
        switch (type) {
            case NOTIFICATION_TYPES.SUCCESS:
                return {
                    icon: <SvgSuccess />,
                    className: styles.Notification__Success
                };
            case NOTIFICATION_TYPES.WARNING:
                return {
                    icon: <SvgWarning />,
                    className: styles.Notification__Warning
                };
            default:
                return {
                    icon: <SvgError />,
                    className: styles.Notification__Error
                };
        }
    };
    notification.open({
        message: title,
        description: description,
        ...getNotificationTypes(),
        style: {
            width: 600
        },
        duration,
        key
    });
};
