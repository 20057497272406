import React from 'react';

import cn from 'classnames';

import styles from './BlockTitle.module.less';

export interface IProps {
    title: string;
    withMargin?: boolean;
    withTopPosition?: boolean;
}

export const BlockTitle = ({
    title,
    withMargin = true,
    withTopPosition = false
}: IProps) => (
    <div
        data-testid="block-title"
        className={cn(styles.BlockTitle, {
            [styles.BlockTitle__WithMargin]: withMargin,
            [styles.BlockTitle__Top]: withTopPosition
        })}
    >
        {title}
    </div>
);
