import * as React from 'react';
import { SVGProps } from 'react';
export const SvgError = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 4.833V7.5m0 2.667h.007M5.24.833h5.52l3.907 3.907v5.52l-3.907 3.907H5.24L1.333 10.26V4.74L5.24.833Z"
            stroke="#F15151"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
